/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { apply, call, put, takeLatest } from '@redux-saga/core/effects';
import * as tk from '../modules/handleQueryParamsModule/treekeys';
import { useQueryParamsWMTUTBusinessChain } from './businessChain/queryParams/queryParamsWMTUTBusinessChain';
import {
  QueryParamsRequest,
  QueryParamsResponse,
} from './businessChain/queryParams/queryParamsTypes';
import { HandleQueryParamsAction } from '../modules/handleQueryParamsModule/types';

export function* handleQueryParamsWMTUT(queryParamsPayload: any): any {
  const handleQueryParamsAction: HandleQueryParamsAction =
    HandleQueryParamsAction.PENDING;
  yield put({
    type: tk.NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION,
    handleQueryParamsAction,
  });
  yield call(() => Promise.resolve());
  const queryParamsRequest: QueryParamsRequest = {
    queryParams: {
      ...queryParamsPayload?.payload,
    },
  };
  const queryParamsResponse: QueryParamsResponse = {
    handleQueryParamsAction,
  };
  const queryParamsWMTUTBC = useQueryParamsWMTUTBusinessChain();
  yield apply(queryParamsWMTUTBC, queryParamsWMTUTBC.chainBusinessLogic, [
    queryParamsRequest,
    queryParamsResponse,
  ]);
  yield put({
    type: tk.NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION,
    handleQueryParamsAction: queryParamsResponse.handleQueryParamsAction,
  });
  if (
    queryParamsResponse.handleQueryParamsAction ===
    HandleQueryParamsAction.PENDING
  ) {
    yield put({
      type: tk.NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION,
      handleQueryParamsAction: HandleQueryParamsAction.GOTO_WMTUPDATETICKET,
    });
  }
}

export function* watchHandleQueryParamsWMTUT(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, handleQueryParamsWMTUT);
}

export default watchHandleQueryParamsWMTUT;
