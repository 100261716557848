export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const USA_STATES_ABBREV = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const ROUTERNAME_HOME = 'Home';
export const ROUTERNAME_LOGOUT = 'Logout';
export const ROUTERNAME_STSAUTHWRAPPER = 'STSAuthWrapper';
export const ROUTERNAME_STSLANDING = 'STSLanding';
export const ROUTERNAME_STSNOPRODUCTS = 'STSNoProducts';
export const ROUTERNAME_STSPENDINGORDER = 'STSPendingOrder';
export const ROUTERNAME_STSPOSTLOGIN = 'STSPostLogin';
export const ROUTERNAME_STS_MOBILE_LOGIN = 'STSMobileLogIn';
export const ROUTERNAME_STSSELECTACCT = 'STSSelectAcct';
export const ROUTERNAME_STSSELECTWTN = 'STSSelectWTN';
export const ROUTERNAME_STSSUSPNONPAY = 'STSSuspNonPay';
export const ROUTERNAME_STSSUSPVAC = 'STSSuspVac';
export const ROUTERNAME_STSIFRAME = 'STSIFrame';
export const ROUTERNAME_WMTAUTHWRAPPER = 'WMTAuthWrapper';
export const ROUTERNAME_WMTLANDING = 'WMTLanding';
export const ROUTERNAME_WMTPOSTLOGIN = 'WMTPostLogin';
export const ROUTERNAME_WMTRESULTS = 'WMTResults';
export const ROUTERNAME_WMTSELECTACCT = 'WMTSelectAcct';
export const ROUTERNAME_WMTRESCHEDULE = 'WMTReschedule';
export const ROUTERNAME_WMTCANCEL = 'WMTCancel';
export const ROUTERNAME_WMTFINDMYTECH = 'WMTFindMyTech';
export const ROUTERNAME_PAGENOTFOUND = 'PageNotFound';
export const ROUTERNAME_TECHLOCATION = 'TechLocation';

export const ALLOWS_UNAUTH_PATHS = [
  ROUTERNAME_HOME,
  ROUTERNAME_LOGOUT,
  ROUTERNAME_STSLANDING,
  ROUTERNAME_WMTLANDING,
  ROUTERNAME_STSNOPRODUCTS,
  ROUTERNAME_STSPENDINGORDER,
  ROUTERNAME_STSSELECTACCT,
  ROUTERNAME_STSSELECTWTN,
  ROUTERNAME_STSSUSPNONPAY,
  ROUTERNAME_STSSUSPVAC,
  ROUTERNAME_STSIFRAME,
  ROUTERNAME_WMTRESULTS,
  ROUTERNAME_WMTSELECTACCT,
  ROUTERNAME_WMTRESCHEDULE,
  ROUTERNAME_WMTCANCEL,
  ROUTERNAME_WMTFINDMYTECH,
  ROUTERNAME_PAGENOTFOUND,
  ROUTERNAME_STS_MOBILE_LOGIN,
];

export const DH_SSO_ALLOWABLE_PATHS = [
  ROUTERNAME_STSLANDING,
  ROUTERNAME_WMTLANDING,
];

export const ACTIVE_STATUSES = ['active', 'live', 'open', 'o'];
export const PENDING_STATUSES = ['pending', 't'];
export const INACTIVE_STATUSES = [
  'n',
  'inactive',
  'c',
  'final',
  'otn',
  'wrtof',
  'converted',
];
export const SUSPENDED_STATUSES = ['s', 'susp', 'suspended'];
export const ALL_STATUSES = [
  ...ACTIVE_STATUSES,
  ...PENDING_STATUSES,
  ...INACTIVE_STATUSES,
  ...SUSPENDED_STATUSES,
];

export const TICKETORDER_UPCOMING_STATUSES = [
  'Upcoming',
  'Open',
  'Opn',
  'Pending',
  'Inflight',
  'In Progress',
];
export const TICKETORDER_COMPLETED_STATUSES = ['Completed', 'Closed', 'Cld'];
export const TICKETORDER_CANCELLED_STATUSES = ['Cancelled', 'Canceled', 'Can'];

export const REGEXP_LAST4 = /^[0-9]{4}$/;
export const REGEXP_BAN = /^[0-9]{9}$/;
export const REGEXP_BTN_OPTIONAL_CC =
  /^([0-9]{3})([A-Za-z0-9])([0-9]{6}|[0-9]{9})$/;
export const REGEXP_BAID = /^PPB[0-9]{8}$/;
export const REGEXP_BAID_SMALL = /^ppb[0-9]{8}$/;
export const REGEXP_EMAIL = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,10}$/;
export const REGEXP_ORDERNUMORTICKETNUM = /^[0-9A-Za-z]{5,10}$/;
export const REGEXP_ZIPCODE = /^[0-9]{5}$/;
export const REGEXP_PHONE = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im;

export const REGEXP_BAN_INAVLID = /^[^1][\d|\D]*/;
export const REGEXP_BAN_TRUNCATED_INAVLID = /^[\d|\D]*/;

export const REGEXP_EMAIL_INVALID =
  /^[1]+\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,10}$/;

export const LOCALSTORAGE_FRONTCHANNELLOGOUT =
  'LOCALSTORAGE_FRONTCHANNELLOGOUT';

export const CUSTCARE_ISLOGINFROMDH_COOKIE = 'isLoginFromDH';

export const HIGH_LEVEL_INTERNET_PRODUCT = [
  'HSI',
  'IP_DATA',
  'PAIR_BONDED',
  'DSL',
];
export const HIGH_LEVEL_VOICE_PRODUCT = [
  'VM',
  'POTS',
  'VOICE',
  'CENTREX_ILEC',
  'PBX_DIGITAL_ILEC',
  'KEY_ILEC',
  'PRIVATE_LINE',
  'DHP',
];
