/* eslint-disable no-constant-condition */
import CryptoJS from 'crypto-js';

const _0x45e0 = [
  '22aaKjXB',
  '32537DHeZXC',
  '1042941NilSQK',
  '32707JUicoc',
  '19HGAkHo',
  '1065263PobAFP',
  '1DHeMkZ',
  '641026NclCzW',
  '56931OxYnUX',
  '1170084teCrbb',
  'AES',
  'toString',
];
const _0x6de2: any = (_0x1ca352: any) => {
  const _0x1ca352A = _0x1ca352 - 0x151;
  const _0x3be639 = _0x45e0[_0x1ca352A];
  return _0x3be639;
};

(function (_0x4701cd: any, _0x22b106: any) {
  const _0x513139: any = _0x6de2;
  while ([]) {
    try {
      const _0x54b42c =
        parseInt(_0x513139(0x151)) * parseInt(_0x513139(0x152)) +
        -parseInt(_0x513139(0x153)) +
        -parseInt(_0x513139(0x154)) * parseInt(_0x513139(0x155)) +
        -parseInt(_0x513139(0x156)) * -parseInt(_0x513139(0x157)) +
        -parseInt(_0x513139(0x158)) +
        parseInt(_0x513139(0x159)) +
        parseInt(_0x513139(0x15a));
      if (_0x54b42c === _0x22b106) break;
      else _0x4701cd.push(_0x4701cd.shift());
    } catch (_0x281882) {
      _0x4701cd.push(_0x4701cd.shift());
    }
  }
})(_0x45e0, 0xab8e4);

class GtmUtilsSingleton {
  trackGTMEvent = (customPayload: any) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer?.push(customPayload);
    }
  };

  trackChatEvent = (
    flowEvent: any,
    flowName: any,
    flowStep: any,
    account: any
  ) => {
    this.trackGTMEvent({
      event: flowEvent,
      accountNumber: this.pii(account),
      flow: {
        name: flowName,
        step: `ctl|rsd|support|repair|sam|${flowStep}`,
      },
    });
  };

  pii = (_0x5b5b6c: any) => {
    const _0x17b332: any = _0x6de2;
    const _0x26e0a0 = (CryptoJS as any)[_0x17b332(0x15b)].encrypt(
      _0x5b5b6c,
      'Part\x20Window\x20Trick\x20Discussion\x20Wilderness\x20Creature'
    );
    return _0x26e0a0[_0x17b332(0x15c)]();
  };
}

let gtmUtilsSingleton: GtmUtilsSingleton;

export const useGtmUtilsSingleton = (): GtmUtilsSingleton => {
  if (!gtmUtilsSingleton) {
    gtmUtilsSingleton = new GtmUtilsSingleton();
  }
  return gtmUtilsSingleton;
};
