/* eslint-disable no-unused-expressions */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ref } from 'vue';
import { useDateUtilsSingleton } from '@/common/utils/dateUtilsSingleton';
import {
  TICKETORDER_CANCELLED_STATUSES,
  TICKETORDER_COMPLETED_STATUSES,
  TICKETORDER_UPCOMING_STATUSES,
} from '@/common/strings';
import {
  C360PendingOrdersAndRXTicketInfos,
  CombinedC360PendingOrdersAndRXTicketInfos,
  CombinedServiceOrderInfo,
  GetC360PendingOrdersAndRXTicketInfosParallelState,
  GetC360PendingOrdersAndRXTicketInfosState,
  RXTicketInfo,
  RXTicketInfoNormalized,
  TicketOrderStatus,
  TroubleReport,
} from './types';
import * as treekeys from './treekeys';

export const data: GetC360PendingOrdersAndRXTicketInfosParallelState = {
  shouldThrottle: false,
  lruKeyArr: [],
  getC360PendingOrdersAndRXTicketInfosParallelMap: {},
  shouldShiftLRU: false,
};
export const getters: GetterTree<
  GetC360PendingOrdersAndRXTicketInfosParallelState,
  any
> = {
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP]:
    (state) => state.getC360PendingOrdersAndRXTicketInfosParallelMap,
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDTHROTTLE]:
    (state) => state.shouldThrottle,
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDSHIFTLRU]:
    (state) => state.shouldShiftLRU,
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMTICKETINFOMAP]:
    (state) => {
      const resp: { [index: string]: RXTicketInfoNormalized } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState
              ?.c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse
        )
        ?.forEach(
          (
            combinedC360AndRXResponse:
              | CombinedC360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            combinedC360AndRXResponse?.ticketInfos?.forEach(
              (ticketInfo: RXTicketInfo) =>
                ticketInfo?.troubleReports?.forEach(
                  (troubleReport: TroubleReport) =>
                    (resp[
                      troubleReport?.nativeTroubleReportIdentifier || 'UNKNOWN'
                    ] = {
                      ...ticketInfo,
                      ...combinedC360AndRXResponse,
                      ...troubleReport,
                      nativeTicketNum:
                        troubleReport?.nativeTroubleReportIdentifier,
                      nativeTroubleTicketNumber:
                        troubleReport?.nativeTroubleReportIdentifier,
                      ticketNum: troubleReport?.originatingCrmSessionIdentifier,
                      customerProductID: troubleReport?.customerProductId,
                      productName: troubleReport?.productName,
                      sessionId: combinedC360AndRXResponse?.rxSessionIdentifier,
                    })
                )
            )
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETCOMPANYOWNERID]:
    (state) => {
      const resp = ref();
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState
              ?.c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse
        )
        ?.forEach(
          (
            combinedC360AndRXResponse:
              | CombinedC360PendingOrdersAndRXTicketInfos
              | undefined
          ) => (resp.value = combinedC360AndRXResponse?.companyOwnerId)
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMACCTNUMMAP]:
    (state) => {
      const resp: { [index: string]: string | undefined } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState?.c360PendingOrdersAndRXTicketInfos
        )
        ?.forEach(
          (
            c360PendingOrdersAndRXTicketInfos:
              | C360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.ticketInfos?.forEach(
              (ticketInfo: RXTicketInfo) =>
                ticketInfo?.troubleReports?.forEach(
                  (troubleReport: TroubleReport) =>
                    (resp[
                      troubleReport?.nativeTroubleReportIdentifier || 'UNKNOWN'
                    ] = c360PendingOrdersAndRXTicketInfos?.accountNumber)
                )
            )
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMPOSTALCODEMAP]:
    (state) => {
      const resp: { [index: string]: string | undefined } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState?.c360PendingOrdersAndRXTicketInfos
        )
        ?.forEach(
          (
            c360PendingOrdersAndRXTicketInfos:
              | C360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.ticketInfos?.forEach(
              (ticketInfo: RXTicketInfo) =>
                ticketInfo?.troubleReports?.forEach(
                  (troubleReport: TroubleReport) =>
                    (resp[
                      troubleReport?.nativeTroubleReportIdentifier || 'UNKNOWN'
                    ] =
                      c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.postalCode)
                )
            )
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMDATEASSUMPTIONMAP]:
    (state) => {
      const resp: { [index: string]: string } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState?.c360PendingOrdersAndRXTicketInfos
        )
        ?.forEach(
          (
            c360PendingOrdersAndRXTicketInfos:
              | C360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.ticketInfos?.forEach(
              (ticketInfo: RXTicketInfo) =>
                ticketInfo?.troubleReports?.forEach(
                  (troubleReport: TroubleReport) =>
                    (resp[
                      troubleReport?.nativeTroubleReportIdentifier || 'UNKNOWN'
                    ] =
                      troubleReport?.appointmentInfo?.startDateTime ||
                      troubleReport?.appointmentInfo?.estimatedStartTime ||
                      troubleReport?.appointmentInfo?.estimatedCompletionTime ||
                      troubleReport?.commitmentDateTime ||
                      troubleReport?.createDateTime ||
                      'UNKNOWN')
                )
            )
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMDATEASSUMPTIONMAP]:
    (state) => {
      const resp: { [index: string]: string } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState?.c360PendingOrdersAndRXTicketInfos
        )
        ?.forEach(
          (
            c360PendingOrdersAndRXTicketInfos:
              | C360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.combinedServiceOrderInfos?.forEach(
              (combinedServiceOrderInfo: CombinedServiceOrderInfo) => {
                resp[
                  combinedServiceOrderInfo?.serviceOrderInfo
                    ?.serviceOrderNumber || 'UNKNOWN'
                ] =
                  combinedServiceOrderInfo?.appointmentInfo?.estStartTime ||
                  combinedServiceOrderInfo?.appointmentInfo
                    ?.estCompletionTime ||
                  combinedServiceOrderInfo?.appointmentInfo?.startDateTime ||
                  combinedServiceOrderInfo?.serviceOrderInfo?.dueDate ||
                  'UNKNOWN';
              }
            )
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMSTATUSMAP]:
    (state) => {
      const resp: { [index: string]: TicketOrderStatus } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState?.c360PendingOrdersAndRXTicketInfos
        )
        ?.forEach(
          (
            c360PendingOrdersAndRXTicketInfos:
              | C360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.ticketInfos?.forEach(
              (ticketInfo: RXTicketInfo) =>
                ticketInfo?.troubleReports?.forEach(
                  (troubleReport: TroubleReport) => {
                    const k =
                      troubleReport?.nativeTroubleReportIdentifier || 'UNKNOWN';
                    const currTroubleReportStatus =
                      troubleReport?.troubleReportStatus?.toLowerCase();
                    const splitTroubleReportStatus =
                      currTroubleReportStatus?.split('.') || [];
                    resp[k] = TicketOrderStatus.UNKNOWN;
                    resp[k] = TICKETORDER_UPCOMING_STATUSES.map((s) =>
                      s.toLowerCase()
                    ).includes(
                      troubleReport?.troubleReportStatus?.toLowerCase() ||
                        'assumingNotUpcoming'
                    )
                      ? TicketOrderStatus.UPCOMING
                      : resp[k];
                    if (splitTroubleReportStatus.length > 1) {
                      resp[k] = TICKETORDER_UPCOMING_STATUSES.map((s) =>
                        s.toLowerCase()
                      ).includes(
                        splitTroubleReportStatus[1] || 'assumingNotUpcoming'
                      )
                        ? TicketOrderStatus.UPCOMING
                        : resp[k];
                    }
                    resp[k] = TICKETORDER_COMPLETED_STATUSES.map((s) =>
                      s.toLowerCase()
                    ).includes(
                      troubleReport?.troubleReportStatus?.toLowerCase() ||
                        'assumingNotCompleted'
                    )
                      ? TicketOrderStatus.COMPLETED
                      : resp[k];
                    resp[k] = TICKETORDER_CANCELLED_STATUSES.map((s) =>
                      s.toLowerCase()
                    ).includes(
                      troubleReport?.troubleReportStatus?.toLowerCase() ||
                        'assumingNotCancelled'
                    )
                      ? TicketOrderStatus.CANCELLED
                      : resp[k];
                    const appointmentdate =
                      troubleReport?.appointmentInfo?.startDateTime ||
                      troubleReport?.appointmentInfo?.estimatedStartTime ||
                      troubleReport?.appointmentInfo?.estimatedCompletionTime ||
                      troubleReport?.commitmentDateTime ||
                      troubleReport?.createDateTime;
                    resp[k] =
                      resp[k] === TicketOrderStatus.UPCOMING &&
                      useDateUtilsSingleton()
                        .useDayjs()(
                          appointmentdate?.substring(0, 10).includes(' ')
                            ? appointmentdate?.substring(0, 8)
                            : appointmentdate?.substring(0, 10)
                        )
                        .startOf('day')
                        .isBefore(
                          useDateUtilsSingleton()
                            .useDayjs()(Date.now())
                            .startOf('day')
                        )
                        ? TicketOrderStatus.PAST
                        : resp[k];
                  }
                )
            )
        );
      return resp;
    },
  [treekeys.GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMSTATUSMAP]:
    (state) => {
      const resp: { [index: string]: TicketOrderStatus } = {};
      Object.values(state.getC360PendingOrdersAndRXTicketInfosParallelMap)
        .map(
          (
            getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
          ) =>
            getC360PendingOrdersAndRXTicketInfosState?.c360PendingOrdersAndRXTicketInfos
        )
        ?.forEach(
          (
            c360PendingOrdersAndRXTicketInfos:
              | C360PendingOrdersAndRXTicketInfos
              | undefined
          ) =>
            c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.combinedServiceOrderInfos?.forEach(
              (combinedServiceOrderInfo: CombinedServiceOrderInfo) => {
                const k =
                  combinedServiceOrderInfo?.serviceOrderInfo
                    ?.serviceOrderNumber || 'UNKNOWN';
                resp[k] = TicketOrderStatus.UNKNOWN;
                resp[k] = TICKETORDER_UPCOMING_STATUSES.map((s) =>
                  s.toLowerCase()
                ).includes(
                  combinedServiceOrderInfo?.serviceOrderInfo?.serviceOrderStatus?.toLowerCase() ||
                    'assumingNotUpcoming'
                )
                  ? TicketOrderStatus.UPCOMING
                  : resp[k];
                resp[k] = TICKETORDER_COMPLETED_STATUSES.map((s) =>
                  s.toLowerCase()
                ).includes(
                  combinedServiceOrderInfo?.serviceOrderInfo?.serviceOrderStatus?.toLowerCase() ||
                    'assumingNotCompleted'
                )
                  ? TicketOrderStatus.COMPLETED
                  : resp[k];
                resp[k] = TICKETORDER_CANCELLED_STATUSES.map((s) =>
                  s.toLowerCase()
                ).includes(
                  combinedServiceOrderInfo?.serviceOrderInfo?.serviceOrderStatus?.toLowerCase() ||
                    'assumingNotCancelled'
                )
                  ? TicketOrderStatus.CANCELLED
                  : resp[k];
                if (
                  (resp[k] === TicketOrderStatus.UPCOMING ||
                    resp[k] === TicketOrderStatus.UNKNOWN) &&
                  useDateUtilsSingleton()
                    .useDayjs()(
                      combinedServiceOrderInfo?.serviceOrderInfo?.dueDate
                        ?.substring(0, 10)
                        .includes(' ')
                        ? combinedServiceOrderInfo?.serviceOrderInfo?.dueDate?.substring(
                            0,
                            8
                          )
                        : combinedServiceOrderInfo?.serviceOrderInfo?.dueDate?.substring(
                            0,
                            10
                          )
                    )
                    .startOf('day')
                    .isBefore(
                      useDateUtilsSingleton()
                        .useDayjs()(Date.now())
                        .startOf('day')
                    )
                ) {
                  resp[k] = TicketOrderStatus.PAST;
                } else if (resp[k] === TicketOrderStatus.UNKNOWN) {
                  resp[k] = TicketOrderStatus.UPCOMING;
                }
              }
            )
        );
      return resp;
    },
};
export const mutations: MutationTree<GetC360PendingOrdersAndRXTicketInfosParallelState> =
  {
    [treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP]:
      (state, { getC360PendingOrdersAndRXTicketInfosParallelMap }) => {
        state.getC360PendingOrdersAndRXTicketInfosParallelMap =
          getC360PendingOrdersAndRXTicketInfosParallelMap;
      },
    [treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHIFTLRU]:
      (state) => {
        delete state.getC360PendingOrdersAndRXTicketInfosParallelMap[
          state.lruKeyArr.shift() || ''
        ];
        state.shouldThrottle =
          Object.values(
            state.getC360PendingOrdersAndRXTicketInfosParallelMap
          ).filter(
            (
              getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
            ) => getC360PendingOrdersAndRXTicketInfosState.pending
          ).length >=
          treekeys.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXPARALLEL;
      },
    [treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING]:
      (state, { key }) => {
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[key]
          ? 0
          : state.lruKeyArr.push(key);
        state.shouldShiftLRU =
          state.lruKeyArr.length >
          treekeys.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXENTRIES;
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[key] =
          state.getC360PendingOrdersAndRXTicketInfosParallelMap[key] || {};
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[key].pending =
          true;
        state.shouldThrottle =
          Object.values(
            state.getC360PendingOrdersAndRXTicketInfosParallelMap
          ).filter(
            (
              getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
            ) => getC360PendingOrdersAndRXTicketInfosState.pending
          ).length >=
          treekeys.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXPARALLEL;
      },
    [treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS]:
      (state, { key, c360PendingOrdersAndRXTicketInfos }) => {
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[key].pending =
          false;
        state.shouldThrottle =
          Object.values(
            state.getC360PendingOrdersAndRXTicketInfosParallelMap
          ).filter(
            (
              getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
            ) => getC360PendingOrdersAndRXTicketInfosState.pending
          ).length >=
          treekeys.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXPARALLEL;
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[
          key
        ].c360PendingOrdersAndRXTicketInfos = c360PendingOrdersAndRXTicketInfos;
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[
          key
        ].c360PendingOrdersAndRXTicketInfosError = undefined;
      },
    [treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR]:
      (state, { key, c360PendingOrdersAndRXTicketInfosError }) => {
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[key].pending =
          false;
        state.shouldThrottle =
          Object.values(
            state.getC360PendingOrdersAndRXTicketInfosParallelMap
          ).filter(
            (
              getC360PendingOrdersAndRXTicketInfosState: GetC360PendingOrdersAndRXTicketInfosState
            ) => getC360PendingOrdersAndRXTicketInfosState.pending
          ).length >=
          treekeys.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXPARALLEL;
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[
          key
        ].c360PendingOrdersAndRXTicketInfos = {};
        state.getC360PendingOrdersAndRXTicketInfosParallelMap[
          key
        ].c360PendingOrdersAndRXTicketInfosError = c360PendingOrdersAndRXTicketInfosError;
      },
    [treekeys.MUTATE_GETBRIGHTSPEED_NOTIFICATION]: (
      state,
      { key, brightSpeedNotification }
    ) => {
      state.getC360PendingOrdersAndRXTicketInfosParallelMap[
        key
      ].brightSpeedNotification = brightSpeedNotification;
    },
  };
export const actions: ActionTree<
  GetC360PendingOrdersAndRXTicketInfosParallelState,
  any
> = {
  [treekeys.ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP]:
    ({ commit }, { getC360PendingOrdersAndRXTicketInfosParallelMap }) => {
      commit(
        treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP,
        {
          getC360PendingOrdersAndRXTicketInfosParallelMap,
        }
      );
    },
  [treekeys.ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING]:
    ({ commit }, { key }) => {
      commit(
        treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING,
        { key }
      );
    },
  [treekeys.ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS]:
    ({ commit }, { key, c360PendingOrdersAndRXTicketInfos }) => {
      commit(
        treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS,
        {
          key,
          c360PendingOrdersAndRXTicketInfos,
        }
      );
    },
  [treekeys.ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR]:
    ({ commit }, { key, c360PendingOrdersAndRXTicketInfosError }) => {
      commit(
        treekeys.MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR,
        {
          key,
          c360PendingOrdersAndRXTicketInfosError,
        }
      );
    },
  [treekeys.ACTION_GETBRIGHTSPEED_NOTIFICATION]: (
    { commit },
    { key, brightSpeedNotification }
  ) => {
    commit(treekeys.MUTATE_GETBRIGHTSPEED_NOTIFICATION, {
      key,
      brightSpeedNotification,
    });
  },
};

const getC360PendingOrdersAndRXTicketInfosParallelModule: Module<
  GetC360PendingOrdersAndRXTicketInfosParallelState,
  any
> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default getC360PendingOrdersAndRXTicketInfosParallelModule;
