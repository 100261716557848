export const GET_PROGRESSBAR_PROGRESSBARAPPEAR =
  'GET_PROGRESSBAR_PROGRESSBARAPPEAR';
export const GET_PROGRESSBAR_PROGRESSVALUE = 'GET_PROGRESSBAR_PROGRESSVALUE';
export const GET_PROGRESSBAR_LONGERTHANUSUAL =
  'GET_PROGRESSBAR_LONGERTHANUSUAL';
export const GET_PROGRESSBAR_SEGMENTS = 'GET_PROGRESSBAR_SEGMENTS';
export const GET_PROGRESSBAR_RUNNINGSEGMENTS =
  'GET_PROGRESSBAR_RUNNINGSEGMENTS';
export const GET_PROGRESSBAR_TIMEOUT = 'GET_PROGRESSBAR_TIMEOUT';
export const GET_PROGRESSBAR_VISUALFINISHED = 'GET_PROGRESSBAR_VISUALFINISHED';
export const MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR =
  'MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR';
export const MUTATE_PROGRESSBAR_PROGRESSVALUE =
  'MUTATE_PROGRESSBAR_PROGRESSVALUE';
export const MUTATE_PROGRESSBAR_LONGERTHANUSUAL =
  'MUTATE_PROGRESSBAR_LONGERTHANUSUAL';
export const MUTATE_PROGRESSBAR_SEGMENTS = 'MUTATE_PROGRESSBAR_SEGMENTS';
export const MUTATE_PROGRESSBAR_RUNNINGSEGMENTS =
  'MUTATE_PROGRESSBAR_RUNNINGSEGMENTS';
export const MUTATE_PROGRESSBAR_TIMEOUT = 'MUTATE_PROGRESSBAR_TIMEOUT';
export const MUTATE_PROGRESSBAR_VISUALFINISHED =
  'MUTATE_PROGRESSBAR_VISUALFINISHED';

export const PROGRESSBARMODULE = 'progressBarModule';
export const NS_GET_PROGRESSBAR_PROGRESSBARAPPEAR = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_PROGRESSBARAPPEAR}`;
export const NS_GET_PROGRESSBAR_PROGRESSVALUE = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_PROGRESSVALUE}`;
export const NS_GET_PROGRESSBAR_LONGERTHANUSUAL = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_LONGERTHANUSUAL}`;
export const NS_GET_PROGRESSBAR_SEGMENTS = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_SEGMENTS}`;
export const NS_GET_PROGRESSBAR_RUNNINGSEGMENTS = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_RUNNINGSEGMENTS}`;
export const NS_GET_PROGRESSBAR_TIMEOUT = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_TIMEOUT}`;
export const NS_GET_PROGRESSBAR_VISUALFINISHED = `${PROGRESSBARMODULE}/${GET_PROGRESSBAR_VISUALFINISHED}`;
export const NS_MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR}`;
export const NS_MUTATE_PROGRESSBAR_PROGRESSVALUE = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_PROGRESSVALUE}`;
export const NS_MUTATE_PROGRESSBAR_LONGERTHANUSUAL = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_LONGERTHANUSUAL}`;
export const NS_MUTATE_PROGRESSBAR_SEGMENTS = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_SEGMENTS}`;
export const NS_MUTATE_PROGRESSBAR_RUNNINGSEGMENTS = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_RUNNINGSEGMENTS}`;
export const NS_MUTATE_PROGRESSBAR_TIMEOUT = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_TIMEOUT}`;
export const NS_MUTATE_PROGRESSBAR_VISUALFINISHED = `${PROGRESSBARMODULE}/${MUTATE_PROGRESSBAR_VISUALFINISHED}`;
