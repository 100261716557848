import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { STSIFrameIterState, STSIFrameState } from './types';
import * as treekeys from './treekeys';

const initState: STSIFrameIterState = {
  tokenReceivedTime: 0,
  routeParams: {},
};

export const data: STSIFrameState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<STSIFrameState, any> = {
  [treekeys.GET_STSIFRAMEMODULE_TOKENRECEIVEDTIME]: (state) =>
    state.currState.tokenReceivedTime,
  [treekeys.GET_STSIFRAMEMODULE_ROUTEPARAMS]: (state) =>
    state.currState.routeParams,
  [treekeys.GET_STSIFRAMEMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_STSIFRAMEMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<STSIFrameState> = {
  [treekeys.MUTATE_STSIFRAMEMODULE_TOKENRECEIVEDTIME]: (
    state,
    { tokenReceivedTime }
  ) => {
    state.currState.tokenReceivedTime = tokenReceivedTime;
  },
  [treekeys.MUTATE_STSIFRAMEMODULE_ROUTEPARAMS]: (state, { routeParams }) => {
    state.currState.routeParams = routeParams;
  },
  [treekeys.MUTATE_STSIFRAMEMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_STSIFRAMEMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<STSIFrameState, any> = {
  [treekeys.ACTION_STSIFRAMEMODULE_TOKENRECEIVEDTIME]: (
    { commit },
    { tokenReceivedTime }
  ) => {
    commit(treekeys.MUTATE_STSIFRAMEMODULE_TOKENRECEIVEDTIME, {
      tokenReceivedTime,
    });
  },
  [treekeys.ACTION_STSIFRAMEMODULE_ROUTEPARAMS]: (
    { commit },
    { routeParams }
  ) => {
    commit(treekeys.MUTATE_STSIFRAMEMODULE_ROUTEPARAMS, {
      routeParams,
    });
  },
};

const stsIFrameModule: Module<STSIFrameState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default stsIFrameModule;
