/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGCAP from '@/store/modules/getC360AcctsParallelModule/treekeys';
import * as tkGRPP from '@/store/modules/getRXProductInfoParallelModule/treekeys';
import {
  CustomerProduct,
  GetRXProductInfoParallelMap,
  GetRXProductInfoState,
  ServiceLine,
} from '@/store/modules/getRXProductInfoParallelModule/types';
import { useNSProtectedStore } from '@/store';
import { getRXProductInfoParallel } from '../../watchGetRXProductInfoParallel';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';

class QueryParamsSTSSVHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getActionBasedOnRulesRXProductInfo = (
    getRXProductInfoParallelMap: GetRXProductInfoParallelMap,
    acctNumOrEmail: string
  ): HandleQueryParamsAction => {
    const store360 = useNSProtectedStore(tkGCAP.GETC360ACCTSPARALLELMODULE);
    const getRXProductInfoState: GetRXProductInfoState =
      getRXProductInfoParallelMap?.[acctNumOrEmail];
    const custProducts: CustomerProduct[] | undefined =
      getRXProductInfoState?.rxProductInfo?.serviceLines?.flatMap(
        (serviceLine: ServiceLine) => serviceLine?.customerProducts || []
      );
    const billingIdentifier: string | undefined =
      getRXProductInfoState?.rxProductInfo?.accountInformation
        ?.billingIdentifier;
    if (
      // TODO: check custProducts are HSI, VOICE, or IWP
      // custProducts?.length === 1 &&
      custProducts?.filter(
        (custProduct: CustomerProduct) => custProduct?.isVacationSuspend
      )?.length === custProducts?.length
    ) {
      store360.dispatch(
        tkGCAP.NS_ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID,
        { accountNumber: billingIdentifier }
      );
      return HandleQueryParamsAction.GOTO_STSSUSPVAC;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const getRXProductInfoParallelMap: GetRXProductInfoParallelMap =
      yield select(
        (s) =>
          s?.[tkGRPP.GETRXPRODUCTINFOPARALLELMODULE]
            ?.getRXProductInfoParallelMap
      );
    if (
      !getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo ||
      !Object.keys(getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo)
        .length
    ) {
      yield call(getRXProductInfoParallel, { payload: acctNumOrEmail });
    }

    response.handleQueryParamsAction = this.getActionBasedOnRulesRXProductInfo(
      getRXProductInfoParallelMap,
      acctNumOrEmail.replaceAll('*', '')
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSSVHandler;

export const useQueryParamsSTSSVHandler = (): QueryParamsSTSSVHandler => {
  if (!qp) {
    qp = new QueryParamsSTSSVHandler();
  }
  return qp;
};
