/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGRPP from '@/store/modules/getRXProductInfoParallelModule/treekeys';
import {
  GetRXProductInfoParallelMap,
  ServiceLine,
} from '@/store/modules/getRXProductInfoParallelModule/types';
import { useVuelidateRulesSingleton } from '@/common/utils/vuelidateRulesSingleton';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';

class QueryParamsSTSSWHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getActionBasedOnRulesRXProductInfo = (
    getRXProductInfoParallelMap: GetRXProductInfoParallelMap,
    acctNumOrEmail: string,
    wtn: string
  ): HandleQueryParamsAction => {
    const vuelidateRules = useVuelidateRulesSingleton();
    const numServiceLines: number | undefined =
      getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo?.serviceLines
        ?.length;
    if (
      numServiceLines &&
      numServiceLines > 1 &&
      (!wtn ||
        !vuelidateRules.isSAMAcctNumRegexMatch(wtn) ||
        !getRXProductInfoParallelMap?.[
          acctNumOrEmail
        ]?.rxProductInfo?.serviceLines?.find(
          (serviceLine: ServiceLine) =>
            (serviceLine.sid && serviceLine.sid === wtn) ||
            (serviceLine.serviceTnOrCktId &&
              serviceLine.serviceTnOrCktId === wtn)
        ))
    ) {
      return HandleQueryParamsAction.GOTO_STSSELECTWTN;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const wtn: string = queryParams?.wtn || '';
    const getRXProductInfoParallelMap: GetRXProductInfoParallelMap =
      yield select(
        (s) =>
          s?.[tkGRPP.GETRXPRODUCTINFOPARALLELMODULE]
            ?.getRXProductInfoParallelMap
      );
    response.handleQueryParamsAction = this.getActionBasedOnRulesRXProductInfo(
      getRXProductInfoParallelMap,
      acctNumOrEmail.replaceAll('*', ''),
      wtn
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSSWHandler;

export const useQueryParamsSTSSWHandler = (): QueryParamsSTSSWHandler => {
  if (!qp) {
    qp = new QueryParamsSTSSWHandler();
  }
  return qp;
};
