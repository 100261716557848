import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { HandleQueryParamsState, HandleQueryParamsAction } from './types';
import * as treekeys from './treekeys';

export const data: HandleQueryParamsState = {
  handleQueryParamsAction: HandleQueryParamsAction.PENDING,
};

export const getters: GetterTree<HandleQueryParamsState, any> = {
  [treekeys.GET_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION]: (state) =>
    state.handleQueryParamsAction,
};

export const mutations: MutationTree<HandleQueryParamsState> = {
  [treekeys.MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION]: (
    state,
    { handleQueryParamsAction }
  ) => {
    state.handleQueryParamsAction = handleQueryParamsAction;
  },
};

export const actions: ActionTree<HandleQueryParamsState, any> = {
  [treekeys.ACTION_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION]: (
    { commit },
    { handleQueryParamsAction }
  ) => {
    commit(treekeys.MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION, {
      handleQueryParamsAction,
    });
  },
};

const handleQueryParamsModule: Module<HandleQueryParamsState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default handleQueryParamsModule;
