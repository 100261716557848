export const GET_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER =
  'GET_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER';
export const GET_STSSELECTACCTMODULE_CURRSTATE =
  'GET_STSSELECTACCTMODULE_CURRSTATE';
export const GET_STSSELECTACCTMODULE_PREVSTATE =
  'GET_STSSELECTACCTMODULE_PREVSTATE';
export const GET_STSSELECTACCTMODULE_ROUTEPARAMS =
  'GET_STSSELECTACCTMODULE_ROUTEPARAMS';
export const MUTATE_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER =
  'MUTATE_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER';
export const MUTATE_STSSELECTACCTMODULE_CURRSTATE =
  'MUTATE_STSSELECTACCTMODULE_CURRSTATE';
export const MUTATE_STSSELECTACCTMODULE_PREVSTATE =
  'MUTATE_STSSELECTACCTMODULE_PREVSTATE';
export const MUTATE_STSSELECTACCTMODULE_ROUTEPARAMS =
  'MUTATE_STSSELECTACCTMODULE_ROUTEPARAMS';
export const ACTION_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER =
  'ACTION_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER';
export const ACTION_STSSELECTACCTMODULE_ROUTEPARAMS =
  'ACTION_STSSELECTACCTMODULE_ROUTEPARAMS';
export const STSSELECTACCTMODULE = 'stsSelectAcctModule';
export const NS_GET_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER = `${STSSELECTACCTMODULE}/${GET_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_GET_STSSELECTACCTMODULE_CURRSTATE = `${STSSELECTACCTMODULE}/${GET_STSSELECTACCTMODULE_CURRSTATE}`;
export const NS_GET_STSSELECTACCTMODULE_PREVSTATE = `${STSSELECTACCTMODULE}/${GET_STSSELECTACCTMODULE_PREVSTATE}`;
export const NS_GET_STSSELECTACCTMODULE_ROUTEPARAMS = `${STSSELECTACCTMODULE}/${GET_STSSELECTACCTMODULE_ROUTEPARAMS}`;
export const NS_MUTATE_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER = `${STSSELECTACCTMODULE}/${MUTATE_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_MUTATE_STSSELECTACCTMODULE_CURRSTATE = `${STSSELECTACCTMODULE}/${MUTATE_STSSELECTACCTMODULE_CURRSTATE}`;
export const NS_MUTATE_STSSELECTACCTMODULE_PREVSTATE = `${STSSELECTACCTMODULE}/${MUTATE_STSSELECTACCTMODULE_PREVSTATE}`;
export const NS_MUTATE_STSSELECTACCTMODULE_ROUTEPARAMS = `${STSSELECTACCTMODULE}/${MUTATE_STSSELECTACCTMODULE_ROUTEPARAMS}`;
export const NS_ACTION_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER = `${STSSELECTACCTMODULE}/${ACTION_STSSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_ACTION_STSSELECTACCTMODULE_ROUTEPARAMS = `${STSSELECTACCTMODULE}/${ACTION_STSSELECTACCTMODULE_ROUTEPARAMS}`;
