export const GET_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER =
  'GET_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER';
export const GET_WMTSELECTACCTMODULE_MASKED_SELECTEDC360ACCOUNTNUMBER =
  'GET_WMTSELECTACCTMODULE_MASKED_SELECTEDC360ACCOUNTNUMBER';
export const GET_WMTSELECTACCTMODULE_CURRSTATE =
  'GET_WMTSELECTACCTMODULE_CURRSTATE';
export const GET_WMTSELECTACCTMODULE_PREVSTATE =
  'GET_WMTSELECTACCTMODULE_PREVSTATE';
export const MUTATE_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER =
  'MUTATE_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER';
export const MUTATE_WMTSELECTACCTMODULE_CURRSTATE =
  'MUTATE_WMTSELECTACCTMODULE_CURRSTATE';
export const MUTATE_WMTSELECTACCTMODULE_PREVSTATE =
  'MUTATE_WMTSELECTACCTMODULE_PREVSTATE';
export const ACTION_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER =
  'ACTION_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER';
export const WMTSELECTACCTMODULE = 'wmtSelectAcctModule';
export const NS_GET_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER = `${WMTSELECTACCTMODULE}/${GET_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_GET_WMTSELECTACCTMODULE_MASKED_SELECTEDC360ACCOUNTNUMBER = `${WMTSELECTACCTMODULE}/${GET_WMTSELECTACCTMODULE_MASKED_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_GET_WMTSELECTACCTMODULE_CURRSTATE = `${WMTSELECTACCTMODULE}/${GET_WMTSELECTACCTMODULE_CURRSTATE}`;
export const NS_GET_WMTSELECTACCTMODULE_PREVSTATE = `${WMTSELECTACCTMODULE}/${GET_WMTSELECTACCTMODULE_PREVSTATE}`;
export const NS_MUTATE_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER = `${WMTSELECTACCTMODULE}/${MUTATE_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_MUTATE_WMTSELECTACCTMODULE_CURRSTATE = `${WMTSELECTACCTMODULE}/${MUTATE_WMTSELECTACCTMODULE_CURRSTATE}`;
export const NS_MUTATE_WMTSELECTACCTMODULE_PREVSTATE = `${WMTSELECTACCTMODULE}/${MUTATE_WMTSELECTACCTMODULE_PREVSTATE}`;
export const NS_ACTION_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER = `${WMTSELECTACCTMODULE}/${ACTION_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER}`;
