import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import getIsAuthMockResp from '../../../assets/json/apiMockResp/getIsAuth.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 500;
const MOCK_TIME_BOUND_MAX = 2000;

export function* getIsAuth(): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: getIsAuthMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  return yield call(
    axios.get,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.AUTH_IS_AUTH}`,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
