import axios from 'axios';
import * as envVars from '@/common/envVars';
import CryptoJS from 'crypto-js';

class ApiKeyUtilsSingleton {
  ipAddress;

  constructor() {
    this.ipAddress = '';
  }

  async getEncryptedApiKey() {
    if (!this.ipAddress || this.ipAddress.length <= 0) {
      try {
        const ipAdd = await axios.get(`${envVars.VUE_APP_PUBLIC_IP}`, {
          timeout: 10000,
          signal: abortSignal(10000),
        });
        if (ipAdd?.data?.ip) {
          this.ipAddress = ipAdd?.data?.ip;
        } else {
          console.log(`ERROR: Ip address not specified!`);
          return '';
        }
      } catch (err) {
        console.log(`ERROR: Unable to access ${envVars.VUE_APP_PUBLIC_IP}`);
        return '';
      }
    }
    const apiKey = `${new Date().toISOString()}|${this.ipAddress}|${
      envVars.VUE_APP_CRYPTO_PASSPHRASE
    }`;
    const key = CryptoJS.enc.Latin1.parse(envVars.VUE_APP_CRYPTO_APP_KEY);
    const iv = CryptoJS.enc.Latin1.parse(envVars.VUE_APP_CRYPTO_APP_IV);
    const encryptedApiKey = CryptoJS.AES.encrypt(apiKey, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    return encryptedApiKey;
  }
}

function abortSignal(timeOut: number) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeOut || 0);
  return abortController.signal;
}

let apiKeyUtilsSingleton: ApiKeyUtilsSingleton;

export const useApiKeyUtilsSingleton = (): ApiKeyUtilsSingleton => {
  if (!apiKeyUtilsSingleton) {
    apiKeyUtilsSingleton = new ApiKeyUtilsSingleton();
  }
  return apiKeyUtilsSingleton;
};
