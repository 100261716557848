import authModule from '@/store/modules/auth';
import azureAttrsModule from '@/store/modules/azureattrs';
import progressBarModule from '@/store/modules/progressbar';
import progressSpinnerModule from '@/store/modules/progressspinner';
import sagaTasksModule from '@/store/modules/sagaTasks';

import get34SEcomUserProfileModule from '@/store/modules/get34SEcomUserProfileModule';
import getC360AcctsParallelModule from '@/store/modules/getC360AcctsParallelModule';
import trackerTechModule from '@/store/modules/trackerTechModule';
import getC360PendingOrdersAndRXTicketInfosParallelModule from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule';
import getC360PendingOrdersParallelModule from '@/store/modules/getC360PendingOrdersParallelModule';
import getNokiaSSCTokenParallelModule from '@/store/modules/getNokiaSSCTokenParallelModule';
import getRXAppointmentInfosParallelModule from '@/store/modules/getRXAppointmentInfosParallelModule';
import getRXProductInfoParallelModule from '@/store/modules/getRXProductInfoParallelModule';
import handleQueryParamsModule from '@/store/modules/handleQueryParamsModule';
import updateRXTicketParallelModule from '@/store/modules/updateRXTicketParallelModule';
import stsIFrameModule from '@/store/modules/stsIFrameModule';
import stsLandingModule from '@/store/modules/stsLandingModule';
import stsPostLoginModule from '@/store/modules/stsPostLoginModule';
import stsSelectAcctModule from '@/store/modules/stsSelectAcctModule';
import stsSelectWTNModule from '@/store/modules/stsSelectWTNModule';
import stsSuspNonPayModule from '@/store/modules/stsSuspNonPayModule';
import stsPendingOrderModule from '@/store/modules/stsPendingOrderModule';
import performMobileSSOModule from '@/store/modules/performMobileSSOModule';

import wmtLandingModule from '@/store/modules/wmtLandingModule';
import wmtPostLoginModule from '@/store/modules/wmtPostLoginModule';
import wmtSelectAcctModule from '@/store/modules/wmtSelectAcctModule';
import wmtResultsModule from '@/store/modules/wmtResultsModule';
import wmtRescheduleModule from '@/store/modules/wmtRescheduleModule';
import wmtCancelModule from '@/store/modules/wmtCancelModule';
import sendUILogModule from '@/store/modules/senduilog';

import { SENDUILOGMODULE } from '@/store/modules/senduilog/treekeys';
import { AUTHMODULE } from '@/store/modules/auth/treekeys';
import { AZUREATTRSMODULE } from '@/store/modules/azureattrs/treekeys';
import { PROGRESSBARMODULE } from '@/store/modules/progressbar/treekeys';
import { PROGRESSSPINNERMODULE } from '@/store/modules/progressspinner/treekeys';

import { GET34SECOMUSERPROFILEMODULE } from '@/store/modules/get34SEcomUserProfileModule/treekeys';
import { GETC360ACCTSPARALLELMODULE } from '@/store/modules/getC360AcctsParallelModule/treekeys';
import { TRACKERTECHMODULE } from '@/store/modules/trackerTechModule/treekeys';
import { GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE } from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule/treekeys';
import { GETC360PENDINGORDERSPARALLELMODULE } from '@/store/modules/getC360PendingOrdersParallelModule/treekeys';
import { GETNOKIASSCTOKENPARALLELMODULE } from '@/store/modules/getNokiaSSCTokenParallelModule/treekeys';
import { GETRXAPPOINTMENTINFOSPARALLELMODULE } from '@/store/modules/getRXAppointmentInfosParallelModule/treekeys';
import { GETRXPRODUCTINFOPARALLELMODULE } from '@/store/modules/getRXProductInfoParallelModule/treekeys';
import { HANDLEQUERYPARAMSMODULE } from '@/store/modules/handleQueryParamsModule/treekeys';
import { UPDATERXTICKETPARALLELMODULE } from '@/store/modules/updateRXTicketParallelModule/treekeys';
import { STSIFRAMEMODULE } from '@/store/modules/stsIFrameModule/treekeys';
import { STSLANDINGMODULE } from '@/store/modules/stsLandingModule/treekeys';
import { STSPOSTLOGINMODULE } from '@/store/modules/stsPostLoginModule/treekeys';
import { STSSELECTACCTMODULE } from '@/store/modules/stsSelectAcctModule/treekeys';
import { STSSELECTWTNMODULE } from '@/store/modules/stsSelectWTNModule/treekeys';
import { STSSUSPNONPAYMODULE } from '@/store/modules/stsSuspNonPayModule/treekeys';
import { STSPENDINGORDERMODULE } from '@/store/modules/stsPendingOrderModule/treekeys';
import { WMTLANDINGMODULE } from '@/store/modules/wmtLandingModule/treekeys';
import { WMTPOSTLOGINMODULE } from '@/store/modules/wmtPostLoginModule/treekeys';
import { WMTSELECTACCTMODULE } from '@/store/modules/wmtSelectAcctModule/treekeys';
import { WMTRESULTSMODULE } from '@/store/modules/wmtResultsModule/treekeys';
import { WMTRESCHEDULEMODULE } from '@/store/modules/wmtRescheduleModule/treekeys';
import { WMTCANCELMODULE } from '@/store/modules/wmtCancelModule/treekeys';
import { GETPERFORMMOBILESSOMODULE } from '@/store/modules/performMobileSSOModule/treekeys';

export const SAGATASKSMODULE = 'sagaTasksModule';

const modules: any = {
  sagaTasksModule,

  [AUTHMODULE]: authModule,
  [AZUREATTRSMODULE]: azureAttrsModule,
  [PROGRESSBARMODULE]: progressBarModule,
  [PROGRESSSPINNERMODULE]: progressSpinnerModule,

  [GET34SECOMUSERPROFILEMODULE]: get34SEcomUserProfileModule,
  [GETC360ACCTSPARALLELMODULE]: getC360AcctsParallelModule,
  [GETC360PENDINGORDERSPARALLELMODULE]: getC360PendingOrdersParallelModule,
  [GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE]:
    getC360PendingOrdersAndRXTicketInfosParallelModule,
  [GETNOKIASSCTOKENPARALLELMODULE]: getNokiaSSCTokenParallelModule,
  [GETRXAPPOINTMENTINFOSPARALLELMODULE]: getRXAppointmentInfosParallelModule,
  [GETRXPRODUCTINFOPARALLELMODULE]: getRXProductInfoParallelModule,
  [TRACKERTECHMODULE]: trackerTechModule,
  [UPDATERXTICKETPARALLELMODULE]: updateRXTicketParallelModule,

  [HANDLEQUERYPARAMSMODULE]: handleQueryParamsModule,

  [STSIFRAMEMODULE]: stsIFrameModule,
  [STSLANDINGMODULE]: stsLandingModule,
  [STSPOSTLOGINMODULE]: stsPostLoginModule,
  [STSSELECTACCTMODULE]: stsSelectAcctModule,
  [STSSELECTWTNMODULE]: stsSelectWTNModule,
  [STSSUSPNONPAYMODULE]: stsSuspNonPayModule,
  [STSPENDINGORDERMODULE]: stsPendingOrderModule,
  [WMTLANDINGMODULE]: wmtLandingModule,
  [WMTPOSTLOGINMODULE]: wmtPostLoginModule,
  [WMTSELECTACCTMODULE]: wmtSelectAcctModule,
  [WMTRESULTSMODULE]: wmtResultsModule,
  [WMTRESCHEDULEMODULE]: wmtRescheduleModule,
  [WMTCANCELMODULE]: wmtCancelModule,
  [GETPERFORMMOBILESSOMODULE]: performMobileSSOModule,
  [SENDUILOGMODULE]: sendUILogModule,
};

export default modules;

export const FULL_ACCESS_MODULES = [
  AUTHMODULE,
  AZUREATTRSMODULE,
  GET34SECOMUSERPROFILEMODULE,
  GETC360ACCTSPARALLELMODULE,
  GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE,
  GETC360PENDINGORDERSPARALLELMODULE,
  GETNOKIASSCTOKENPARALLELMODULE,
  GETRXAPPOINTMENTINFOSPARALLELMODULE,
  GETRXPRODUCTINFOPARALLELMODULE,
  HANDLEQUERYPARAMSMODULE,
  UPDATERXTICKETPARALLELMODULE,
  PROGRESSBARMODULE,
  PROGRESSSPINNERMODULE,
  SAGATASKSMODULE,
  GETPERFORMMOBILESSOMODULE,
  WMTSELECTACCTMODULE,
  TRACKERTECHMODULE,
  SENDUILOGMODULE,
];
