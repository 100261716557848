export const GET_STSIFRAMEMODULE_TOKENRECEIVEDTIME =
  'GET_STSIFRAMEMODULE_TOKENRECEIVEDTIME';
export const GET_STSIFRAMEMODULE_CURRSTATE = 'GET_STSIFRAMEMODULE_CURRSTATE';
export const GET_STSIFRAMEMODULE_PREVSTATE = 'GET_STSIFRAMEMODULE_PREVSTATE';
export const GET_STSIFRAMEMODULE_ROUTEPARAMS =
  'GET_STSIFRAMEMODULE_ROUTEPARAMS';
export const MUTATE_STSIFRAMEMODULE_TOKENRECEIVEDTIME =
  'MUTATE_STSIFRAMEMODULE_TOKENRECEIVEDTIME';
export const MUTATE_STSIFRAMEMODULE_CURRSTATE =
  'MUTATE_STSIFRAMEMODULE_CURRSTATE';
export const MUTATE_STSIFRAMEMODULE_PREVSTATE =
  'MUTATE_STSIFRAMEMODULE_PREVSTATE';
export const MUTATE_STSIFRAMEMODULE_ROUTEPARAMS =
  'MUTATE_STSIFRAMEMODULE_ROUTEPARAMS';
export const ACTION_STSIFRAMEMODULE_TOKENRECEIVEDTIME =
  'ACTION_STSIFRAMEMODULE_TOKENRECEIVEDTIME';
export const ACTION_STSIFRAMEMODULE_ROUTEPARAMS =
  'ACTION_STSIFRAMEMODULE_ROUTEPARAMS';
export const STSIFRAMEMODULE = 'stsIFrameModule';
export const NS_GET_STSIFRAMEMODULE_TOKENRECEIVEDTIME = `${STSIFRAMEMODULE}/${GET_STSIFRAMEMODULE_TOKENRECEIVEDTIME}`;
export const NS_GET_STSIFRAMEMODULE_CURRSTATE = `${STSIFRAMEMODULE}/${GET_STSIFRAMEMODULE_CURRSTATE}`;
export const NS_GET_STSIFRAMEMODULE_PREVSTATE = `${STSIFRAMEMODULE}/${GET_STSIFRAMEMODULE_PREVSTATE}`;
export const NS_GET_STSIFRAMEMODULE_ROUTEPARAMS = `${STSIFRAMEMODULE}/${GET_STSIFRAMEMODULE_ROUTEPARAMS}`;
export const NS_MUTATE_STSIFRAMEMODULE_TOKENRECEIVEDTIME = `${STSIFRAMEMODULE}/${MUTATE_STSIFRAMEMODULE_TOKENRECEIVEDTIME}`;
export const NS_MUTATE_STSIFRAMEMODULE_CURRSTATE = `${STSIFRAMEMODULE}/${MUTATE_STSIFRAMEMODULE_CURRSTATE}`;
export const NS_MUTATE_STSIFRAMEMODULE_PREVSTATE = `${STSIFRAMEMODULE}/${MUTATE_STSIFRAMEMODULE_PREVSTATE}`;
export const NS_MUTATE_STSIFRAMEMODULE_ROUTEPARAMS = `${STSIFRAMEMODULE}/${MUTATE_STSIFRAMEMODULE_ROUTEPARAMS}`;
export const NS_ACTION_STSIFRAMEMODULE_TOKENRECEIVEDTIME = `${STSIFRAMEMODULE}/${ACTION_STSIFRAMEMODULE_TOKENRECEIVEDTIME}`;
export const NS_ACTION_STSIFRAMEMODULE_ROUTEPARAMS = `${STSIFRAMEMODULE}/${ACTION_STSIFRAMEMODULE_ROUTEPARAMS}`;
