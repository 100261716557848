import AbstractBusinessChain from '../abstractBusinessChain';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { useQueryParamsWMTLHandler } from './queryParamsWMTLHandler';
import { useQueryParamsWMTSAHandler } from './queryParamsWMTSAHandler';
import { useQueryParamsWMTRHandler } from './queryParamsWMTRHandler';

class QueryParamsWMTRBusinessChain extends AbstractBusinessChain<
  QueryParamsRequest,
  QueryParamsResponse
> {
  setupChain() {
    this.chainHandlers.push(useQueryParamsWMTLHandler());
    this.chainHandlers.push(useQueryParamsWMTSAHandler());
    this.chainHandlers.push(useQueryParamsWMTRHandler());
  }
}

let qp: QueryParamsWMTRBusinessChain;

export const useQueryParamsWMTRBusinessChain =
  (): QueryParamsWMTRBusinessChain => {
    if (!qp) {
      qp = new QueryParamsWMTRBusinessChain();
    }
    return qp;
  };
