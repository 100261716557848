/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGRPP from '@/store/modules/getRXProductInfoParallelModule/treekeys';
import {
  GetRXProductInfoParallelMap,
  GetRXProductInfoState,
} from '@/store/modules/getRXProductInfoParallelModule/types';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { getRXProductInfoParallel } from '../../watchGetRXProductInfoParallel';

class QueryParamsSTSLRXProductInfoHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getActionBasedOnRulesRXProductInfo = (
    getRXProductInfoParallelMap: GetRXProductInfoParallelMap,
    acctNumOrEmail: string
  ): HandleQueryParamsAction => {
    const getRXProductInfoState: GetRXProductInfoState =
      getRXProductInfoParallelMap?.[acctNumOrEmail];
    if (
      getRXProductInfoState?.rxProductInfoError &&
      Object.values(getRXProductInfoState?.rxProductInfoError).length
    ) {
      return HandleQueryParamsAction.GOTO_STSLANDING;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const getRXProductInfoParallelMap: GetRXProductInfoParallelMap =
      yield select(
        (s) =>
          s?.[tkGRPP.GETRXPRODUCTINFOPARALLELMODULE]
            ?.getRXProductInfoParallelMap
      );
    if (
      !getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo ||
      !Object.keys(getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo)
        .length
    ) {
      yield call(getRXProductInfoParallel, { payload: acctNumOrEmail });
    }

    response.handleQueryParamsAction = this.getActionBasedOnRulesRXProductInfo(
      getRXProductInfoParallelMap,
      acctNumOrEmail.replaceAll('*', '')
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSLRXProductInfoHandler;

export const useQueryParamsSTSLRXProductInfoHandler =
  (): QueryParamsSTSLRXProductInfoHandler => {
    if (!qp) {
      qp = new QueryParamsSTSLRXProductInfoHandler();
    }
    return qp;
  };
