export const GET_AZURE_ATTRS = 'getAzureAttrs';
export const HANDLE_AUTH = 'handleAuth';
export const HANDLE_PROGRESS_BAR = 'handleProgressBar';
export const HANDLE_PROGRESS_BAR_FINISH_SEGMENTS =
  'handleProgressBarFinishSegments';
export const HANDLE_PROGRESS_SPINNER = 'handleProgressSpinner';
export const HANDLELOGOUT = 'handleLogout';
export const HANDLE_USER_PAGE_CLOSE = 'handleUserPageClose';

export const CANCEL_GET_AZURE_ATTRS = 'cancelGetAzureAttrs';
export const CANCEL_HANDLE_PROGRESS_BAR = 'cancelHandleProgressBar';

export const GET34SECOMUSERPROFILE = 'get34SEcomUserProfile';
export const PERFORMMOBILESSO = 'performMobileSSO';
export const GETC360ACCTSPARALLEL = 'getC360AcctsParallel';
export const GETC360PENDINGORDERSANDRXTICKETINFOSPARALLEL =
  'getC360PendingOrdersAndRXTicketInfosParallel';
export const GETC360PENDINGORDERSPARALLEL = 'getC360PendingOrdersParallel';
export const GETNOKIASSCTOKENPARALLEL = 'getNokiaSSCTokenParallel';
export const GETRXAPPOINTMENTINFOSPARALLEL = 'getRXAppointmentInfosParallel';
export const GETRXPRODUCTINFOPARALLEL = 'getRXProductInfoParallel';
export const GETSERVICEADDRESSANDTECHNICIANDETAILS =
  'getServiceAddressAndTechnicianDetails';
export const GETSERVICEADDRESSGEOCODEINFO = 'getServiceAddressGeocodeInfo';
export const UPDATERXTICKETPARALLEL = 'updateRXTicketParallel';

export const HANDLEQUERYPARAMSSTSIF = 'handleQueryParamsSTSIF';
export const HANDLEQUERYPARAMSSTSSA = 'handleQueryParamsSTSSA';
export const HANDLEQUERYPARAMSSTSSW = 'handleQueryParamsSTSSW';
export const HANDLEQUERYPARAMSWMTSA = 'handleQueryParamsWMTSA';
export const HANDLEQUERYPARAMSWMTR = 'handleQueryParamsWMTR';
export const HANDLEQUERYPARAMSWMTUT = 'handleQueryParamsWMTUT';
export const HANDLEACCTNUMQUERYPARAMS = 'handleAcctNumQueryParams';
export const HANDLEACCTNUMQUERYPARAMSSTS = 'handleAcctNumQueryParamsSTS';
export const HANDLEACCTNUMQUERYPARAMSSTSSELECTWTN =
  'handleAcctNumQueryParamsSTSSelectWTN';
export const HANDLETICKETNUMQUERYPARAMS = 'handleTicketNumQueryParams';
export const HANDLEWTNQUERYPARAMS = 'handleWTNQueryParams';

export const STSLANDINGGETC360ACCTS = 'stsLandingGetC360Accts';
export const WMTCANCELUPDATERXTICKET = 'wmtCancelUpdateRXTicket';
export const WMTLANDINGGETC360ACCTS = 'wmtLandingGetC360Accts';
export const WMTPOSTLOGINGETC360ACCTSFOREACHACCTIN34S =
  'wmtPostLoginGetC360AcctsForEachAcctIn34S';
export const WMTPOSTLOGINGETC360ACCTSPARALLEL =
  'wmtLandingGetC360AcctsParallel';
export const WMTRESCHEDULEGETRXAPPOINTMENTINFOS =
  'wmtRescheduleGetRXAppointmentInfos';
export const WMTRESCHEDULEUPDATERXTICKET = 'wmtRescheduleUpdateRXTicket';
export const WMTRESULTSGETC360POANDRXTI = 'wmtResultsGetC360POAndRXTI';

export const CANCELWMTLANDINGGETC360ACCTS = 'cancelWMTLandingGetC360Accts';
export const CANCELWMTRESULTSGETC360POANDRXTI =
  'cancelWMTResultsGetC360POAndRXTI';
export const CANCELWMTRESCHEDULEGETRXAPPOINTMENTINFOS =
  'cancelWMTRescheduleGetRXAppointmentInfos';

export const SENDUILOG = 'sendUILog';
