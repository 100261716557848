/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/getNokiaSSCTokenParallelModule/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import { getNokiaSSCToken as getNokiaSSCTokenApi } from './api/getNokiaSSCToken';
import { GetNokiaSSCTokenParallelMap } from '../modules/getNokiaSSCTokenParallelModule/types';

const shouldCall = (
  gnstpMap: GetNokiaSSCTokenParallelMap,
  acctNum: string,
  wtn: string
) => acctNum && wtn && gnstpMap && !gnstpMap[`${acctNum}_${wtn}`]?.pending;

export function* getNokiaSSCTokenParallel(nokiaSSCTokenPayload: any): any {
  const acctNum: string =
    nokiaSSCTokenPayload?.payload?.accountNumber?.replaceAll('*', '');
  const wtn: string = nokiaSSCTokenPayload?.payload?.wtn?.replaceAll('*', '');
  const gnstpMap: GetNokiaSSCTokenParallelMap = yield select(
    (s) => s?.[tk.GETNOKIASSCTOKENPARALLELMODULE]?.getNokiaSSCTokenParallelMap
  );
  const shouldThrottle: boolean = yield select(
    (s) => s?.[tk.GETNOKIASSCTOKENPARALLELMODULE]?.shouldThrottle
  );
  if (shouldCall(gnstpMap, acctNum, wtn) && !shouldThrottle) {
    yield put({
      type: tk.NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING,
      key: `${acctNum}_${wtn}`,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) => s?.[tk.GETNOKIASSCTOKENPARALLELMODULE]?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }

      const resp = yield call(getNokiaSSCTokenApi, wtn, acctNum, isAuth);
      if (resp?.data) {
        yield put({
          type: tk.NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN,
          token: resp.data,
          key: `${acctNum}_${wtn}`,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR,
          tokenError: resp,
          key: `${acctNum}_${wtn}`,
        });
      }
    } catch (err) {
      if (gnstpMap[acctNum]) {
        yield put({
          type: tk.NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR,
          tokenError: err,
          key: `${acctNum}_${wtn}`,
        });
      }
    } finally {
      if (yield cancelled()) {
        if (gnstpMap[`${acctNum}_${wtn}`]) {
          yield put({
            type: tk.NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR,
            tokenError: 'SAGA_CANCELLED',
            key: `${acctNum}_${wtn}`,
          });
        }
      }
    }
  }
}

export function* watchGetNokiaSSCTokenParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getNokiaSSCTokenParallel);
}

export default watchGetNokiaSSCTokenParallel;
