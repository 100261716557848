export const GET_WMTCANCELMODULE_CURRSTATE = 'GET_WMTCANCELMODULE_CURRSTATE';
export const GET_WMTCANCELMODULE_PREVSTATE = 'GET_WMTCANCELMODULE_PREVSTATE';

export const MUTATE_WMTCANCELMODULE_CURRSTATE =
  'MUTATE_WMTCANCELMODULE_CURRSTATE';
export const MUTATE_WMTCANCELMODULE_PREVSTATE =
  'MUTATE_WMTCANCELMODULE_PREVSTATE';

export const WMTCANCELMODULE = 'wmtCancelModule';
export const NS_GET_WMTCANCELMODULE_CURRSTATE = `${WMTCANCELMODULE}/${GET_WMTCANCELMODULE_CURRSTATE}`;
export const NS_GET_WMTCANCELMODULE_PREVSTATE = `${WMTCANCELMODULE}/${GET_WMTCANCELMODULE_PREVSTATE}`;
export const NS_MUTATE_WMTCANCELMODULE_CURRSTATE = `${WMTCANCELMODULE}/${MUTATE_WMTCANCELMODULE_CURRSTATE}`;
export const NS_MUTATE_WMTCANCELMODULE_PREVSTATE = `${WMTCANCELMODULE}/${MUTATE_WMTCANCELMODULE_PREVSTATE}`;
