export const GET_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP =
  'GET_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP';
export const GET_GETC360ACCTSPARALLELMODULE_SHOULDTHROTTLE =
  'GET_GETC360ACCTSPARALLELMODULE_SHOULDTHROTTLE';
export const GET_GETC360ACCTSPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_GETC360ACCTSPARALLELMODULE_SHOULDSHIFTLRU';
export const GET_GETC360ACCTSPARALLELMODULE_ACCTNUMPOSTALCODEMAP =
  'GET_GETC360ACCTSPARALLELMODULE_ACCTNUMPOSTALCODEMAP';
export const GET_GETC360ACCTSPARALLELMODULE_ACCTNUMACCTSTATUSMAP =
  'GET_GETC360ACCTSPARALLELMODULE_ACCTNUMACCTSTATUSMAP';
export const GET_GETC360ACCTSPARALLELMODULE_ACCTNUMASSOCC360ACCOUNTSMAP =
  'GET_GETC360ACCTSPARALLELMODULE_ACCTNUMASSOCC360ACCOUNTSMAP';
export const GET_GETC360ACCTSPARALLELMODULE_ACCTNUMNUMKEYMAP =
  'GET_GETC360ACCTSPARALLELMODULE_ACCTNUMNUMKEYMAP';
export const GET_GETC360ACCTSPARALLELMODULE_ACCTNUM_COMPANYOWNER_IDMAP =
  'GET_GETC360ACCTSPARALLELMODULE_ACCTNUM_COMPANYOWNER_IDMAP';
export const GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID =
  'GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID';
export const GET_GETC360ACCTSPARALLELMODULE_POSTALCODEACCTNUMSMAP =
  'GET_GETC360ACCTSPARALLELMODULE_POSTALCODEACCTNUMSMAP';
export const GET_GETC360ACCTSPARALLELMODULE_NUMKEYNONINACTIVEANDNONSUSPACCTSMAP =
  'GET_GETC360ACCTSPARALLELMODULE_NUMKEYNONINACTIVEANDNONSUSPACCTSMAP';
export const GET_GETC360ACCTSPARALLELMODULE_NUMKEYPOSTALCODENONINACTIVEANDNONSUSPACCTSMAPMAP =
  'GET_GETC360ACCTSPARALLELMODULE_NUMKEYPOSTALCODENONINACTIVEANDNONSUSPACCTSMAPMAP';
export const GET_GETC360ACCTSPARALLELMODULE_COMPANY_ID_BY_C360ACCOUNT =
  'GET_GETC360ACCTSPARALLELMODULE_COMPANY_ID_BY_C360ACCOUNT';
export const MUTATE_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP =
  'MUTATE_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP';
export const MUTATE_GETC360ACCTSPARALLELMODULE_SHIFTLRU =
  'MUTATE_GETC360ACCTSPARALLELMODULE_SHIFTLRU';
export const MUTATE_GETC360ACCTSPARALLELMODULE_PENDING =
  'MUTATE_GETC360ACCTSPARALLELMODULE_PENDING';
export const MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST =
  'MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST';
export const MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR =
  'MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR';
export const MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID =
  'MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID';
export const MUTATE_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID =
  'MUTATE_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID';
export const ACTION_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP =
  'ACTION_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP';
export const ACTION_GETC360ACCTSPARALLELMODULE_PENDING =
  'ACTION_GETC360ACCTSPARALLELMODULE_PENDING';
export const ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST =
  'ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST';
export const ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR =
  'ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR';
export const ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID =
  'ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID';
export const ACTION_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID =
  'ACTION_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID';
export const GETC360ACCTSPARALLELMODULE = 'getC360AcctsParallelModule';
export const GETC360ACCTSPARALLELMODULE_MAXPARALLEL = 3;
export const GETC360ACCTSPARALLELMODULE_MAXENTRIES = 20;
export const NS_GET_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_SHOULDTHROTTLE = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_SHOULDSHIFTLRU = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_ACCTNUMPOSTALCODEMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_ACCTNUMPOSTALCODEMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_ACCTNUMACCTSTATUSMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_ACCTNUMACCTSTATUSMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_ACCTNUMASSOCC360ACCOUNTSMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_ACCTNUMASSOCC360ACCOUNTSMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_POSTALCODEACCTNUMSMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_POSTALCODEACCTNUMSMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_NUMKEYNONINACTIVEANDNONSUSPACCTSMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_NUMKEYNONINACTIVEANDNONSUSPACCTSMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_NUMKEYPOSTALCODENONINACTIVEANDNONSUSPACCTSMAPMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_NUMKEYPOSTALCODENONINACTIVEANDNONSUSPACCTSMAPMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_ACCTNUMNUMKEYMAP = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_ACCTNUMNUMKEYMAP}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID}`;
export const NS_MUTATE_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP = `${GETC360ACCTSPARALLELMODULE}/${MUTATE_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP}`;
export const NS_MUTATE_GETC360ACCTSPARALLELMODULE_SHIFTLRU = `${GETC360ACCTSPARALLELMODULE}/${MUTATE_GETC360ACCTSPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_GETC360ACCTSPARALLELMODULE_PENDING = `${GETC360ACCTSPARALLELMODULE}/${MUTATE_GETC360ACCTSPARALLELMODULE_PENDING}`;
export const NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST = `${GETC360ACCTSPARALLELMODULE}/${MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST}`;
export const NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR = `${GETC360ACCTSPARALLELMODULE}/${MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR}`;
export const NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID = `${GETC360ACCTSPARALLELMODULE}/${MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID}`;
export const NS_ACTION_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP = `${GETC360ACCTSPARALLELMODULE}/${ACTION_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP}`;
export const NS_ACTION_GETC360ACCTSPARALLELMODULE_PENDING = `${GETC360ACCTSPARALLELMODULE}/${ACTION_GETC360ACCTSPARALLELMODULE_PENDING}`;
export const NS_ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST = `${GETC360ACCTSPARALLELMODULE}/${ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST}`;
export const NS_ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR = `${GETC360ACCTSPARALLELMODULE}/${ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR}`;
export const NS_ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID = `${GETC360ACCTSPARALLELMODULE}/${ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID}`;
export const NS_GET_GETC360ACCTSPARALLELMODULE_COMPANY_ID_BY_C360ACCOUNT = `${GETC360ACCTSPARALLELMODULE}/${GET_GETC360ACCTSPARALLELMODULE_COMPANY_ID_BY_C360ACCOUNT}`;
export const NS_ACTION_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID = `${GETC360ACCTSPARALLELMODULE}/${ACTION_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID}`;
