/* eslint-disable no-unused-vars */
export enum HandleQueryParamsAction {
  PENDING = 'PENDING',
  GOTO_WMTLANDING = 'GOTO_WMTLANDING',
  GOTO_WMTSELECTACCT = 'GOTO_WMTSELECTACCT',
  GOTO_WMTRESULTS = 'GOTO_WMTRESULTS',
  GOTO_WMTUPDATETICKET = 'GOTO_WMTUPDATETICKET',
  GOTO_WMTUPDATETICKETNOTCANCELNOTRESCHEDULE = 'GOTO_WMTUPDATETICKETNOTCANCELNOTRESCHEDULE',
  GOTO_WMTUPDATETICKETNOTCANCEL = 'GOTO_WMTUPDATETICKETNOTCANCEL',
  GOTO_WMTUPDATETICKETNOTRESCHEDULE = 'GOTO_WMTUPDATETICKETNOTRESCHEDULE',
  GOTO_STSLANDING = 'GOTO_STSLANDING',
  GOTO_STSSELECTACCT = 'GOTO_STSSELECTACCT',
  GOTO_STSSUSPNONPAY = 'GOTO_STSSUSPNONPAY',
  GOTO_STSPENDINGORDER = 'GOTO_STSPENDINGORDER',
  GOTO_STSNOPRODUCTS = 'GOTO_STSNOPRODUCTS',
  GOTO_STSSUSPVAC = 'GOTO_STSSUSPVAC',
  GOTO_STSSELECTWTN = 'GOTO_STSSELECTWTN',
  GOTO_STSIFRAME = 'GOTO_STSIFRAME',
}

export interface HandleQueryParamsState {
  handleQueryParamsAction: HandleQueryParamsAction;
}
