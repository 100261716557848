/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/updateRXTicketParallelModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import { updateRXTicket as updateRXTicketApi } from './api/updateRXTicket';
import { UpdateRXTicketParallelMap } from '../modules/updateRXTicketParallelModule/types';

const isGoodResp = (resp: any) =>
  resp?.data?.updateTicketResponse?.apiResponse?.type &&
  !resp.data.updateTicketResponse.apiResponse.type.toLowerCase().includes('f');

const shouldCall = (urtpMap: UpdateRXTicketParallelMap, tktNum: string) =>
  tktNum && urtpMap && !urtpMap[tktNum]?.pending;

export function* updateRXTicketParallel(updateRXTicketPayload: any): any {
  const tktNum: string =
    updateRXTicketPayload?.payload?.nativeTroubleTicketNumber;
  const urtpMap: UpdateRXTicketParallelMap = yield select(
    (s) => s?.[tk.UPDATERXTICKETPARALLELMODULE]?.updateRXTicketParallelMap
  );
  const shouldThrottle: boolean = yield select(
    (s) => s?.[tk.UPDATERXTICKETPARALLELMODULE]?.shouldThrottle
  );
  if (shouldCall(urtpMap, tktNum) && !shouldThrottle) {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: true,
    });
    yield put({
      type: tk.NS_MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING,
      key: tktNum,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) => s?.[tk.UPDATERXTICKETPARALLELMODULE]?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_UPDATERXTICKETPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }
      const resp = yield call(
        updateRXTicketApi,
        {
          ...updateRXTicketPayload?.payload,
        },
        isAuth
      );
      if (isGoodResp(resp)) {
        yield put({
          type: tk.NS_MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE,
          response: resp.data,
          key: tktNum,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR,
          responseError: resp,
          key: tktNum,
        });
      }
    } catch (err) {
      if (urtpMap[tktNum]) {
        yield put({
          type: tk.NS_MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR,
          responseError: err,
          key: tktNum,
        });
      }
    } finally {
      yield put({
        type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
        progressSpinnerAppear: false,
      });
      if (yield cancelled()) {
        if (urtpMap[tktNum]) {
          yield put({
            type: tk.NS_MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR,
            responseError: 'SAGA_CANCELLED',
            key: tktNum,
          });
        }
      }
    }
  }
}

export function* watchUpdateRXTicketParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, updateRXTicketParallel);
}

export default watchUpdateRXTicketParallel;
