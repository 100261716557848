/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createStore, DispatchOptions } from 'vuex';
import modules, { FULL_ACCESS_MODULES } from './modules';

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [],
});

export default store;

export const useSagaDispatch =
  () =>
  (s: string, ...a: any[]) => {
    const storeAny: any = store;
    const { sagaDispatch } = storeAny;
    sagaDispatch?.apply(storeAny, [s, ...a]);
  };

export const useModuleMediatorStore = (
  nsGet: string,
  nsAction: string
): any => {
  const s0: any = store;
  const getters = {};
  const state0 = {};
  Object.keys(s0.getters)
    .filter(
      (getKey) =>
        getKey.startsWith(nsGet) ||
        FULL_ACCESS_MODULES.some((fam) => getKey.startsWith(fam))
    )
    .forEach((getKey) => {
      Object.defineProperty(getters, getKey, {
        get: () => s0.getters[getKey],
      });
    });
  Object.keys(s0.state)
    .filter(
      (nsModule) =>
        nsModule.startsWith(nsGet) ||
        FULL_ACCESS_MODULES.some((fam) => nsModule.startsWith(fam))
    )
    .forEach((nsModule) => {
      Object.defineProperty(state0, nsModule, {
        get: () => (s0.state as any)[nsModule],
      });
    });
  if (process.env.NODE_ENV === 'development') {
    Object.keys(s0.getters)
      .filter(
        (getKey) =>
          !getKey.startsWith(nsGet) &&
          !FULL_ACCESS_MODULES.some((fam) => getKey.startsWith(fam))
      )
      .forEach((getKey) => {
        Object.defineProperty(getters, getKey, {
          get: () => {
            throw new Error(
              `${getKey} accessed outside assigned namespace ${nsGet}, if ${getKey} should be accessible anywhere then add its module to FULL_ACCESS_MODULES array in src/store/modules/index.ts, ensure ${nsGet} is registered properly in the modules object in src/store/modules/index.ts`
            );
          },
        });
      });
    Object.keys(s0.state)
      .filter(
        (nsModule) =>
          !nsModule.startsWith(nsGet) &&
          !FULL_ACCESS_MODULES.some((fam) => nsModule.startsWith(fam))
      )
      .forEach((nsModule) => {
        Object.defineProperty(state0, nsModule, {
          get: () => {
            throw new Error(
              `${nsModule} accessed outside assigned namespace ${nsGet}, if ${nsModule} should be accessible anywhere then add its module to FULL_ACCESS_MODULES array in src/store/modules/index.ts, ensure ${nsGet} is registered properly in the modules object in src/store/modules/index.ts`
            );
          },
        });
      });
  }
  return {
    ...s0,
    getters,
    state: state0,
    commit: (type: any, payload: any) => {
      if (
        !type?.type?.startsWith(nsAction) &&
        !FULL_ACCESS_MODULES.some((fam) => type?.type?.startsWith(fam))
      ) {
        throw new Error(
          `${type?.type} accessed outside assigned namespace ${nsAction}, if ${type?.type} should be accessible anywhere then add its module to FULL_ACCESS_MODULES array in src/store/modules/index.ts, ensure ${nsAction} is registered properly in the modules object in src/store/modules/index.ts`
        );
      }
      s0.commit(type, payload);
    },
    dispatch: (
      actionKey: string,
      payload?: any,
      options?: DispatchOptions
    ): Promise<any> => {
      if (
        !actionKey.startsWith(nsAction) &&
        !FULL_ACCESS_MODULES.some((fam) => actionKey?.startsWith(fam))
      ) {
        throw new Error(
          `${actionKey} accessed outside assigned namespace ${nsAction}, if ${actionKey} should be accessible anywhere then add its module to FULL_ACCESS_MODULES array in src/store/modules/index.ts, ensure ${nsAction} is registered properly in the modules object in src/store/modules/index.ts`
        );
      }
      return s0.dispatch(actionKey, payload, options);
    },
  };
};
export const useNSProtectedStore = (ns: string): any =>
  useModuleMediatorStore(ns, ns);
