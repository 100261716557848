export const GET_STSSUSPNONPAYMODULE_CURRSTATE =
  'GET_STSSUSPNONPAYMODULE_CURRSTATE';
export const GET_STSSUSPNONPAYMODULE_PREVSTATE =
  'GET_STSSUSPNONPAYMODULE_PREVSTATE';
export const GET_STSSUSPNONPAYMODULE_ROUTEPARAMS =
  'GET_STSSUSPNONPAYMODULE_ROUTEPARAMS';
export const MUTATE_STSSUSPNONPAYMODULE_CURRSTATE =
  'MUTATE_STSSUSPNONPAYMODULE_CURRSTATE';
export const MUTATE_STSSUSPNONPAYMODULE_PREVSTATE =
  'MUTATE_STSSUSPNONPAYMODULE_PREVSTATE';
export const MUTATE_STSSUSPNONPAYMODULE_ROUTEPARAMS =
  'MUTATE_STSSUSPNONPAYMODULE_ROUTEPARAMS';
export const ACTION_STSSUSPNONPAYMODULE_ROUTEPARAMS =
  'ACTION_STSSUSPNONPAYMODULE_ROUTEPARAMS';
export const STSSUSPNONPAYMODULE = 'stsSuspNonPayModule';
export const NS_GET_STSSUSPNONPAYMODULE_CURRSTATE = `${STSSUSPNONPAYMODULE}/${GET_STSSUSPNONPAYMODULE_CURRSTATE}`;
export const NS_GET_STSSUSPNONPAYMODULE_PREVSTATE = `${STSSUSPNONPAYMODULE}/${GET_STSSUSPNONPAYMODULE_PREVSTATE}`;
export const NS_GET_STSSUSPNONPAYMODULE_ROUTEPARAMS = `${STSSUSPNONPAYMODULE}/${GET_STSSUSPNONPAYMODULE_ROUTEPARAMS}`;
export const NS_MUTATE_STSSUSPNONPAYMODULE_CURRSTATE = `${STSSUSPNONPAYMODULE}/${MUTATE_STSSUSPNONPAYMODULE_CURRSTATE}`;
export const NS_MUTATE_STSSUSPNONPAYMODULE_PREVSTATE = `${STSSUSPNONPAYMODULE}/${MUTATE_STSSUSPNONPAYMODULE_PREVSTATE}`;
export const NS_MUTATE_STSSUSPNONPAYMODULE_ROUTEPARAMS = `${STSSUSPNONPAYMODULE}/${MUTATE_STSSUSPNONPAYMODULE_ROUTEPARAMS}`;
export const NS_ACTION_STSSUSPNONPAYMODULE_ROUTEPARAMS = `${STSSUSPNONPAYMODULE}/${ACTION_STSSUSPNONPAYMODULE_ROUTEPARAMS}`;
