/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  GetC360PendingOrdersParallelState,
  GetC360PendingOrdersState,
} from './types';
import * as treekeys from './treekeys';

export const data: GetC360PendingOrdersParallelState = {
  getC360PendingOrdersParallelMap: {},
  shouldThrottle: false,
  lruKeyArr: [],
  shouldShiftLRU: false,
};
export const getters: GetterTree<GetC360PendingOrdersParallelState, any> = {
  [treekeys.GET_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP]:
    (state) => state.getC360PendingOrdersParallelMap,
  [treekeys.GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDTHROTTLE]: (state) =>
    state.shouldThrottle,
  [treekeys.GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDSHIFTLRU]: (state) =>
    state.shouldShiftLRU,
};
export const mutations: MutationTree<GetC360PendingOrdersParallelState> = {
  [treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP]:
    (state, { getC360PendingOrdersParallelMap }) => {
      state.getC360PendingOrdersParallelMap = getC360PendingOrdersParallelMap;
    },
  [treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_SHIFTLRU]: (state) => {
    delete state.getC360PendingOrdersParallelMap[state.lruKeyArr.shift() || ''];
    state.shouldThrottle =
      Object.values(state.getC360PendingOrdersParallelMap).filter(
        (getC360PendingOrdersState: GetC360PendingOrdersState) =>
          getC360PendingOrdersState.pending
      ).length >= treekeys.GETC360PENDINGORDERSPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING]: (
    state,
    { key }
  ) => {
    state.getC360PendingOrdersParallelMap[key] ? 0 : state.lruKeyArr.push(key);
    state.shouldShiftLRU =
      state.lruKeyArr.length >
      treekeys.GETC360PENDINGORDERSPARALLELMODULE_MAXENTRIES;
    state.getC360PendingOrdersParallelMap[key] =
      state.getC360PendingOrdersParallelMap[key] || {};
    state.getC360PendingOrdersParallelMap[key].pending = true;
    state.shouldThrottle =
      Object.values(state.getC360PendingOrdersParallelMap).filter(
        (getC360PendingOrdersState: GetC360PendingOrdersState) =>
          getC360PendingOrdersState.pending
      ).length >= treekeys.GETC360PENDINGORDERSPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS]: (
    state,
    { key, c360PendingOrders }
  ) => {
    state.getC360PendingOrdersParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getC360PendingOrdersParallelMap).filter(
        (getC360PendingOrdersState: GetC360PendingOrdersState) =>
          getC360PendingOrdersState.pending
      ).length >= treekeys.GETC360PENDINGORDERSPARALLELMODULE_MAXPARALLEL;
    state.getC360PendingOrdersParallelMap[key].c360PendingOrders =
      c360PendingOrders;
    state.getC360PendingOrdersParallelMap[key].c360PendingOrdersError =
      undefined;
  },
  [treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR]: (
    state,
    { key, c360PendingOrdersError }
  ) => {
    state.getC360PendingOrdersParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getC360PendingOrdersParallelMap).filter(
        (getC360PendingOrdersState: GetC360PendingOrdersState) =>
          getC360PendingOrdersState.pending
      ).length >= treekeys.GETC360PENDINGORDERSPARALLELMODULE_MAXPARALLEL;
    state.getC360PendingOrdersParallelMap[key].c360PendingOrders = {};
    state.getC360PendingOrdersParallelMap[key].c360PendingOrdersError =
      c360PendingOrdersError;
  },
};
export const actions: ActionTree<GetC360PendingOrdersParallelState, any> = {
  [treekeys.ACTION_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP]:
    ({ commit }, { getC360PendingOrdersParallelMap }) => {
      commit(
        treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP,
        {
          getC360PendingOrdersParallelMap,
        }
      );
    },
  [treekeys.ACTION_GETC360PENDINGORDERSPARALLELMODULE_PENDING]: (
    { commit },
    { key }
  ) => {
    commit(treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING, { key });
  },
  [treekeys.ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS]: (
    { commit },
    { key, c360PendingOrders }
  ) => {
    commit(
      treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS,
      {
        key,
        c360PendingOrders,
      }
    );
  },
  [treekeys.ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR]: (
    { commit },
    { key, c360PendingOrdersError }
  ) => {
    commit(
      treekeys.MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR,
      {
        key,
        c360PendingOrdersError,
      }
    );
  },
};

const getC360PendingOrdersParallelModule: Module<
  GetC360PendingOrdersParallelState,
  any
> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default getC360PendingOrdersParallelModule;
