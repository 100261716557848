import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { WMTRescheduleIterState, WMTRescheduleState } from './types';
import * as treekeys from './treekeys';

const initState: WMTRescheduleIterState = {
  keyReload: false,
};

export const data: WMTRescheduleState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<WMTRescheduleState, any> = {
  [treekeys.GET_WMTRESCHEDULEMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_WMTRESCHEDULEMODULE_PREVSTATE]: (state) => state.prevState,
  [treekeys.GET_WMTRESCHEDULEMODULE_KEYRELOAD]: (state) =>
    state.currState.keyReload,
};
export const mutations: MutationTree<WMTRescheduleState> = {
  [treekeys.MUTATE_WMTRESCHEDULEMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_WMTRESCHEDULEMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
  [treekeys.MUTATE_WMTRESCHEDULEMODULE_KEYRELOAD]: (state, { keyReload }) => {
    state.currState.keyReload = keyReload;
  },
};
export const actions: ActionTree<WMTRescheduleState, any> = {
  [treekeys.ACTION_WMTRESCHEDULEMODULE_KEYRELOAD]: (
    { commit },
    { keyReload }
  ) => {
    commit(treekeys.MUTATE_WMTRESCHEDULEMODULE_KEYRELOAD, { keyReload });
  },
};
const wmtRescheduleModule: Module<WMTRescheduleState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default wmtRescheduleModule;
