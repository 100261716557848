import axios from 'axios';
import * as envVars from '@/common/envVars';
import * as apiEndpts from '@/common/apiEndpts';
import { useCookies } from 'vue3-cookies';

class CookieUtilsSingleton {
  async clearAllCookies() {
    const { cookies } = useCookies();
    cookies.keys().forEach((cookie) => {
      cookies.remove(cookie);
    });
    try {
      const clearServerCookies = await axios.get(
        `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.AUTH_CLEAR_COOKIES}`,
        {
          timeout: 10000,
          signal: abortSignal(10000),
        }
      );
      if (clearServerCookies?.data) {
        console.log(
          `SUCCESS: Server-side deletion complete! - ${clearServerCookies?.data}`
        );
      } else {
        console.log(`ERROR: Cookies unsuccesfully deleted!`);
      }
    } catch (err) {
      console.log(`ERROR: Error occured while trying to delete cookies!`);
    }
  }
}

function abortSignal(timeOut: number) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeOut || 0);
  return abortController.signal;
}

let cookieUtilsSingleton: CookieUtilsSingleton;

export const useCookieUtilsSingleton = (): CookieUtilsSingleton => {
  if (!cookieUtilsSingleton) {
    cookieUtilsSingleton = new CookieUtilsSingleton();
  }
  return cookieUtilsSingleton;
};
