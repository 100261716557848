import { put, takeLatest } from '@redux-saga/core/effects';
import { LOCALSTORAGE_FRONTCHANNELLOGOUT } from '@/common/strings';
import * as apiEndpts from '../../common/apiEndpts';
import * as envVars from '../../common/envVars';
import * as tkA from '../modules/auth/treekeys';
import * as tkAA from '../modules/azureattrs/treekeys';
import * as tkG3EUP from '../modules/get34SEcomUserProfileModule/treekeys';

export function* handleLogout(): any {
  yield put({
    type: tkG3EUP.NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR,
    _34SEcomUserProfileError: 'SAGA_LOGOUT',
  });
  yield put({
    type: tkA.NS_MUTATE_AUTH_REJECTED,
    authenticationError: 'SAGA_LOGOUT',
  });
  yield put({
    type: tkAA.NS_MUTATE_AZUREATTRS_REJECTED,
    azureAttrsError: 'SAGA_LOGOUT',
  });
  localStorage.setItem(LOCALSTORAGE_FRONTCHANNELLOGOUT, 'true');
  window.location.href = `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.AUTH_LOGOUT}`;
}

export function* watchHandleLogout(pattern: string): Generator<any, any, any> {
  yield takeLatest(pattern, handleLogout);
}

export default watchHandleLogout;
