import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import getServiceAddressAndTechnicianInfoMockResp from '../../../assets/json/apiMockResp/getServiceAddressAndTechnicianInfos.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 1000;
const MOCK_TIME_BOUND_MAX = 2000;

export function* getServiceAddressAndTechnicianInfos(trackerInfo: string): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: getServiceAddressAndTechnicianInfoMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  if (trackerInfo) {
    return yield call(
      axios.get,
      `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.WMT_GET_SERVICE_ADDRESS_TECHNICIAN_INFO}?trackerInfo=${trackerInfo}`,
      {
        withCredentials: true,
        headers: {
          ApiKey: encryptedApiKey,
        },
        /* timeout: 60000, */
      }
    );
  }
  return yield call(
    axios.get,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.WMT_GET_SERVICE_ADDRESS_TECHNICIAN_INFO}`,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
