export const GET_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP =
  'GET_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP';
export const GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDTHROTTLE =
  'GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDTHROTTLE';
export const GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDSHIFTLRU';
export const GET_GETRXPRODUCTINFOPARALLELMODULE_GETACCTNUMWTNSMAP =
  'GET_GETRXPRODUCTINFOPARALLELMODULE_GETACCTNUMWTNSMAP';
export const MUTATE_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP =
  'MUTATE_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP';
export const MUTATE_GETRXPRODUCTINFOPARALLELMODULE_SHIFTLRU =
  'MUTATE_GETRXPRODUCTINFOPARALLELMODULE_SHIFTLRU';
export const MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING =
  'MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING';
export const MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO =
  'MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO';
export const MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR =
  'MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR';
export const MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO =
  'MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO';
export const ACTION_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP =
  'ACTION_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP';
export const ACTION_GETRXPRODUCTINFOPARALLELMODULE_PENDING =
  'ACTION_GETRXPRODUCTINFOPARALLELMODULE_PENDING';
export const ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO =
  'ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO';
export const ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR =
  'ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR';
export const GETRXPRODUCTINFOPARALLELMODULE = 'getRXProductInfoParallelModule';
export const GETRXPRODUCTINFOPARALLELMODULE_MAXPARALLEL = 3;
export const GETRXPRODUCTINFOPARALLELMODULE_MAXENTRIES = 10;
export const NS_GET_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP = `${GETRXPRODUCTINFOPARALLELMODULE}/${GET_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP}`;
export const NS_GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDTHROTTLE = `${GETRXPRODUCTINFOPARALLELMODULE}/${GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDSHIFTLRU = `${GETRXPRODUCTINFOPARALLELMODULE}/${GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_GET_GETRXPRODUCTINFOPARALLELMODULE_GETACCTNUMWTNSMAP = `${GETRXPRODUCTINFOPARALLELMODULE}/${GET_GETRXPRODUCTINFOPARALLELMODULE_GETACCTNUMWTNSMAP}`;
export const NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP = `${GETRXPRODUCTINFOPARALLELMODULE}/${MUTATE_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP}`;
export const NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_SHIFTLRU = `${GETRXPRODUCTINFOPARALLELMODULE}/${MUTATE_GETRXPRODUCTINFOPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING = `${GETRXPRODUCTINFOPARALLELMODULE}/${MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING}`;
export const NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO = `${GETRXPRODUCTINFOPARALLELMODULE}/${MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO}`;
export const NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR = `${GETRXPRODUCTINFOPARALLELMODULE}/${MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR}`;
export const NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO = `${GETRXPRODUCTINFOPARALLELMODULE}/${MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO}`;
export const NS_ACTION_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP = `${GETRXPRODUCTINFOPARALLELMODULE}/${ACTION_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP}`;
export const NS_ACTION_GETRXPRODUCTINFOPARALLELMODULE_PENDING = `${GETRXPRODUCTINFOPARALLELMODULE}/${ACTION_GETRXPRODUCTINFOPARALLELMODULE_PENDING}`;
export const NS_ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO = `${GETRXPRODUCTINFOPARALLELMODULE}/${ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO}`;
export const NS_ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR = `${GETRXPRODUCTINFOPARALLELMODULE}/${ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR}`;
