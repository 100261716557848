import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { SagaTasksState } from './types';
import * as tk from './treekeys';

const dataInit: any = {
  blockingVars: {},
  [tk.K_GETAZUREATTRSTASK]: undefined,
  [tk.K_HANDLEAUTHTASK]: undefined,
  [tk.K_HANDLEPROGRESSBARTASK]: undefined,
  [tk.K_HANDLEPROGRESSSPINNERTASK]: undefined,
  [tk.K_GETC360ACCTSTASK]: undefined,
  [tk.K_GETC360PENDINGORDERSANDRXTICKETINFOSTASK]: undefined,
  [tk.K_GETRXAPPOINTMENTINFOSTASK]: undefined,
  [tk.K_UPDATERXTICKETTASK]: undefined,
  [tk.K_STSLANDINGGETC360ACCTSTASK]: undefined,
  [tk.K_WMTCANCELUPDATERXTICKETTASK]: undefined,
  [tk.K_WMTLANDINGGETC360ACCTSTASK]: undefined,
  [tk.K_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK]: undefined,
  [tk.K_WMTRESCHEDULEUPDATERXTICKETTASK]: undefined,
  [tk.K_WMTRESULTSGETC360POANDRXTITASK]: undefined,
};

export const data: SagaTasksState = {
  ...dataInit,
};

export const getters: GetterTree<SagaTasksState, any> = {
  [tk.GET_SAGATASKS_BLOCKINGVARS]: (state) => state.blockingVars,
  [tk.GET_SAGATASKS_GETAZUREATTRS_TASK]: (state) =>
    state?.[tk.K_GETAZUREATTRSTASK],
  [tk.GET_SAGATASKS_HANDLEAUTH_TASK]: (state) => state?.[tk.K_HANDLEAUTHTASK],
  [tk.GET_SAGATASKS_HANDLEPROGRESBAR_TASK]: (state) =>
    state?.[tk.K_HANDLEPROGRESSBARTASK],
  [tk.GET_SAGATASKS_HANDLEPROGRESSSPINNER_TASK]: (state) =>
    state?.[tk.K_HANDLEPROGRESSSPINNERTASK],
  [tk.GET_SAGATASKSMODULE_GETC360ACCTSTASK]: (state) => state.getC360AcctsTask,
  [tk.GET_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK]: (state) =>
    state.getC360PendingOrdersAndRXTicketInfosTask,
  [tk.GET_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK]: (state) =>
    state.getRXAppointmentInfosTask,
  [tk.GET_SAGATASKSMODULE_UPDATERXTICKETTASK]: (state) =>
    state.updateRXTicketTask,
  [tk.GET_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK]: (state) =>
    state.stsLandingGetC360AcctsTask,
  [tk.GET_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK]: (state) =>
    state.wmtCancelUpdateRXTicketTask,
  [tk.GET_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK]: (state) =>
    state.wmtLandingGetC360AcctsTask,
  [tk.GET_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK]: (state) =>
    state.wmtRescheduleGetRXAppointmentInfosTask,
  [tk.GET_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK]: (state) =>
    state.wmtRescheduleUpdateRXTicketTask,
  [tk.GET_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK]: (state) =>
    state.wmtResultsGetC360POAndRXTITask,
};

export const mutations: MutationTree<SagaTasksState> = {
  [tk.MUTATE_SAGATASKS_BLOCKINGVARS]: (state, { blockingVars }) => {
    state.blockingVars = {
      ...state.blockingVars,
      ...blockingVars,
    };
  },
  [tk.MUTATE_SAGATASKS_GETAZUREATTRS_TASK]: (state, { getAzureAttrsTask }) => {
    state[tk.K_GETAZUREATTRSTASK] = getAzureAttrsTask;
  },
  [tk.MUTATE_SAGATASKS_HANDLEAUTH_TASK]: (state, { handleAuthTask }) => {
    state[tk.K_HANDLEAUTHTASK] = handleAuthTask;
  },
  [tk.MUTATE_SAGATASKS_HANDLEPROGRESSBAR_TASK]: (
    state,
    { handleProgressBarTask }
  ) => {
    state[tk.K_HANDLEPROGRESSBARTASK] = handleProgressBarTask;
  },
  [tk.MUTATE_SAGATASKS_HANDLEPROGRESSSPINNER_TASK]: (
    state,
    { handleProgressSpinnerTask }
  ) => {
    state[tk.K_HANDLEPROGRESSSPINNERTASK] = handleProgressSpinnerTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_GETC360ACCTSTASK]: (
    state,
    { getC360AcctsTask }
  ) => {
    state.getC360AcctsTask = getC360AcctsTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK]: (
    state,
    { getC360PendingOrdersAndRXTicketInfosTask }
  ) => {
    state.getC360PendingOrdersAndRXTicketInfosTask =
      getC360PendingOrdersAndRXTicketInfosTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK]: (
    state,
    { getRXAppointmentInfosTask }
  ) => {
    state.getRXAppointmentInfosTask = getRXAppointmentInfosTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_UPDATERXTICKETTASK]: (
    state,
    { updateRXTicketTask }
  ) => {
    state.updateRXTicketTask = updateRXTicketTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK]: (
    state,
    { stsLandingGetC360AcctsTask }
  ) => {
    state.stsLandingGetC360AcctsTask = stsLandingGetC360AcctsTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK]: (
    state,
    { wmtCancelUpdateRXTicketTask }
  ) => {
    state.wmtCancelUpdateRXTicketTask = wmtCancelUpdateRXTicketTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK]: (
    state,
    { wmtLandingGetC360AcctsTask }
  ) => {
    state.wmtLandingGetC360AcctsTask = wmtLandingGetC360AcctsTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK]: (
    state,
    { wmtRescheduleGetRXAppointmentInfosTask }
  ) => {
    state.wmtRescheduleGetRXAppointmentInfosTask =
      wmtRescheduleGetRXAppointmentInfosTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK]: (
    state,
    { wmtRescheduleUpdateRXTicketTask }
  ) => {
    state.wmtRescheduleUpdateRXTicketTask = wmtRescheduleUpdateRXTicketTask;
  },
  [tk.MUTATE_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK]: (
    state,
    { wmtResultsGetC360POAndRXTITask }
  ) => {
    state.wmtResultsGetC360POAndRXTITask = wmtResultsGetC360POAndRXTITask;
  },
};

export const actions: ActionTree<SagaTasksState, any> = {};

const sagaTasksModule: Module<SagaTasksState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default sagaTasksModule;
