import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { WMTCancelState } from './types';
import * as treekeys from './treekeys';

const initState = {};

export const data: WMTCancelState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<WMTCancelState, any> = {
  [treekeys.GET_WMTCANCELMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_WMTCANCELMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<WMTCancelState> = {
  [treekeys.MUTATE_WMTCANCELMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_WMTCANCELMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<WMTCancelState, any> = {};
const wmtCancelModule: Module<WMTCancelState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default wmtCancelModule;
