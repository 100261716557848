/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { call } from '@redux-saga/core/effects';

class AbstractBusinessChainRequestHandler<TRequest, TResponse> {
  public *handleRequest(request: TRequest, response: TResponse): any {
    yield call(() => Promise.resolve());
    throw new Error('handleRequest not implemented');
  }

  public *shouldNotHandle(request: TRequest, response: TResponse): any {
    yield call(() => Promise.resolve());
    return false;
  }
}

export default AbstractBusinessChainRequestHandler;
