import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';

import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import OverlayPanel from 'primevue/overlaypanel';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Skeleton from 'primevue/skeleton';
import TabMenu from 'primevue/tabmenu';
import Timeline from 'primevue/timeline';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import InlineMessage from 'primevue/inlinemessage';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import {
  VUE_APP_GTM_ID,
  VUE_APP_GTM_AUTH,
  VUE_APP_GTM_ENV,
  VUE_APP_GTM_COOCKIES,
} from './common/envVars';
import store from './store';
import router from './router';
import App from './App.vue';

import 'primevue/resources/themes/md-light-indigo/theme.css'; // theme
import 'primevue/resources/primevue.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import 'primeflex/primeflex.css';

import './assets/css/dss.css';
import './assets/css/nucleus.css';
import 'add-to-calendar-button';

const gtmObj = createGtm({
  id: `GTM-${VUE_APP_GTM_ID}`,
  queryParams: {
    gtm_auth: VUE_APP_GTM_AUTH,
    gtm_preview: VUE_APP_GTM_ENV,
    gtm_cookies_win: VUE_APP_GTM_COOCKIES,
  },
  defer: false,
  compatibility: false,
  enabled: true,
  debug: false,
  loadScript: true,
  // vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

createApp(App)
  .use(router)
  .use(store)
  .use(ToastService)

  .component('PVAccordion', Accordion)
  .component('PVAccordionTab', AccordionTab)
  .component('PVButton', Button)
  .component('PVCalendar', Calendar)
  .component('PVCard', Card)
  .component('PVDivider', Divider)
  .component('PVDropdown', Dropdown)
  .component('PVInputText', InputText)
  .component('PVOverlayPanel', OverlayPanel)
  .component('PVMessage', Message)
  .component('PVProgressBar', ProgressBar)
  .component('PVRadioButton', RadioButton)
  .component('PVSkeleton', Skeleton)
  .component('PVTabMenu', TabMenu)
  .component('PVTimeline', Timeline)
  .component('PVProgressSpinner', ProgressSpinner)
  .component('PVDialog', Dialog)
  .component('PVToast', Toast)
  .component('PVInlineMessage', InlineMessage)
  .component('PVTabView', TabView)
  .component('PVTabPanel', TabPanel)
  .directive('pvtooltip', Tooltip)
  .use(PrimeVue, { ripple: true })
  .use(gtmObj)
  .mount('#app');
