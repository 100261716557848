/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  fork,
  put,
  takeLatest,
} from '@redux-saga/core/effects';
import * as tk from '../modules/progressspinner/treekeys';
import * as tkST from '../modules/sagaTasks/treekeys';

export function* initProgressSpinnerAppear(): any {
  yield put({
    type: tk.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
}

export function* finishProgress() {
  yield put({
    type: tk.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: false,
  });
}

export function* finishProgressNoBlock() {
  yield put({
    type: tk.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: false,
  });
}

export function* handleProgressSpinner(progressSpinnerConfig: any): any {
  yield fork(initProgressSpinnerAppear);
}

export function* cancelHandlerHandleProgressSpinner(
  progressSpinnerConfig: any
): any {
  try {
    yield call(handleProgressSpinner, progressSpinnerConfig);
  } finally {
    if (yield cancelled()) {
      yield* finishProgressNoBlock();
    }
  }
}

export function* taskHandleProgressSpinner(progressSpinnerConfig: any): any {
  const task = yield fork(
    cancelHandlerHandleProgressSpinner,
    progressSpinnerConfig
  );
  const taskObj: any = {
    type: tkST.NS_MUTATE_SAGATASKS_HANDLEPROGRESSSPINNER_TASK,
  };
  taskObj[tkST.K_HANDLEPROGRESSSPINNERTASK] = task;
  yield put(taskObj);
}

export function* watchHandleProgressSpinner(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, taskHandleProgressSpinner);
}

export default watchHandleProgressSpinner;
