/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from '@redux-saga/core/effects';
import * as tk from '@/store/modules/wmtLandingModule/treekeys';
import * as tkST from '@/store/modules/sagaTasks/treekeys';
import * as tkPS from '@/store/modules/progressspinner/treekeys';
import { takeLatestCancelRevert } from '@/store/sagas/utils/takeLatestCancelRevert';
import { getC360AcctsParallel } from '@/store/sagas/watchGetC360AcctsParallel';
import { REGEXP_EMAIL } from '@/common/strings';

export function* getC360AcctsSubTask(
  accountNumber: string,
  email: string,
  zipCode: string
): any {
  yield call(getC360AcctsParallel, {
    payload: accountNumber || email,
    zipCode,
  });
}

export function* throttle(): any {
  try {
    yield put({
      type: tk.NS_MUTATE_WMTLANDINGMODULE_THROTTLED,
      throttled: true,
    });
    yield delay(15000);
    yield put({
      type: tk.NS_MUTATE_WMTLANDINGMODULE_THROTTLED,
      throttled: false,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: tk.NS_MUTATE_WMTLANDINGMODULE_THROTTLED,
        throttled: false,
      });
    }
  }
}

export function* updateStateWithQuery(query: any): any {
  yield put({
    type: tk.NS_MUTATE_WMTLANDINGMODULE_ISORDERORREPAIRNUMBER,
    isOrderOrRepairNumber: query?.payload?.isOrderOrRepairNumber || false,
  });
  if (query?.payload?.selectedAccountNumber) {
    yield put({
      type: tk.NS_MUTATE_WMTLANDINGMODULE_SELECTEDACCOUNTNUMBER,
      selectedAccountNumber: query?.payload?.selectedAccountNumber,
    });
  }
}

export function* wmtLandingGetC360Accts(query: any): any {
  yield put({
    type: tk.NS_MUTATE_WMTLANDINGMODULE_SUBMITPENDING,
    submitPending: true,
  });
  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
  yield call(() => Promise.resolve());
  yield call(updateStateWithQuery, query);
  const throttleTask = yield fork(throttle);
  if (!query?.payload?.isOrderOrRepairNumber) {
    if (REGEXP_EMAIL.test(query?.payload?.selectedAccountNumber)) {
      yield call(
        getC360AcctsSubTask,
        '',
        query?.payload?.selectedAccountNumber,
        query?.payload?.zipCode
      );
    } else {
      yield call(
        getC360AcctsSubTask,
        query?.payload?.selectedAccountNumber,
        '',
        query?.payload?.zipCode
      );
    }
  }
  yield cancel(throttleTask);
  // updates state as we finish with original query in case user changed any vals during the saga
  yield call(updateStateWithQuery, query);
  yield put({
    type: tk.NS_MUTATE_WMTLANDINGMODULE_SUBMITPENDING,
    submitPending: false,
  });
  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: false,
  });
}

export function* stashIntoPrevState(): any {
  const currState = yield select((s) => s?.[tk.WMTLANDINGMODULE]?.currState);
  yield put({
    type: tk.NS_MUTATE_WMTLANDINGMODULE_PREVSTATE,
    prevState: {
      ...currState,
    },
  });
}

export function* popOutPrevState(): any {
  const prevState = yield select((s) => s?.[tk.WMTLANDINGMODULE]?.prevState);
  yield put({
    type: tk.NS_MUTATE_WMTLANDINGMODULE_CURRSTATE,
    currState: {
      ...prevState,
    },
  });
}

export function* cancelWMTLandingGetC360Accts(): any {
  const task = yield select(
    (s) => s?.[tkST.SAGATASKSMODULE]?.[tkST.K_WMTLANDINGGETC360ACCTSTASK]
  );
  if (task && task.isRunning()) {
    yield cancel(task);
  }
}

export function* watchCancelWMTLandingGetC360Accts(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, cancelWMTLandingGetC360Accts);
}

export function* watchWMTLandingGetC360Accts(
  pattern: string
): Generator<any, any, any> {
  yield fork(takeLatestCancelRevert, {
    stash: stashIntoPrevState,
    pop: popOutPrevState,
    task: wmtLandingGetC360Accts,
    taskPattern: pattern,
    taskName: tkST.K_WMTLANDINGGETC360ACCTSTASK,
    trackTaskAction: tkST.NS_MUTATE_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK,
  });
}

export default watchWMTLandingGetC360Accts;
