import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import updateRXTicketMockResp from '../../../assets/json/apiMockResp/updateRXTicket.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 1000;
const MOCK_TIME_BOUND_MAX = 3000;

interface UpdateRXTicketPayload {
  action: string;
  sourceSystem: string;
  ticketNumber: string;
  nativeTroubleTicketNumber: string;
  customerProductID: number;
  callBackNumber?: string;
  sessionId: number;
  selectedAppointmentId?: string;
  useRXWS?: boolean;
}

export function* updateRXTicket(
  updateRXTicketPayload: UpdateRXTicketPayload,
  isAuth: boolean
): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: updateRXTicketMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  const API_ENDPOINT = isAuth
    ? apiEndpts.AUTH_WMT_UPDATE_TICKET
    : apiEndpts.WMT_UPDATE_RX_TICKET;
  return yield call(
    axios.post,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}`,
    updateRXTicketPayload,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
