import {
  call,
  cancelled,
  put,
  select,
  takeLatest,
} from '@redux-saga/core/effects';
import * as tk from '../modules/get34SEcomUserProfileModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import { get34SEcomUserProfile as get34SEcomUserProfileApi } from './api/get34SEcomUserProfile';

export function* get34SEcomUserProfile(flowName: any): any {
  yield put({ type: tk.NS_MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING });
  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
  try {
    const resp = yield call(get34SEcomUserProfileApi, flowName?.payload);
    if (resp?.data) {
      yield put({
        type: tk.NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE,
        _34SEcomUserProfile: resp.data,
      });
    } else {
      yield put({
        type: tk.NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR,
        _34SEcomUserProfileError: resp,
      });
    }
  } catch (err) {
    yield put({
      type: tk.NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR,
      _34SEcomUserProfileError: err,
    });
  } finally {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: false,
    });
    if (yield cancelled()) {
      yield put({
        type: tk.NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR,
        _34SEcomUserProfileError: 'SAGA_CANCELLED',
      });
    }
  }
}

export function* watchGet34SEcomUserProfile(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, get34SEcomUserProfile);
}

export default watchGet34SEcomUserProfile;
