/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from '@redux-saga/core/effects';
import * as tk from '@/store/modules/stsLandingModule/treekeys';
import * as tkST from '@/store/modules/sagaTasks/treekeys';
import { takeLatestCancelRevert } from '@/store/sagas/utils/takeLatestCancelRevert';
import { getC360AcctsParallel } from '@/store/sagas/watchGetC360AcctsParallel';
import { REGEXP_EMAIL } from '@/common/strings';

export function* getC360AcctsSubTask(
  accountNumber: string,
  email: string,
  zipCode: string
): any {
  yield call(getC360AcctsParallel, {
    payload: accountNumber || email,
    zipCode,
  });
}

export function* throttle(): any {
  try {
    yield put({
      type: tk.NS_MUTATE_STSLANDINGMODULE_THROTTLED,
      throttled: true,
    });
    yield delay(15000);
    yield put({
      type: tk.NS_MUTATE_STSLANDINGMODULE_THROTTLED,
      throttled: false,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: tk.NS_MUTATE_STSLANDINGMODULE_THROTTLED,
        throttled: false,
      });
    }
  }
}

export function* updateStateWithQuery(query: any): any {
  yield put({
    type: tk.NS_MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER,
    selectedAccountNumber: query?.payload?.selectedAccountNumber,
  });
  yield put({
    type: tk.NS_MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE,
    selectedZipCode: query?.payload?.selectedZipCode,
  });
}

export function* stsLandingGetC360Accts(query: any): any {
  yield put({
    type: tk.NS_MUTATE_STSLANDINGMODULE_SUBMITPENDING,
    submitPending: true,
  });
  yield call(() => Promise.resolve());
  yield call(updateStateWithQuery, query);
  const throttleTask = yield fork(throttle);
  if (REGEXP_EMAIL.test(query?.payload?.selectedAccountNumber)) {
    yield call(
      getC360AcctsSubTask,
      '',
      query?.payload?.selectedAccountNumber,
      query?.payload?.selectedZipCode
    );
  } else {
    yield call(
      getC360AcctsSubTask,
      query?.payload?.selectedAccountNumber,
      '',
      query?.payload?.selectedZipCode
    );
  }
  yield cancel(throttleTask);
  // updates state as we finish with original query in case user changed any vals during the saga
  yield call(updateStateWithQuery, query);
  yield put({
    type: tk.NS_MUTATE_STSLANDINGMODULE_SUBMITPENDING,
    submitPending: false,
  });
}

export function* stashIntoPrevState(): any {
  const currState = yield select((s) => s?.[tk.STSLANDINGMODULE]?.currState);
  yield put({
    type: tk.NS_MUTATE_STSLANDINGMODULE_PREVSTATE,
    prevState: {
      ...currState,
    },
  });
}

export function* popOutPrevState(): any {
  const prevState = yield select((s) => s?.[tk.STSLANDINGMODULE]?.prevState);
  yield put({
    type: tk.NS_MUTATE_STSLANDINGMODULE_CURRSTATE,
    currState: {
      ...prevState,
    },
  });
}

export function* cancelSTSLandingGetC360Accts(): any {
  const task = yield select(
    (s) => s?.[tkST.SAGATASKSMODULE]?.[tkST.K_STSLANDINGGETC360ACCTSTASK]
  );
  if (task && task.isRunning()) {
    yield cancel(task);
  }
}

export function* watchCancelSTSLandingGetC360Accts(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, cancelSTSLandingGetC360Accts);
}

export function* watchSTSLandingGetC360Accts(
  pattern: string
): Generator<any, any, any> {
  yield fork(takeLatestCancelRevert, {
    stash: stashIntoPrevState,
    pop: popOutPrevState,
    task: stsLandingGetC360Accts,
    taskPattern: pattern,
    taskName: tkST.K_STSLANDINGGETC360ACCTSTASK,
    trackTaskAction: tkST.NS_MUTATE_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK,
  });
}

export default watchSTSLandingGetC360Accts;
