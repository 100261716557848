export const GET_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP =
  'GET_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP';
export const GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDTHROTTLE =
  'GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDTHROTTLE';
export const GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDSHIFTLRU';
export const MUTATE_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP =
  'MUTATE_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP';
export const MUTATE_GETC360PENDINGORDERSPARALLELMODULE_SHIFTLRU =
  'MUTATE_GETC360PENDINGORDERSPARALLELMODULE_SHIFTLRU';
export const MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING =
  'MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING';
export const MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS =
  'MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS';
export const MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR =
  'MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR';
export const ACTION_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP =
  'ACTION_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP';
export const ACTION_GETC360PENDINGORDERSPARALLELMODULE_PENDING =
  'ACTION_GETC360PENDINGORDERSPARALLELMODULE_PENDING';
export const ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS =
  'ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS';
export const ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR =
  'ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR';
export const GETC360PENDINGORDERSPARALLELMODULE =
  'getC360PendingOrdersParallelModule';
export const GETC360PENDINGORDERSPARALLELMODULE_MAXPARALLEL = 3;
export const GETC360PENDINGORDERSPARALLELMODULE_MAXENTRIES = 10;
export const NS_GET_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP = `${GETC360PENDINGORDERSPARALLELMODULE}/${GET_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP}`;
export const NS_GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDTHROTTLE = `${GETC360PENDINGORDERSPARALLELMODULE}/${GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDSHIFTLRU = `${GETC360PENDINGORDERSPARALLELMODULE}/${GET_GETC360PENDINGORDERSPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP = `${GETC360PENDINGORDERSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP}`;
export const NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_SHIFTLRU = `${GETC360PENDINGORDERSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING = `${GETC360PENDINGORDERSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING}`;
export const NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS = `${GETC360PENDINGORDERSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS}`;
export const NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR = `${GETC360PENDINGORDERSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR}`;
export const NS_ACTION_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP = `${GETC360PENDINGORDERSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSPARALLELMODULE_GETC360PENDINGORDERSPARALLELMAP}`;
export const NS_ACTION_GETC360PENDINGORDERSPARALLELMODULE_PENDING = `${GETC360PENDINGORDERSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSPARALLELMODULE_PENDING}`;
export const NS_ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS = `${GETC360PENDINGORDERSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS}`;
export const NS_ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR = `${GETC360PENDINGORDERSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR}`;
