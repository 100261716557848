/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, cancelled, put, takeEvery } from '@redux-saga/core/effects';
import * as tk from '../modules/trackerTechModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import { getAddressGeocodeInfo as getAddressGeocodeInfoApi } from './api/getAddressGeocodeInfo';

export function* getServiceAddressGeocodeInfo(
  getServiceAddressGeocodeInfoPayload: any
): any {
  const serviceAddress: string = getServiceAddressGeocodeInfoPayload?.payload;
  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
  yield put({
    type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR,
    errorMessage: '',
  });
  yield put({
    type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING,
  });
  try {
    const resp = yield call(getAddressGeocodeInfoApi, serviceAddress);
    if (resp?.results?.length >= 1) {
      const geocodeInfo = {
        lat: resp.results[0].geometry.location.lat(),
        lng: resp.results[0].geometry.location.lng(),
        formattedAddress: resp.results[0].formatted_address,
      };
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION,
        location: geocodeInfo,
      });
    } else {
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS,
      });
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR,
        errorMessage: 'geocode-error',
      });
    }
  } catch (err) {
    yield put({
      type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS,
    });
    yield put({
      type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR,
      errorMessage: err,
    });
  } finally {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: false,
    });
    if (yield cancelled()) {
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS,
      });
      yield put({
        type: tk.NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR,
        errorMessage: 'SAGA_CANCELLED',
      });
    }
  }
}

export function* watchGetServiceAddressGeocodeInfo(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getServiceAddressGeocodeInfo);
}

export default watchGetServiceAddressGeocodeInfo;
