/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { useVuelidateRulesSingleton } from '@/common/utils/vuelidateRulesSingleton';
import { GetC360AcctsParallelMap } from '@/store/modules/getC360AcctsParallelModule/types';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGCAP from '@/store/modules/getC360AcctsParallelModule/treekeys';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';

class QueryParamsSTSSNPHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getActionBasedOnRulesC360Accts = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNumOrEmail: string
  ): HandleQueryParamsAction => {
    const vuelidateRules = useVuelidateRulesSingleton();
    if (
      vuelidateRules.isAcctSuspendedError(
        getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList,
        acctNumOrEmail
      )
    ) {
      return HandleQueryParamsAction.GOTO_STSSUSPNONPAY;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const getC360AcctsParallelMap: GetC360AcctsParallelMap = yield select(
      (s) => s?.[tkGCAP.GETC360ACCTSPARALLELMODULE]?.getC360AcctsParallelMap
    );
    response.handleQueryParamsAction = this.getActionBasedOnRulesC360Accts(
      getC360AcctsParallelMap,
      acctNumOrEmail.replaceAll('*', '')
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSSNPHandler;

export const useQueryParamsSTSSNPHandler = (): QueryParamsSTSSNPHandler => {
  if (!qp) {
    qp = new QueryParamsSTSSNPHandler();
  }
  return qp;
};
