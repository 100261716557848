import { call } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

export function* sendUILog(uiLogRequest: any): any {
  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();
  return yield call(
    axios.post,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.SEND_UI_LOG}`,
    uiLogRequest,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
