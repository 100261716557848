/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { UILogRequestState } from './types';
import * as treekeys from './treekeys';

export const data: UILogRequestState = {
  uiLogRequest: {
    level: -1,
    accountNumber: '',
    orderOrTicketNumber: '',
    emailAddress: '',
    username: '',
    page: '',
    message: '',
    action: '',
    userAgent: '',
  },
};
export const getters: GetterTree<UILogRequestState, any> = {
  [treekeys.GET_SENDUILOGMODULE_LOGINFO]: (state) => state.uiLogRequest,
};
export const mutations: MutationTree<UILogRequestState> = {
  [treekeys.MUTATE_SENDUILOGMODULE_RESPONSEERROR]: (
    state,
    { uiLogRequest }
  ) => {
    state.uiLogRequest = uiLogRequest;
  },
};
export const actions: ActionTree<UILogRequestState, any> = {
  [treekeys.ACTION_SENDUILOGMODULE_RESPONSEERROR]: (
    { commit },
    { uiLogRequest }
  ) => {
    commit(treekeys.MUTATE_SENDUILOGMODULE_RESPONSEERROR, {
      uiLogRequest,
    });
  },
};

const sendUILogModule: Module<UILogRequestState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default sendUILogModule;
