export const GET_WMTRESULTSMODULE_SHOWUPDATETICKETMSG =
  'GET_WMTRESULTSMODULE_SHOWUPDATETICKETMSG';
export const GET_WMTRESULTSMODULE_CURRSTATE = 'GET_WMTRESULTSMODULE_CURRSTATE';
export const GET_WMTRESULTSMODULE_PREVSTATE = 'GET_WMTRESULTSMODULE_PREVSTATE';
export const GET_WMTRESULTSMODULE_KEYRELOAD = 'GET_WMTRESULTSMODULE_KEYRELOAD';
export const GET_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT =
  'GET_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT';
export const GET_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING =
  'GET_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING';

export const MUTATE_WMTRESULTSMODULE_SHOWUPDATETICKETMSG =
  'MUTATE_WMTRESULTSMODULE_SHOWUPDATETICKETMSG';
export const MUTATE_WMTRESULTSMODULE_CURRSTATE =
  'MUTATE_WMTRESULTSMODULE_CURRSTATE';
export const MUTATE_WMTRESULTSMODULE_PREVSTATE =
  'MUTATE_WMTRESULTSMODULE_PREVSTATE';
export const MUTATE_WMTRESULTSMODULE_KEYRELOAD =
  'MUTATE_WMTRESULTSMODULE_KEYRELOAD';
export const MUTATE_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT =
  'MUTATE_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT';
export const MUTATE_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING =
  'MUTATE_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING';

export const ACTION_WMTRESULTSMODULE_SHOWUPDATETICKETMSG =
  'ACTION_WMTRESULTSMODULE_SHOWUPDATETICKETMSG';
export const ACTION_WMTRESULTSMODULE_KEYRELOAD =
  'ACTION_WMTRESULTSMODULE_KEYRELOAD';
export const ACTION_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT =
  'ACTION_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT';
export const ACTION_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING =
  'ACTION_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING';

export const WMTRESULTSMODULE = 'wmtResultsModule';
export const NS_GET_WMTRESULTSMODULE_SHOWUPDATETICKETMSG = `${WMTRESULTSMODULE}/${GET_WMTRESULTSMODULE_SHOWUPDATETICKETMSG}`;
export const NS_GET_WMTRESULTSMODULE_CURRSTATE = `${WMTRESULTSMODULE}/${GET_WMTRESULTSMODULE_CURRSTATE}`;
export const NS_GET_WMTRESULTSMODULE_PREVSTATE = `${WMTRESULTSMODULE}/${GET_WMTRESULTSMODULE_PREVSTATE}`;
export const NS_GET_WMTRESULTSMODULE_KEYRELOAD = `${WMTRESULTSMODULE}/${GET_WMTRESULTSMODULE_KEYRELOAD}`;
export const NS_GET_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT = `${WMTRESULTSMODULE}/${GET_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT}`;
export const NS_GET_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING = `${WMTRESULTSMODULE}/${GET_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING}`;

export const NS_MUTATE_WMTRESULTSMODULE_SHOWUPDATETICKETMSG = `${WMTRESULTSMODULE}/${MUTATE_WMTRESULTSMODULE_SHOWUPDATETICKETMSG}`;
export const NS_MUTATE_WMTRESULTSMODULE_CURRSTATE = `${WMTRESULTSMODULE}/${MUTATE_WMTRESULTSMODULE_CURRSTATE}`;
export const NS_MUTATE_WMTRESULTSMODULE_PREVSTATE = `${WMTRESULTSMODULE}/${MUTATE_WMTRESULTSMODULE_PREVSTATE}`;
export const NS_MUTATE_WMTRESULTSMODULE_KEYRELOAD = `${WMTRESULTSMODULE}/${MUTATE_WMTRESULTSMODULE_KEYRELOAD}`;
export const NS_MUTATE_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT = `${WMTRESULTSMODULE}/${MUTATE_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT}`;
export const NS_MUTATE_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING = `${WMTRESULTSMODULE}/${MUTATE_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING}`;

export const NS_ACTION_WMTRESULTSMODULE_SHOWUPDATETICKETMSG = `${WMTRESULTSMODULE}/${ACTION_WMTRESULTSMODULE_SHOWUPDATETICKETMSG}`;
export const NS_ACTION_WMTRESULTSMODULE_KEYRELOAD = `${WMTRESULTSMODULE}/${ACTION_WMTRESULTSMODULE_KEYRELOAD}`;
export const NS_ACTION_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT = `${WMTRESULTSMODULE}/${ACTION_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT}`;
export const NS_ACTION_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING = `${WMTRESULTSMODULE}/${ACTION_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING}`;
