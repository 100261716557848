export const GET_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR =
  'GET_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR';
export const MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR =
  'MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR';
export const ACTION_PROGRESSSPINNERMODULEMODULE_PROGRESSSPINNERAPPEAR =
  'ACTION_PROGRESSSPINNERMODULEMODULE_PROGRESSSPINNERAPPEAR';

export const PROGRESSSPINNERMODULE = 'progressSpinnerModule';
export const NS_GET_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR = `${PROGRESSSPINNERMODULE}/${GET_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR}`;
export const NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR = `${PROGRESSSPINNERMODULE}/${MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR}`;
export const NS_ACTION_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR = `${PROGRESSSPINNERMODULE}/${ACTION_PROGRESSSPINNERMODULEMODULE_PROGRESSSPINNERAPPEAR}`;
