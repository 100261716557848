export const GET_STSSELECTWTNMODULE_SELECTEDWTN =
  'GET_STSSELECTWTNMODULE_SELECTEDWTN';
export const GET_STSSELECTWTNMODULE_CURRSTATE =
  'GET_STSSELECTWTNMODULE_CURRSTATE';
export const GET_STSSELECTWTNMODULE_PREVSTATE =
  'GET_STSSELECTWTNMODULE_PREVSTATE';
export const GET_STSSELECTWTNMODULE_ROUTEPARAMS =
  'GET_STSSELECTWTNMODULE_ROUTEPARAMS';
export const MUTATE_STSSELECTWTNMODULE_SELECTEDWTN =
  'MUTATE_STSSELECTWTNMODULE_SELECTEDWTN';
export const MUTATE_STSSELECTWTNMODULE_CURRSTATE =
  'MUTATE_STSSELECTWTNMODULE_CURRSTATE';
export const MUTATE_STSSELECTWTNMODULE_PREVSTATE =
  'MUTATE_STSSELECTWTNMODULE_PREVSTATE';
export const MUTATE_STSSELECTWTNMODULE_ROUTEPARAMS =
  'MUTATE_STSSELECTWTNMODULE_ROUTEPARAMS';
export const ACTION_STSSELECTWTNMODULE_SELECTEDWTN =
  'ACTION_STSSELECTWTNMODULE_SELECTEDWTN';
export const ACTION_STSSELECTWTNMODULE_ROUTEPARAMS =
  'ACTION_STSSELECTWTNMODULE_ROUTEPARAMS';
export const STSSELECTWTNMODULE = 'stsSelectWTNModule';
export const NS_GET_STSSELECTWTNMODULE_SELECTEDWTN = `${STSSELECTWTNMODULE}/${GET_STSSELECTWTNMODULE_SELECTEDWTN}`;
export const NS_GET_STSSELECTWTNMODULE_CURRSTATE = `${STSSELECTWTNMODULE}/${GET_STSSELECTWTNMODULE_CURRSTATE}`;
export const NS_GET_STSSELECTWTNMODULE_PREVSTATE = `${STSSELECTWTNMODULE}/${GET_STSSELECTWTNMODULE_PREVSTATE}`;
export const NS_GET_STSSELECTWTNMODULE_ROUTEPARAMS = `${STSSELECTWTNMODULE}/${GET_STSSELECTWTNMODULE_ROUTEPARAMS}`;
export const NS_MUTATE_STSSELECTWTNMODULE_SELECTEDWTN = `${STSSELECTWTNMODULE}/${MUTATE_STSSELECTWTNMODULE_SELECTEDWTN}`;
export const NS_MUTATE_STSSELECTWTNMODULE_CURRSTATE = `${STSSELECTWTNMODULE}/${MUTATE_STSSELECTWTNMODULE_CURRSTATE}`;
export const NS_MUTATE_STSSELECTWTNMODULE_PREVSTATE = `${STSSELECTWTNMODULE}/${MUTATE_STSSELECTWTNMODULE_PREVSTATE}`;
export const NS_MUTATE_STSSELECTWTNMODULE_ROUTEPARAMS = `${STSSELECTWTNMODULE}/${MUTATE_STSSELECTWTNMODULE_ROUTEPARAMS}`;
export const NS_ACTION_STSSELECTWTNMODULE_SELECTEDWTN = `${STSSELECTWTNMODULE}/${ACTION_STSSELECTWTNMODULE_SELECTEDWTN}`;
export const NS_ACTION_STSSELECTWTNMODULE_ROUTEPARAMS = `${STSSELECTWTNMODULE}/${ACTION_STSSELECTWTNMODULE_ROUTEPARAMS}`;
