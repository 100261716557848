/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { GetNokiaSSCTokenParallelState, GetNokiaSSCTokenState } from './types';
import * as treekeys from './treekeys';

export const data: GetNokiaSSCTokenParallelState = {
  getNokiaSSCTokenParallelMap: {},
  shouldThrottle: false,
  lruKeyArr: [],
  shouldShiftLRU: false,
};
export const getters: GetterTree<GetNokiaSSCTokenParallelState, any> = {
  [treekeys.GET_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP]: (
    state
  ) => state.getNokiaSSCTokenParallelMap,
  [treekeys.GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDTHROTTLE]: (state) =>
    state.shouldThrottle,
  [treekeys.GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDSHIFTLRU]: (state) =>
    state.shouldShiftLRU,
};
export const mutations: MutationTree<GetNokiaSSCTokenParallelState> = {
  [treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP]:
    (state, { getNokiaSSCTokenParallelMap }) => {
      state.getNokiaSSCTokenParallelMap = getNokiaSSCTokenParallelMap;
    },
  [treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_SHIFTLRU]: (state) => {
    delete state.getNokiaSSCTokenParallelMap[state.lruKeyArr.shift() || ''];
    state.shouldThrottle =
      Object.values(state.getNokiaSSCTokenParallelMap).filter(
        (getNokiaSSCTokenState: GetNokiaSSCTokenState) =>
          getNokiaSSCTokenState.pending
      ).length >= treekeys.GETNOKIASSCTOKENPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING]: (
    state,
    { key }
  ) => {
    state.getNokiaSSCTokenParallelMap[key] ? 0 : state.lruKeyArr.push(key);
    state.shouldShiftLRU =
      state.lruKeyArr.length >
      treekeys.GETNOKIASSCTOKENPARALLELMODULE_MAXENTRIES;
    state.getNokiaSSCTokenParallelMap[key] =
      state.getNokiaSSCTokenParallelMap[key] || {};
    state.getNokiaSSCTokenParallelMap[key].pending = true;
    state.shouldThrottle =
      Object.values(state.getNokiaSSCTokenParallelMap).filter(
        (getNokiaSSCTokenState: GetNokiaSSCTokenState) =>
          getNokiaSSCTokenState.pending
      ).length >= treekeys.GETNOKIASSCTOKENPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN]: (
    state,
    { key, token }
  ) => {
    state.getNokiaSSCTokenParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getNokiaSSCTokenParallelMap).filter(
        (getNokiaSSCTokenState: GetNokiaSSCTokenState) =>
          getNokiaSSCTokenState.pending
      ).length >= treekeys.GETNOKIASSCTOKENPARALLELMODULE_MAXPARALLEL;
    state.getNokiaSSCTokenParallelMap[key].token = token;
    state.getNokiaSSCTokenParallelMap[key].tokenError = undefined;
  },
  [treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR]: (
    state,
    { key, tokenError }
  ) => {
    state.getNokiaSSCTokenParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getNokiaSSCTokenParallelMap).filter(
        (getNokiaSSCTokenState: GetNokiaSSCTokenState) =>
          getNokiaSSCTokenState.pending
      ).length >= treekeys.GETNOKIASSCTOKENPARALLELMODULE_MAXPARALLEL;
    state.getNokiaSSCTokenParallelMap[key].token = '';
    state.getNokiaSSCTokenParallelMap[key].tokenError = tokenError;
  },
};
export const actions: ActionTree<GetNokiaSSCTokenParallelState, any> = {
  [treekeys.ACTION_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP]:
    ({ commit }, { getNokiaSSCTokenParallelMap }) => {
      commit(
        treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP,
        {
          getNokiaSSCTokenParallelMap,
        }
      );
    },
  [treekeys.ACTION_GETNOKIASSCTOKENPARALLELMODULE_PENDING]: (
    { commit },
    { key }
  ) => {
    commit(treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING, {
      key,
    });
  },
  [treekeys.ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKEN]: (
    { commit },
    { key, token }
  ) => {
    commit(treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN, {
      key,
      token,
    });
  },
  [treekeys.ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR]: (
    { commit },
    { key, tokenError }
  ) => {
    commit(treekeys.MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR, {
      key,
      tokenError,
    });
  },
};

const getNokiaSSCTokenParallelModule: Module<
  GetNokiaSSCTokenParallelState,
  any
> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default getNokiaSSCTokenParallelModule;
