import AbstractBusinessChain from '../abstractBusinessChain';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { useQueryParamsWMTLHandler } from './queryParamsWMTLHandler';
import { useQueryParamsWMTSAHandler } from './queryParamsWMTSAHandler';
import { useQueryParamsWMTRHandler } from './queryParamsWMTRHandler';
import { useQueryParamsWMTUTHandler } from './queryParamsWMTUTHandler';

class QueryParamsWMTUTBusinessChain extends AbstractBusinessChain<
  QueryParamsRequest,
  QueryParamsResponse
> {
  setupChain() {
    this.chainHandlers.push(useQueryParamsWMTLHandler());
    this.chainHandlers.push(useQueryParamsWMTSAHandler());
    this.chainHandlers.push(useQueryParamsWMTRHandler());
    this.chainHandlers.push(useQueryParamsWMTUTHandler());
  }
}

let qp: QueryParamsWMTUTBusinessChain;

export const useQueryParamsWMTUTBusinessChain =
  (): QueryParamsWMTUTBusinessChain => {
    if (!qp) {
      qp = new QueryParamsWMTUTBusinessChain();
    }
    return qp;
  };
