class ErrorUtilsSingleton {
  isNetworkError = (obj: any) =>
    obj?.toString()?.includes('Error: Network Error') ||
    obj?.toString()?.includes('Error: timeout') ||
    obj?.toString()?.includes('Error: Request failed with status code');

  getTrackerTechStatusAxiosErrorMsg = (catchErr: any) => {
    let errorMessage = 'An error occured while processing the request.';
    if (
      catchErr?.name?.includes('AxiosError') ||
      catchErr?.response?.status === 401
    ) {
      errorMessage =
        'The credentials you signed in with do not match the account that you are trying to view details for.';
    }
    return errorMessage;
  };

  isRxProductInfoError = (obj: any) =>
    obj?.data &&
    obj.data?.rxSessionIdentifier === null &&
    obj.data?.accountInformation === null &&
    obj.data?.serviceLines === null &&
    obj.data?.apiResponse === null;
}

let errorUtilsSingleton: ErrorUtilsSingleton;

export const useErrorUtilsSingleton = (): ErrorUtilsSingleton => {
  if (!errorUtilsSingleton) {
    errorUtilsSingleton = new ErrorUtilsSingleton();
  }
  return errorUtilsSingleton;
};
