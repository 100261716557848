export const GET_TRACKERTECHMODULE_TRACKERTECH =
  'GET_TRACKERTECHMODULE_TRACKERTECH';
export const GET_TRACKERTECHMODULE_TRACKERTECHERROR =
  'GET_TRACKERTECHMODULE_TRACKERTECHERROR';
export const GET_TRACKERTECHMODULE_TRACKERTECHPENDING =
  'GET_TRACKERTECHMODULE_TRACKERTECHPENDING';
export const GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESS =
  'GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESS';
export const GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING =
  'GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING';
export const GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION =
  'GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION';
export const GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR =
  'GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR';
export const GET_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION =
  'GET_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION';

export const MUTATE_TRACKERTECHMODULE_TRACKERTECH =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECH';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECHPENDING =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECHPENDING';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR';
export const MUTATE_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION =
  'MUTATE_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION';

export const ACTION_TRACKERTECHMODULE_TRACKERTECH =
  'ACTION_TRACKERTECHMODULE_TRACKERTECH';
export const ACTION_TRACKERTECHMODULE_TRACKERTECHERROR =
  'ACTION_TRACKERTECHMODULE_TRACKERTECHERROR';

export const TRACKERTECHMODULE = 'trackerTechModule';
export const NS_GET_TRACKERTECHMODULE_TRACKERTECH = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECH}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECHPENDING = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECHPENDING}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECHERROR = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECHERROR}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESS = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESS}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR}`;
export const NS_GET_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION = `${TRACKERTECHMODULE}/${GET_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECH}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHPENDING = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECHPENDING}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS}`;
export const NS_MUTATE_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION = `${TRACKERTECHMODULE}/${MUTATE_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION}`;
export const NS_ACTION_TRACKERTECHMODULE_TRACKERTECH = `${TRACKERTECHMODULE}/${ACTION_TRACKERTECHMODULE_TRACKERTECH}`;
export const NS_ACTION_TRACKERTECHMODULE_TRACKERTECHERROR = `${TRACKERTECHMODULE}/${ACTION_TRACKERTECHMODULE_TRACKERTECHERROR}`;
