import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { STSSelectWTNIterState, STSSelectWTNState } from './types';
import * as treekeys from './treekeys';

const initState: STSSelectWTNIterState = {
  selectedWTN: '',
  routeParams: {},
};

export const data: STSSelectWTNState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<STSSelectWTNState, any> = {
  [treekeys.GET_STSSELECTWTNMODULE_SELECTEDWTN]: (state) =>
    state.currState.selectedWTN,
  [treekeys.GET_STSSELECTWTNMODULE_ROUTEPARAMS]: (state) =>
    state.currState.routeParams,
  [treekeys.GET_STSSELECTWTNMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_STSSELECTWTNMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<STSSelectWTNState> = {
  [treekeys.MUTATE_STSSELECTWTNMODULE_SELECTEDWTN]: (
    state,
    { selectedWTN }
  ) => {
    state.currState.selectedWTN = selectedWTN;
  },
  [treekeys.MUTATE_STSSELECTWTNMODULE_ROUTEPARAMS]: (
    state,
    { routeParams }
  ) => {
    state.currState.routeParams = routeParams;
  },
  [treekeys.MUTATE_STSSELECTWTNMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_STSSELECTWTNMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<STSSelectWTNState, any> = {
  [treekeys.ACTION_STSSELECTWTNMODULE_SELECTEDWTN]: (
    { commit },
    { selectedWTN }
  ) => {
    commit(treekeys.MUTATE_STSSELECTWTNMODULE_SELECTEDWTN, {
      selectedWTN,
    });
  },
  [treekeys.ACTION_STSSELECTWTNMODULE_ROUTEPARAMS]: (
    { commit },
    { routeParams }
  ) => {
    commit(treekeys.MUTATE_STSSELECTWTNMODULE_ROUTEPARAMS, {
      routeParams,
    });
  },
};

const stsSelectWTNModule: Module<STSSelectWTNState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default stsSelectWTNModule;
