import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import {
  ROUTERNAME_WMTLANDING,
  ROUTERNAME_WMTSELECTACCT,
  ROUTERNAME_WMTRESULTS,
  ROUTERNAME_WMTRESCHEDULE,
  ROUTERNAME_WMTCANCEL,
  ROUTERNAME_STSLANDING,
  ROUTERNAME_STSSUSPNONPAY,
  ROUTERNAME_STSPENDINGORDER,
  ROUTERNAME_STSSELECTACCT,
  ROUTERNAME_STSNOPRODUCTS,
  ROUTERNAME_STSSUSPVAC,
  ROUTERNAME_STSSELECTWTN,
  ROUTERNAME_STSIFRAME,
} from '../strings';

class QueryParamsUtilsSingleton {
  handleWMTRouterReplace = (
    router: Router,
    route: RouteLocationNormalizedLoaded,
    handleQueryParamsAction: HandleQueryParamsAction
  ) => {
    switch (handleQueryParamsAction) {
      case HandleQueryParamsAction.GOTO_WMTLANDING: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        router.replace({
          name: ROUTERNAME_WMTLANDING,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_WMTSELECTACCT: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        router.replace({
          name: ROUTERNAME_WMTSELECTACCT,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_WMTRESULTS: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        router.replace({
          name: ROUTERNAME_WMTRESULTS,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_WMTUPDATETICKET: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        router.replace({
          name: route?.name?.toString() || ROUTERNAME_WMTRESULTS,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_WMTUPDATETICKETNOTCANCEL: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        let routeName = route?.name?.toString();
        if (routeName === ROUTERNAME_WMTCANCEL) {
          routeName = ROUTERNAME_WMTRESULTS;
        }
        router.replace({
          name: routeName || ROUTERNAME_WMTRESULTS,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_WMTUPDATETICKETNOTRESCHEDULE: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        let routeName = route?.name?.toString();
        if (routeName === ROUTERNAME_WMTRESCHEDULE) {
          routeName = ROUTERNAME_WMTRESULTS;
        }
        router.replace({
          name: routeName || ROUTERNAME_WMTRESULTS,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_WMTUPDATETICKETNOTCANCELNOTRESCHEDULE: {
        const qp = route?.query ? { ...route.query } : {};
        delete qp.ticketNumber;
        let routeName = route?.name?.toString();
        if (
          routeName === ROUTERNAME_WMTRESCHEDULE ||
          routeName === ROUTERNAME_WMTCANCEL
        ) {
          routeName = ROUTERNAME_WMTRESULTS;
        }
        router.replace({
          name: routeName || ROUTERNAME_WMTRESULTS,
          query: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.PENDING:
      default:
    }
  };

  handleSTSRouterReplace = (
    router: Router,
    route: any,
    handleQueryParamsAction: HandleQueryParamsAction
  ) => {
    switch (handleQueryParamsAction) {
      case HandleQueryParamsAction.GOTO_STSLANDING: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSLANDING,
          params: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSSELECTACCT: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSSELECTACCT,
          params: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSSUSPNONPAY: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSSUSPNONPAY,
          params: {
            ...qp,
          },
          query: {
            accountNumber: qp.accountNumber,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSPENDINGORDER: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSPENDINGORDER,
          params: {
            ...qp,
          },
          query: {
            accountNumber: qp.accountNumber,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSSUSPVAC: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSSUSPVAC,
          params: {
            ...qp,
          },
          query: {
            accountNumber: qp.accountNumber,
            wtn: qp.wtn,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSNOPRODUCTS: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSNOPRODUCTS,
          params: {
            ...qp,
          },
          query: {
            accountNumber: qp.accountNumber,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSSELECTWTN: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSSELECTWTN,
          params: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.GOTO_STSIFRAME: {
        const qp = route?.params ? { ...route.params } : {};
        router.replace({
          name: ROUTERNAME_STSIFRAME,
          params: {
            ...qp,
          },
        });
        break;
      }
      case HandleQueryParamsAction.PENDING:
      default:
    }
  };

  getComputedKeyFromFullPath = (fullPath: string) => {
    const localStoredZipcode = localStorage.getItem('wmtZipcode');
    if (
      fullPath?.includes('orderNumberOrTicketNumber=') &&
      localStoredZipcode
    ) {
      return `${fullPath}&wmtZipCode=${localStoredZipcode}`;
    }
    return fullPath;
  };
}

let queryParamsUtilsSingleton: QueryParamsUtilsSingleton;

export const useQueryParamsUtilsSingleton = (): QueryParamsUtilsSingleton => {
  if (!queryParamsUtilsSingleton) {
    queryParamsUtilsSingleton = new QueryParamsUtilsSingleton();
  }
  return queryParamsUtilsSingleton;
};
