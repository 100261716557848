import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import getRXProductInfoMockResp from '../../../assets/json/apiMockResp/getRXProductInfo.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 1000;
const MOCK_TIME_BOUND_MAX = 10000;

export function* getRXProductInfo(accountNumber: string, isAuth: boolean): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: getRXProductInfoMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  const API_ENDPOINT = isAuth
    ? apiEndpts.AUTH_STS_GET_PRODUCTINFO
    : apiEndpts.STS_GET_RX_PRODUCTINFO;
  return yield call(
    axios.get,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?accountNumber=${accountNumber}`,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
