/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/getRXProductInfoParallelModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import { getRXProductInfo as getRXProductInfoApi } from './api/getRXProductInfo';
import { GetRXProductInfoParallelMap } from '../modules/getRXProductInfoParallelModule/types';

const shouldCall = (grppMap: GetRXProductInfoParallelMap, acctNum: string) =>
  acctNum &&
  grppMap &&
  !grppMap[acctNum]?.pending &&
  (!grppMap[acctNum]?.rxProductInfo ||
    !Object.keys(grppMap[acctNum]?.rxProductInfo).length);

export function* getRXProductInfoParallel(getRXProductInfoPayload: any): any {
  const acctNum: string = getRXProductInfoPayload?.payload?.replaceAll('*', '');
  const grppMap: GetRXProductInfoParallelMap = yield select(
    (s) => s?.[tk.GETRXPRODUCTINFOPARALLELMODULE]?.getRXProductInfoParallelMap
  );
  const shouldThrottle: boolean = yield select(
    (s) => s?.[tk.GETRXPRODUCTINFOPARALLELMODULE]?.shouldThrottle
  );
  if (shouldCall(grppMap, acctNum) && !shouldThrottle) {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: true,
    });
    yield put({
      type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING,
      key: acctNum,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) => s?.[tk.GETRXPRODUCTINFOPARALLELMODULE]?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }

      const resp = yield call(getRXProductInfoApi, acctNum, isAuth);
      if (resp?.data?.rxSessionIdentifier) {
        // if (resp?.data?.rxSessionIdentifier) {
        yield put({
          type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO,
          rxProductInfo: resp.data,
          key: acctNum,
        });
        // } else {
        //   yield put({
        //     type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR,
        //     rxProductInfoError: 'Something went wrong.',
        //     key: acctNum,
        //   });
        // }
      } else {
        yield put({
          type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR,
          rxProductInfoError: resp,
          key: acctNum,
        });
      }
    } catch (err) {
      if (grppMap[acctNum]) {
        yield put({
          type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR,
          rxProductInfoError: err,
          key: acctNum,
        });
      }
    } finally {
      yield put({
        type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
        progressSpinnerAppear: false,
      });
      if (yield cancelled()) {
        if (grppMap[acctNum]) {
          yield put({
            type: tk.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR,
            rxProductInfoError: 'SAGA_CANCELLED',
            key: acctNum,
          });
        }
      }
    }
  }
}

export function* watchGetRXProductInfoParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getRXProductInfoParallel);
}

export default watchGetRXProductInfoParallel;
