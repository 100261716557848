import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { ProgressBarState } from './types';
import * as treekeys from './treekeys';

export const data: ProgressBarState = {
  progressBarAppear: false,
  progressValue: 0.0,
  longerThanUsual: false,
  segments: 1,
  runningSegments: [],
  timeout: 60000,
  visualFinished: true,
};

export const getters: GetterTree<ProgressBarState, any> = {
  [treekeys.GET_PROGRESSBAR_PROGRESSBARAPPEAR]: (state) =>
    state.progressBarAppear,
  [treekeys.GET_PROGRESSBAR_PROGRESSVALUE]: (state) => state.progressValue,
  [treekeys.GET_PROGRESSBAR_LONGERTHANUSUAL]: (state) => state.longerThanUsual,
  [treekeys.GET_PROGRESSBAR_SEGMENTS]: (state) => state.segments,
  [treekeys.GET_PROGRESSBAR_RUNNINGSEGMENTS]: (state) => state.runningSegments,
  [treekeys.GET_PROGRESSBAR_TIMEOUT]: (state) => state.timeout,
  [treekeys.GET_PROGRESSBAR_VISUALFINISHED]: (state) => state.visualFinished,
};

export const mutations: MutationTree<ProgressBarState> = {
  [treekeys.MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR]: (
    state,
    { progressBarAppear }
  ) => {
    state.progressBarAppear = progressBarAppear;
  },
  [treekeys.MUTATE_PROGRESSBAR_PROGRESSVALUE]: (state, { progressValue }) => {
    state.progressValue = progressValue;
  },
  [treekeys.MUTATE_PROGRESSBAR_LONGERTHANUSUAL]: (
    state,
    { longerThanUsual }
  ) => {
    state.longerThanUsual = longerThanUsual;
  },
  [treekeys.MUTATE_PROGRESSBAR_SEGMENTS]: (state, { segments }) => {
    state.segments = segments;
  },
  [treekeys.MUTATE_PROGRESSBAR_RUNNINGSEGMENTS]: (
    state,
    { runningSegments }
  ) => {
    state.runningSegments = runningSegments;
  },
  [treekeys.MUTATE_PROGRESSBAR_TIMEOUT]: (state, { timeout }) => {
    state.timeout = timeout;
  },
  [treekeys.MUTATE_PROGRESSBAR_VISUALFINISHED]: (state, { visualFinished }) => {
    state.visualFinished = visualFinished;
  },
};

export const actions: ActionTree<ProgressBarState, any> = {};

const progressBarModule: Module<ProgressBarState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default progressBarModule;
