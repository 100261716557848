export const GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP =
  'GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP';
export const GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDTHROTTLE =
  'GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDTHROTTLE';
export const GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDSHIFTLRU';
export const MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP =
  'MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP';
export const MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHIFTLRU =
  'MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHIFTLRU';
export const MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING =
  'MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING';
export const MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS =
  'MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS';
export const MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR =
  'MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR';
export const ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP =
  'ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP';
export const ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING =
  'ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING';
export const ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS =
  'ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS';
export const ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR =
  'ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR';
export const GETRXAPPOINTMENTINFOSPARALLELMODULE =
  'getRXAppointmentInfosParallelModule';
export const GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXPARALLEL = 3;
export const GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXENTRIES = 10;
export const NS_GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP}`;
export const NS_GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDTHROTTLE = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDSHIFTLRU = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP}`;
export const NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHIFTLRU = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING}`;
export const NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS}`;
export const NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR}`;
export const NS_ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP}`;
export const NS_ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING}`;
export const NS_ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS}`;
export const NS_ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR = `${GETRXAPPOINTMENTINFOSPARALLELMODULE}/${ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR}`;
