const getEnvVar = (envVar: string) =>
  process.env[`VUE_${envVar}`] || process.env[`VITE_${envVar}`];

export const VUE_APP_CUSTCARE_TITLE = getEnvVar('APP_CUSTCARE_TITLE');
export const VUE_APP_CUSTCARE_SERVICE_HOST =
  getEnvVar('APP_CUSTCARE_SERVICE_HOST') || 'http://localhost:8001';
export const VUE_APP_CUSTCARE_UI_HOST =
  getEnvVar('APP_CUSTCARE_UI_HOST') || 'http://localhost:3000';
export const VUE_APP_CUSTCARE_LOGOUT_URL = getEnvVar('APP_CUSTCARE_LOGOUT_URL');
export const VUE_APP_MYCTL_HOST = getEnvVar('APP_MYCTL_HOST');
export const VUE_APP_DH_HOST = getEnvVar('APP_DH_HOST');
export const VUE_APP_DSSP_HOST = getEnvVar('APP_DSSP_HOST');
export const VUE_APP_TWOXS_HOST = getEnvVar('APP_TWOXS_HOST');
export const VUE_APP_KAL_HOST = getEnvVar('APP_KAL_HOST');
export const VUE_APP_DOTCOMHOST = getEnvVar('APP_DOTCOMHOST');
export const VUE_APP_LUMENHOST = getEnvVar('APP_LUMENHOST');
export const VUE_APP_PUBLIC_IP = getEnvVar('APP_PUBLIC_IP');
export const VUE_APP_CRYPTO_APP_KEY = getEnvVar('APP_CRYPTO_APP_KEY');
export const VUE_APP_CRYPTO_APP_IV = getEnvVar('APP_CRYPTO_APP_IV');
export const VUE_APP_CRYPTO_PASSPHRASE = getEnvVar('APP_CRYPTO_PASSPHRASE');
export const VUE_APP_NOKIA_URL = getEnvVar('APP_NOKIA_URL');
export const VUE_APP_NOKIA_BSPD_URL = getEnvVar('APP_NOKIA_BSPD_URL');
export const VUE_APP_MOCK_API = getEnvVar('APP_MOCK_API');
export const VUE_APP_GTM_ID = getEnvVar('APP_GTM_ID');
export const VUE_APP_GTM_AUTH = getEnvVar('APP_GTM_AUTH');
export const VUE_APP_GTM_ENV = getEnvVar('APP_GTM_ENV');
export const VUE_APP_GTM_COOCKIES = getEnvVar('APP_GTM_COOCKIES');
export const VUE_APP_BRIGHTSPEED_TOGGLE = getEnvVar('APP_BRIGHTSPEED_TOGGLE');
export const VUE_APP_BRIGHTSPEED_DAYONEPLUS_TOGGLE = getEnvVar(
  'APP_BRIGHTSPEED_DAYONEPLUS_TOGGLE'
);
export const VUE_APP_BRIGHTSPEED_DSSR_URL = getEnvVar(
  'APP_BRIGHTSPEED_DSSR_URL'
);
export const VUE_APP_BRIGHTSPEED_URL = getEnvVar('APP_BRIGHTSPEED_URL');
export const VUE_APP_SSC_TOGGLE = getEnvVar('APP_SSC_TOGGLE');
export const VUE_APP_GMAP_CHANNEL = getEnvVar('APP_GMAP_CHANNEL');
export const VUE_APP_GMAP_CLIENT = getEnvVar('APP_GMAP_CLIENT');
