/* eslint-disable class-methods-use-this */
import { call } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';

class QueryParamsSTSIFHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    response.handleQueryParamsAction = HandleQueryParamsAction.PENDING;
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSIFHandler;

export const useQueryParamsSTSIFHandler = (): QueryParamsSTSIFHandler => {
  if (!qp) {
    qp = new QueryParamsSTSIFHandler();
  }
  return qp;
};
