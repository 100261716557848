import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { STSLandingIterState, STSLandingState } from './types';
import * as treekeys from './treekeys';

const initState: STSLandingIterState = {
  showValidateErrorsAccountNumber: false,
  selectedAccountNumber: '',
  throttled: false,
  submitPending: false,
  queryParamsUsed: false,
};

export const data: STSLandingState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<STSLandingState, any> = {
  [treekeys.GET_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER]: (state) =>
    state.currState.selectedAccountNumber,
  [treekeys.GET_STSLANDINGMODULE_SELECTEDZIPCODE]: (state) =>
    state.currState.selectedZipCode,
  [treekeys.GET_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER]: (state) =>
    state.currState.showValidateErrorsAccountNumber,
  [treekeys.GET_STSLANDINGMODULE_THROTTLED]: (state) =>
    state.currState.throttled,
  [treekeys.GET_STSLANDINGMODULE_SUBMITPENDING]: (state) =>
    state.currState.submitPending,
  [treekeys.GET_STSLANDINGMODULE_QUERYPARAMSUSED]: (state) =>
    state.currState.queryParamsUsed,
  [treekeys.GET_STSLANDINGMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_STSLANDINGMODULE_PREVSTATE]: (state) => state.prevState,
  [treekeys.GET_STSLANDINGMODULE_C360ACCTLISTERRKEY]: (state) =>
    state.currState.c360AcctListErrKey,
  [treekeys.GET_STSLANDINGMODULE_RXPRODUCTINFOERRKEY]: (state) =>
    state.currState.rxProductInfoErrKey,
};
export const mutations: MutationTree<STSLandingState> = {
  [treekeys.MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER]: (
    state,
    { selectedAccountNumber }
  ) => {
    state.currState.selectedAccountNumber = selectedAccountNumber;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE]: (
    state,
    { selectedZipCode }
  ) => {
    state.currState.selectedZipCode = selectedZipCode;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER]: (
    state,
    { showValidateErrorsAccountNumber }
  ) => {
    state.currState.showValidateErrorsAccountNumber =
      showValidateErrorsAccountNumber;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_THROTTLED]: (state, { throttled }) => {
    state.currState.throttled = throttled;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_SUBMITPENDING]: (
    state,
    { submitPending }
  ) => {
    state.currState.submitPending = submitPending;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_QUERYPARAMSUSED]: (
    state,
    { queryParamsUsed }
  ) => {
    state.currState.queryParamsUsed = queryParamsUsed;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_C360ACCTLISTERRKEY]: (
    state,
    { c360AcctListErrKey }
  ) => {
    state.currState.c360AcctListErrKey = c360AcctListErrKey;
  },
  [treekeys.MUTATE_STSLANDINGMODULE_RXPRODUCTINFOERRKEY]: (
    state,
    { rxProductInfoErrKey }
  ) => {
    state.currState.rxProductInfoErrKey = rxProductInfoErrKey;
  },
};
export const actions: ActionTree<STSLandingState, any> = {
  [treekeys.ACTION_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER]: (
    { commit },
    { selectedAccountNumber }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER, {
      selectedAccountNumber,
    });
  },
  [treekeys.ACTION_STSLANDINGMODULE_SELECTEDZIPCODE]: (
    { commit },
    { selectedZipCode }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE, {
      selectedZipCode,
    });
  },
  [treekeys.ACTION_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER]: (
    { commit },
    { showValidateErrorsAccountNumber }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER, {
      showValidateErrorsAccountNumber,
    });
  },
  [treekeys.ACTION_STSLANDINGMODULE_THROTTLED]: ({ commit }, { throttled }) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_THROTTLED, { throttled });
  },
  [treekeys.ACTION_STSLANDINGMODULE_SUBMITPENDING]: (
    { commit },
    { submitPending }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_SUBMITPENDING, { submitPending });
  },
  [treekeys.ACTION_STSLANDINGMODULE_QUERYPARAMSUSED]: (
    { commit },
    { queryParamsUsed }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_QUERYPARAMSUSED, {
      queryParamsUsed,
    });
  },
  [treekeys.ACTION_STSLANDINGMODULE_C360ACCTLISTERRKEY]: (
    { commit },
    { c360AcctListErrKey }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_C360ACCTLISTERRKEY, {
      c360AcctListErrKey,
    });
  },
  [treekeys.ACTION_STSLANDINGMODULE_RXPRODUCTINFOERRKEY]: (
    { commit },
    { rxProductInfoErrKey }
  ) => {
    commit(treekeys.MUTATE_STSLANDINGMODULE_RXPRODUCTINFOERRKEY, {
      rxProductInfoErrKey,
    });
  },
};

const stsLandingModule: Module<STSLandingState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default stsLandingModule;
