export const GET_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP =
  'GET_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP';
export const GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDTHROTTLE =
  'GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDTHROTTLE';
export const GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDSHIFTLRU';
export const MUTATE_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP =
  'MUTATE_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP';
export const MUTATE_GETNOKIASSCTOKENPARALLELMODULE_SHIFTLRU =
  'MUTATE_GETNOKIASSCTOKENPARALLELMODULE_SHIFTLRU';
export const MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING =
  'MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING';
export const MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN =
  'MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN';
export const MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR =
  'MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR';
export const ACTION_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP =
  'ACTION_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP';
export const ACTION_GETNOKIASSCTOKENPARALLELMODULE_PENDING =
  'ACTION_GETNOKIASSCTOKENPARALLELMODULE_PENDING';
export const ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKEN =
  'ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKEN';
export const ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR =
  'ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR';
export const GETNOKIASSCTOKENPARALLELMODULE = 'getNokiaSSCTokenParallelModule';
export const GETNOKIASSCTOKENPARALLELMODULE_MAXPARALLEL = 3;
export const GETNOKIASSCTOKENPARALLELMODULE_MAXENTRIES = 10;
export const NS_GET_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP = `${GETNOKIASSCTOKENPARALLELMODULE}/${GET_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP}`;
export const NS_GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDTHROTTLE = `${GETNOKIASSCTOKENPARALLELMODULE}/${GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDSHIFTLRU = `${GETNOKIASSCTOKENPARALLELMODULE}/${GET_GETNOKIASSCTOKENPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP = `${GETNOKIASSCTOKENPARALLELMODULE}/${MUTATE_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP}`;
export const NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_SHIFTLRU = `${GETNOKIASSCTOKENPARALLELMODULE}/${MUTATE_GETNOKIASSCTOKENPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING = `${GETNOKIASSCTOKENPARALLELMODULE}/${MUTATE_GETNOKIASSCTOKENPARALLELMODULE_PENDING}`;
export const NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN = `${GETNOKIASSCTOKENPARALLELMODULE}/${MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKEN}`;
export const NS_MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR = `${GETNOKIASSCTOKENPARALLELMODULE}/${MUTATE_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR}`;
export const NS_ACTION_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP = `${GETNOKIASSCTOKENPARALLELMODULE}/${ACTION_GETNOKIASSCTOKENPARALLELMODULE_GETNOKIASSCTOKENPARALLELMAP}`;
export const NS_ACTION_GETNOKIASSCTOKENPARALLELMODULE_PENDING = `${GETNOKIASSCTOKENPARALLELMODULE}/${ACTION_GETNOKIASSCTOKENPARALLELMODULE_PENDING}`;
export const NS_ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKEN = `${GETNOKIASSCTOKENPARALLELMODULE}/${ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKEN}`;
export const NS_ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR = `${GETNOKIASSCTOKENPARALLELMODULE}/${ACTION_GETNOKIASSCTOKENPARALLELMODULE_TOKENERROR}`;
