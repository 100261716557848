export const GET_STSPENDINGORDERMODULE_CURRSTATE =
  'GET_STSPENDINGORDERMODULE_CURRSTATE';
export const GET_STSPENDINGORDERMODULE_PREVSTATE =
  'GET_STSPENDINGORDERMODULE_PREVSTATE';
export const GET_STSPENDINGORDERMODULE_ROUTEPARAMS =
  'GET_STSPENDINGORDERMODULE_ROUTEPARAMS';
export const MUTATE_STSPENDINGORDERMODULE_CURRSTATE =
  'MUTATE_STSPENDINGORDERMODULE_CURRSTATE';
export const MUTATE_STSPENDINGORDERMODULE_PREVSTATE =
  'MUTATE_STSPENDINGORDERMODULE_PREVSTATE';
export const MUTATE_STSPENDINGORDERMODULE_ROUTEPARAMS =
  'MUTATE_STSPENDINGORDERMODULE_ROUTEPARAMS';
export const ACTION_STSPENDINGORDERMODULE_ROUTEPARAMS =
  'ACTION_STSPENDINGORDERMODULE_ROUTEPARAMS';
export const STSPENDINGORDERMODULE = 'sTSPENDINGORDERModule';
export const NS_GET_STSPENDINGORDERMODULE_CURRSTATE = `${STSPENDINGORDERMODULE}/${GET_STSPENDINGORDERMODULE_CURRSTATE}`;
export const NS_GET_STSPENDINGORDERMODULE_PREVSTATE = `${STSPENDINGORDERMODULE}/${GET_STSPENDINGORDERMODULE_PREVSTATE}`;
export const NS_GET_STSPENDINGORDERMODULE_ROUTEPARAMS = `${STSPENDINGORDERMODULE}/${GET_STSPENDINGORDERMODULE_ROUTEPARAMS}`;
export const NS_MUTATE_STSPENDINGORDERMODULE_CURRSTATE = `${STSPENDINGORDERMODULE}/${MUTATE_STSPENDINGORDERMODULE_CURRSTATE}`;
export const NS_MUTATE_STSPENDINGORDERMODULE_PREVSTATE = `${STSPENDINGORDERMODULE}/${MUTATE_STSPENDINGORDERMODULE_PREVSTATE}`;
export const NS_MUTATE_STSPENDINGORDERMODULE_ROUTEPARAMS = `${STSPENDINGORDERMODULE}/${MUTATE_STSPENDINGORDERMODULE_ROUTEPARAMS}`;
export const NS_ACTION_STSPENDINGORDERMODULE_ROUTEPARAMS = `${STSPENDINGORDERMODULE}/${ACTION_STSPENDINGORDERMODULE_ROUTEPARAMS}`;
