import { Loader } from '@googlemaps/js-api-loader';
import { VUE_APP_GMAP_CLIENT, VUE_APP_GMAP_CHANNEL } from '@/common/envVars';

class GmapLoaderSingleton {
  gLoader;

  constructor(gLoader: any) {
    this.gLoader = gLoader;
  }

  /* 
    Everytime importLibrary() is called from the loader
    a google instance will be assigned to 'window'
  */
  getLoader = () => this.gLoader;
}

let googleMapLoaderSingleton: GmapLoaderSingleton;

export const useGmapLoaderSingleton = (): GmapLoaderSingleton => {
  if (!googleMapLoaderSingleton) {
    const loader = new Loader({
      apiKey: '',
      client: VUE_APP_GMAP_CLIENT,
      channel: VUE_APP_GMAP_CHANNEL,
      language: 'en-US',
    });
    googleMapLoaderSingleton = new GmapLoaderSingleton(loader);
  }
  return googleMapLoaderSingleton;
};
