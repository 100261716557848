import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { PerformMobileSSOState, MobilePostLoginIterState } from './types';
import * as treekeys from './treekeys';

const initState: MobilePostLoginIterState = {
  selectedC360AccountNumber: '',
};

export const data: PerformMobileSSOState = {
  pending: false,
  mobileSSOResponse: {},
  mobilePostLoginState: {
    currState: { ...initState },
    prevState: { ...initState },
  },
  mobileSSOError: undefined,
};

export const getters: GetterTree<PerformMobileSSOState, any> = {
  [treekeys.GET_PERFORMMOBILESSOMODULE_PENDING]: (state) => state.pending,
  [treekeys.GET_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE]: (state) =>
    state.mobileSSOResponse,
  [treekeys.GET_PERFORMMOBILESSOMODULE_MOBILESSOERROR]: (state) =>
    state.mobileSSOError,
  [treekeys.GET_PERFORMMOBILESSOMODULE_AUTHENTICATED]: (state) =>
    state.mobileSSOResponse.authenticated,
  [treekeys.GET_PERFORMMOBILESSOMODULE_ERRORMESSAGE]: (state) =>
    state.mobileSSOResponse.errorMessage || state.mobileSSOError,
  [treekeys.GET_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER]: (state) =>
    state.mobilePostLoginState.currState.selectedC360AccountNumber,
  [treekeys.GET_PERFORMMOBILESSOMODULE_CURRSTATE]: (state) =>
    state.mobilePostLoginState.currState,
  [treekeys.GET_PERFORMMOBILESSOMODULE_PREVSTATE]: (state) =>
    state.mobilePostLoginState.prevState,
};
export const mutations: MutationTree<PerformMobileSSOState> = {
  [treekeys.MUTATE_PERFORMMOBILESSOMODULE_PENDING]: (state) => {
    state.pending = true;
  },
  [treekeys.MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE]: (
    state,
    { mobileSSOResponse }
  ) => {
    state.pending = false;
    state.mobileSSOResponse = mobileSSOResponse;
    state.mobileSSOError = undefined;
  },
  [treekeys.MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR]: (
    state,
    { mobileSSOError }
  ) => {
    state.mobileSSOError = mobileSSOError;
  },
  [treekeys.MUTATE_PERFORMMOBILESSOMODULE_CURRSTATE]: (
    state,
    { currState }
  ) => {
    state.mobilePostLoginState.currState = currState;
  },
  [treekeys.MUTATE_PERFORMMOBILESSOMODULE_PREVSTATE]: (
    state,
    { prevState }
  ) => {
    state.mobilePostLoginState.prevState = prevState;
  },
  [treekeys.MUTATE_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    state,
    { selectedC360AccountNumber }
  ) => {
    state.mobilePostLoginState.currState.selectedC360AccountNumber =
      selectedC360AccountNumber;
  },
};
export const actions: ActionTree<PerformMobileSSOState, any> = {
  [treekeys.ACTION_PERFORMMOBILESSOMODULE_PENDING]: ({ commit }) => {
    commit(treekeys.MUTATE_PERFORMMOBILESSOMODULE_PENDING);
  },
  [treekeys.ACTION_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE]: (
    { commit },
    { mobileSSOResponse }
  ) => {
    commit(treekeys.MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE, {
      mobileSSOResponse,
    });
  },
  [treekeys.ACTION_PERFORMMOBILESSOMODULE_MOBILESSOERROR]: (
    { commit },
    { mobileSSOError }
  ) => {
    commit(treekeys.MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR, {
      mobileSSOError,
    });
  },
  [treekeys.ACTION_PERFORMMOBILESSOMODULE_CURRSTATE]: (
    { commit },
    { currState }
  ) => {
    commit(treekeys.MUTATE_PERFORMMOBILESSOMODULE_CURRSTATE, { currState });
  },
  [treekeys.ACTION_PERFORMMOBILESSOMODULE_PREVSTATE]: (
    { commit },
    { prevState }
  ) => {
    commit(treekeys.MUTATE_PERFORMMOBILESSOMODULE_PREVSTATE, { prevState });
  },
  [treekeys.ACTION_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    { commit },
    { selectedC360AccountNumber }
  ) => {
    commit(treekeys.MUTATE_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER, {
      selectedC360AccountNumber,
    });
  },
};

const performMobileSSOModule: Module<PerformMobileSSOState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default performMobileSSOModule;
