/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
import { apply } from '@redux-saga/core/effects';
import AbstractBusinessChainRequestHandler from './abstractBusinessChainRequestHandler';

class AbstractBusinessChain<TRequest, TResponse> {
  protected chainHandlers: AbstractBusinessChainRequestHandler<
    TRequest,
    TResponse
  >[];

  constructor() {
    this.chainHandlers = [];
    this.setupChain();
  }

  public *chainBusinessLogic(request: TRequest, response: TResponse): any {
    for (const chainHandler of this.chainHandlers) {
      const shouldNotHandle = yield apply(
        chainHandler,
        chainHandler.shouldNotHandle,
        [request, response]
      );
      if (shouldNotHandle === false) {
        yield apply(chainHandler, chainHandler.handleRequest, [
          request,
          response,
        ]);
      }
    }
  }

  protected setupChain() {
    throw new Error('setupChain not implemented');
  }
}

export default AbstractBusinessChain;
