import AbstractBusinessChain from '../abstractBusinessChain';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { useQueryParamsWMTLHandler } from './queryParamsWMTLHandler';
import { useQueryParamsWMTSAHandler } from './queryParamsWMTSAHandler';

class QueryParamsWMTSABusinessChain extends AbstractBusinessChain<
  QueryParamsRequest,
  QueryParamsResponse
> {
  setupChain() {
    this.chainHandlers.push(useQueryParamsWMTLHandler());
    this.chainHandlers.push(useQueryParamsWMTSAHandler());
  }
}

let qp: QueryParamsWMTSABusinessChain;

export const useQueryParamsWMTSABusinessChain =
  (): QueryParamsWMTSABusinessChain => {
    if (!qp) {
      qp = new QueryParamsWMTSABusinessChain();
    }
    return qp;
  };
