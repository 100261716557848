/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from '@redux-saga/core/effects';
import * as tk from '../modules/progressbar/treekeys';
import * as tkST from '../modules/sagaTasks/treekeys';
import { useSagaUtilsSingleton } from '../../common/utils/sagaUtilsSingleton';

const DELAY_BEFORE_BAR_APPEAR_MS = 500;
const INIT_PROGRESS_MS = 100;
const FINISH_PROGRESS_MS = 1000;

const LONGER_THAN_USUAL_MARK_MS = 15000;
const TIMEOUT_MARK_MS = 60000;

const sagaUtils = useSagaUtilsSingleton();

export function* waitForLongerThanUsual(): any {
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_LONGERTHANUSUAL,
    longerThanUsual: false,
  });
  yield delay(LONGER_THAN_USUAL_MARK_MS);
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_LONGERTHANUSUAL,
    longerThanUsual: true,
  });
}

export function* initProgressBarAppear(): any {
  yield delay(DELAY_BEFORE_BAR_APPEAR_MS);
  const runningSegments: any[] = yield select(
    (s) => s?.[tk.PROGRESSBARMODULE]?.runningSegments
  );
  if (
    runningSegments &&
    runningSegments.filter((task: any) => task.isRunning()).length > 0
  ) {
    yield put({
      type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR,
      progressBarAppear: true,
    });
  }
}

export function* runProgress(): any {
  const startTime = Date.now();
  yield delay(DELAY_BEFORE_BAR_APPEAR_MS + INIT_PROGRESS_MS);
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_VISUALFINISHED,
    visualFinished: false,
  });
  let progressValue: number = yield select(
    (s) => s?.[tk.PROGRESSBARMODULE]?.progressValue
  );
  let segments: number = yield select(
    (s) => s?.[tk.PROGRESSBARMODULE]?.segments
  );
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSVALUE,
    progressValue: progressValue + 5 / segments,
  });
  const timeoutMS: number = yield select(
    (s) => s?.[tk.PROGRESSBARMODULE]?.timeout
  );
  while (Date.now() - startTime < timeoutMS) {
    const rand = sagaUtils.getRandom(1000, 4000);
    yield delay(rand);
    progressValue = yield select(
      (s) => s?.[tk.PROGRESSBARMODULE]?.progressValue
    );
    segments = yield select((s) => s?.[tk.PROGRESSBARMODULE]?.segments);
    yield put({
      type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSVALUE,
      progressValue: progressValue + 95 / segments / (timeoutMS / rand),
    });
  }
}

export function* finishProgressNoBlock() {
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSBARAPPEAR,
    progressBarAppear: false,
  });
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_LONGERTHANUSUAL,
    longerThanUsual: false,
  });
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSVALUE,
    progressValue: 0.0,
  });
}

export function* finishProgress() {
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSVALUE,
    progressValue: 100,
  });
  yield delay(FINISH_PROGRESS_MS);
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_VISUALFINISHED,
    visualFinished: true,
  });
  yield call(finishProgressNoBlock);
}

export function* cancelHandlerRunProgress(): any {
  const startTime = Date.now();
  try {
    yield call(runProgress);
  } finally {
    if (yield cancelled()) {
      const timeoutMS: number = yield select(
        (s) => s?.[tk.PROGRESSBARMODULE]?.timeout
      );
      const remainingTimeMS = timeoutMS - (Date.now() - startTime);
      const progressValue: number = yield select(
        (s) => s?.[tk.PROGRESSBARMODULE]?.progressValue
      );
      const segments: number = yield select(
        (s) => s?.[tk.PROGRESSBARMODULE]?.segments
      );
      yield put({
        type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSVALUE,
        progressValue:
          progressValue + 95 / segments / (timeoutMS / remainingTimeMS),
      });
    }
  }
}

export function* handleProgressBar(progressBarConfig: any): any {
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_PROGRESSVALUE,
    progressValue: 0,
  });
  yield fork(initProgressBarAppear);
  const waitForLongerThanUsualTask = yield fork(waitForLongerThanUsual);
  const segments = progressBarConfig?.payload?.segments
    ? progressBarConfig.payload.segments
    : 1;
  const timeout = progressBarConfig?.payload?.timeout
    ? progressBarConfig.payload.timeout
    : TIMEOUT_MARK_MS;
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_SEGMENTS,
    segments,
  });
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_TIMEOUT,
    timeout,
  });
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_RUNNINGSEGMENTS,
    runningSegments: [],
  });
  const runningSegments: any[] = yield select(
    (s) => s?.[tk.PROGRESSBARMODULE]?.runningSegments
  );
  for (let i = 0; i < segments; i += 1) {
    const task = yield fork(cancelHandlerRunProgress);
    runningSegments.push(task);
  }
  yield put({
    type: tk.NS_MUTATE_PROGRESSBAR_RUNNINGSEGMENTS,
    runningSegments,
  });
  while (runningSegments.filter((task: any) => task.isRunning()).length > 0) {
    yield delay(1000);
  }
  yield cancel(waitForLongerThanUsualTask);
  yield call(finishProgress);
}

export function* cancelHandlerHandleProgressBar(progressBarConfig: any): any {
  try {
    yield call(handleProgressBar, progressBarConfig);
  } finally {
    if (yield cancelled()) {
      yield* finishProgressNoBlock();
    }
  }
}

export function* taskHandleProgressBar(progressBarConfig: any): any {
  const task = yield fork(cancelHandlerHandleProgressBar, progressBarConfig);
  const taskObj: any = {
    type: tkST.NS_MUTATE_SAGATASKS_HANDLEPROGRESSBAR_TASK,
  };
  taskObj[tkST.K_HANDLEPROGRESSBARTASK] = task;
  yield put(taskObj);
}

export function* handleProgressBarFinishSegments(segments: any): any {
  const runningSegments: any[] = yield select(
    (s) => s?.[tk.PROGRESSBARMODULE]?.runningSegments
  );
  const runningSegsOnlyRunning = runningSegments.filter((task: any) =>
    task.isRunning()
  );
  const segsToCancel = Math.min(
    segments?.payload,
    runningSegsOnlyRunning.length
  );
  if (segsToCancel) {
    for (let i = 0; i < segsToCancel; i += 1) {
      yield cancel(runningSegsOnlyRunning[i]);
    }
  }
}

export function* cancelHandleProgressBar(): any {
  const task = yield select(
    (s) => s?.[tkST.SAGATASKSMODULE]?.[tkST.K_HANDLEPROGRESSBARTASK]
  );
  if (task && task.isRunning()) {
    yield cancel(task);
  }
}

export function* watchCancelHandleProgressBar(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, cancelHandleProgressBar);
}

export function* watchHandleProgressBarFinishSegments(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, handleProgressBarFinishSegments);
}

export function* watchHandleProgressBar(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, taskHandleProgressBar);
}

export default watchHandleProgressBar;
