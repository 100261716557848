/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-unused-vars */
import { VuexSaga } from 'vuex-coolstory';
import { fork } from '@redux-saga/core/effects';
import * as s0 from '@/store';
import { PROGRESSBARMODULE } from '@/store/modules/progressbar/treekeys';
import { FULL_ACCESS_MODULES } from './modules';
import { watchAzureAttrs } from './sagas/watchAzureAttrs';
import { watchHandleAuth } from './sagas/watchHandleAuth';
import {
  watchHandleProgressBar,
  watchHandleProgressBarFinishSegments,
  watchCancelHandleProgressBar,
} from './sagas/watchHandleProgressBar';
import {
  watchWMTLandingGetC360Accts,
  watchCancelWMTLandingGetC360Accts,
} from './sagas/watchWMTLandingGetC360Accts';
import { watchWMTRescheduleUpdateRXTicket } from './sagas/watchWMTRescheduleUpdateRXTicket';
import { watchWMTCancelUpdateRXTicket } from './sagas/watchWMTCancelUpdateRXTicket';
import { watchGet34SEcomUserProfile } from './sagas/watchGet34SEcomUserProfile';
import { AZUREATTRSMODULE } from './modules/azureattrs/treekeys';
import { AUTHMODULE } from './modules/auth/treekeys';
import { WMTLANDINGMODULE } from './modules/wmtLandingModule/treekeys';
import { WMTRESCHEDULEMODULE } from './modules/wmtRescheduleModule/treekeys';
import { WMTCANCELMODULE } from './modules/wmtCancelModule/treekeys';
import {
  CANCELWMTLANDINGGETC360ACCTS,
  CANCEL_HANDLE_PROGRESS_BAR,
  GET34SECOMUSERPROFILE,
  GETC360ACCTSPARALLEL,
  GETC360PENDINGORDERSANDRXTICKETINFOSPARALLEL,
  GETC360PENDINGORDERSPARALLEL,
  GETNOKIASSCTOKENPARALLEL,
  GETRXAPPOINTMENTINFOSPARALLEL,
  GETRXPRODUCTINFOPARALLEL,
  GETSERVICEADDRESSANDTECHNICIANDETAILS,
  GETSERVICEADDRESSGEOCODEINFO,
  GET_AZURE_ATTRS,
  HANDLELOGOUT,
  HANDLEQUERYPARAMSSTSIF,
  HANDLEQUERYPARAMSSTSSA,
  HANDLEQUERYPARAMSSTSSW,
  HANDLEQUERYPARAMSWMTR,
  HANDLEQUERYPARAMSWMTSA,
  HANDLEQUERYPARAMSWMTUT,
  HANDLE_AUTH,
  HANDLE_PROGRESS_BAR,
  HANDLE_PROGRESS_BAR_FINISH_SEGMENTS,
  HANDLE_PROGRESS_SPINNER,
  STSLANDINGGETC360ACCTS,
  UPDATERXTICKETPARALLEL,
  WMTCANCELUPDATERXTICKET,
  WMTLANDINGGETC360ACCTS,
  WMTRESCHEDULEUPDATERXTICKET,
  PERFORMMOBILESSO,
  SENDUILOG,
} from './sagas/patterns';
import { GET34SECOMUSERPROFILEMODULE } from './modules/get34SEcomUserProfileModule/treekeys';
import { GETC360ACCTSPARALLELMODULE } from './modules/getC360AcctsParallelModule/treekeys';
import { GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE } from './modules/getC360PendingOrdersAndRXTicketInfosParallelModule/treekeys';
import { GETRXAPPOINTMENTINFOSPARALLELMODULE } from './modules/getRXAppointmentInfosParallelModule/treekeys';
import { UPDATERXTICKETPARALLELMODULE } from './modules/updateRXTicketParallelModule/treekeys';
import { GETNOKIASSCTOKENPARALLELMODULE } from './modules/getNokiaSSCTokenParallelModule/treekeys';
import { GETRXPRODUCTINFOPARALLELMODULE } from './modules/getRXProductInfoParallelModule/treekeys';
import { TRACKERTECHMODULE } from './modules/trackerTechModule/treekeys';
import { GETPERFORMMOBILESSOMODULE } from './modules/performMobileSSOModule/treekeys';
import { GETC360PENDINGORDERSPARALLELMODULE } from './modules/getC360PendingOrdersParallelModule/treekeys';
import { HANDLEQUERYPARAMSMODULE } from './modules/handleQueryParamsModule/treekeys';
import { STSLANDINGMODULE } from './modules/stsLandingModule/treekeys';
import { PROGRESSSPINNERMODULE } from './modules/progressspinner/treekeys';
import { SENDUILOGMODULE } from './modules/senduilog/treekeys';

import { watchGetRXProductInfoParallel } from './sagas/watchGetRXProductInfoParallel';
import { watchGetServiceAddressAndTechnicianDetails } from './sagas/watchGetServiceAddressAndTechnicianDetails';
import { watchGetServiceAddressGeocodeInfo } from './sagas/watchGetServiceAddressGeocodeInfo';
import { watchGetNokiaSSCTokenParallel } from './sagas/watchGetNokiaSSCTokenParallel';
import { watchUpdateRXTicketParallel } from './sagas/watchUpdateRXTicketParallel';
import { watchGetC360AcctsParallel } from './sagas/watchGetC360AcctsParallel';
import { watchGetC360PendingOrdersAndRXTicketInfosParallel } from './sagas/watchGetC360PendingOrdersAndRXTicketInfosParallel';
import { watchGetRXAppointmentInfosParallel } from './sagas/watchGetRXAppointmentInfosParallel';
import { watchHandleLogout } from './sagas/watchHandleLogout';
import { watchHandleQueryParamsWMTSA } from './sagas/watchHandleQueryParamsWMTSA';
import { watchHandleQueryParamsWMTR } from './sagas/watchHandleQueryParamsWMTR';
import { watchHandleQueryParamsWMTUT } from './sagas/watchHandleQueryParamsWMTUT';
import { watchHandleQueryParamsSTSIF } from './sagas/watchHandleQueryParamsSTSIF';
import { watchHandleQueryParamsSTSSA } from './sagas/watchHandleQueryParamsSTSSA';
import { watchHandleQueryParamsSTSSW } from './sagas/watchHandleQueryParamsSTSSW';
import { watchHandleProgressSpinner } from './sagas/watchHandleProgressSpinner';
import { watchSTSLandingGetC360Accts } from './sagas/watchSTSLandingGetC360Accts';
import { watchPerformMobileSSO } from './sagas/watchPerformMobileSSO';
import { watchSendUILogs } from './sagas/watchSendUILogs';

interface NSPatternSagaRecord {
  pattern: string;
  saga: (pattern0: string) => Generator<any, any, any>;
}
interface NSSagaRecord {
  patternSagaRecords: NSPatternSagaRecord[] | undefined;
  nsStore: any | undefined;
}
const nsSagasMap: Record<string, NSSagaRecord> = {};
const registerNSSaga = (
  ns: string,
  saga: (pattern0: string) => Generator<any, any, any>,
  pattern: string
) => {
  if (!nsSagasMap[ns]) {
    nsSagasMap[ns] = {
      patternSagaRecords: [],
      nsStore: undefined,
    };
  }
  nsSagasMap[ns]?.patternSagaRecords?.push({
    pattern,
    saga,
  });
};

registerNSSaga(AZUREATTRSMODULE, watchAzureAttrs, GET_AZURE_ATTRS);
registerNSSaga(AUTHMODULE, watchHandleAuth, HANDLE_AUTH);
registerNSSaga(AUTHMODULE, watchHandleLogout, HANDLELOGOUT);
registerNSSaga(PROGRESSBARMODULE, watchHandleProgressBar, HANDLE_PROGRESS_BAR);
registerNSSaga(
  PROGRESSSPINNERMODULE,
  watchHandleProgressSpinner,
  HANDLE_PROGRESS_SPINNER
);
registerNSSaga(
  GET34SECOMUSERPROFILEMODULE,
  watchGet34SEcomUserProfile,
  GET34SECOMUSERPROFILE
);
registerNSSaga(
  GETC360ACCTSPARALLELMODULE,
  watchGetC360AcctsParallel,
  GETC360ACCTSPARALLEL
);
registerNSSaga(
  GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE,
  watchGetC360PendingOrdersAndRXTicketInfosParallel,
  GETC360PENDINGORDERSANDRXTICKETINFOSPARALLEL
);
registerNSSaga(
  GETC360PENDINGORDERSPARALLELMODULE,
  watchGetC360PendingOrdersAndRXTicketInfosParallel,
  GETC360PENDINGORDERSPARALLEL
);
registerNSSaga(
  GETNOKIASSCTOKENPARALLELMODULE,
  watchGetNokiaSSCTokenParallel,
  GETNOKIASSCTOKENPARALLEL
);
registerNSSaga(
  GETRXAPPOINTMENTINFOSPARALLELMODULE,
  watchGetRXAppointmentInfosParallel,
  GETRXAPPOINTMENTINFOSPARALLEL
);
registerNSSaga(
  GETRXPRODUCTINFOPARALLELMODULE,
  watchGetRXProductInfoParallel,
  GETRXPRODUCTINFOPARALLEL
);
registerNSSaga(
  TRACKERTECHMODULE,
  watchGetServiceAddressAndTechnicianDetails,
  GETSERVICEADDRESSANDTECHNICIANDETAILS
);
registerNSSaga(
  TRACKERTECHMODULE,
  watchGetServiceAddressGeocodeInfo,
  GETSERVICEADDRESSGEOCODEINFO
);
registerNSSaga(
  UPDATERXTICKETPARALLELMODULE,
  watchUpdateRXTicketParallel,
  UPDATERXTICKETPARALLEL
);
registerNSSaga(
  PROGRESSBARMODULE,
  watchHandleProgressBarFinishSegments,
  HANDLE_PROGRESS_BAR_FINISH_SEGMENTS
);
registerNSSaga(
  PROGRESSBARMODULE,
  watchCancelHandleProgressBar,
  CANCEL_HANDLE_PROGRESS_BAR
);

registerNSSaga(
  HANDLEQUERYPARAMSMODULE,
  watchHandleQueryParamsSTSIF,
  HANDLEQUERYPARAMSSTSIF
);
registerNSSaga(
  HANDLEQUERYPARAMSMODULE,
  watchHandleQueryParamsSTSSA,
  HANDLEQUERYPARAMSSTSSA
);
registerNSSaga(
  HANDLEQUERYPARAMSMODULE,
  watchHandleQueryParamsSTSSW,
  HANDLEQUERYPARAMSSTSSW
);
registerNSSaga(
  HANDLEQUERYPARAMSMODULE,
  watchHandleQueryParamsWMTSA,
  HANDLEQUERYPARAMSWMTSA
);
registerNSSaga(
  HANDLEQUERYPARAMSMODULE,
  watchHandleQueryParamsWMTR,
  HANDLEQUERYPARAMSWMTR
);
registerNSSaga(
  HANDLEQUERYPARAMSMODULE,
  watchHandleQueryParamsWMTUT,
  HANDLEQUERYPARAMSWMTUT
);

registerNSSaga(
  STSLANDINGMODULE,
  watchSTSLandingGetC360Accts,
  STSLANDINGGETC360ACCTS
);
registerNSSaga(
  WMTLANDINGMODULE,
  watchWMTLandingGetC360Accts,
  WMTLANDINGGETC360ACCTS
);
registerNSSaga(
  WMTLANDINGMODULE,
  watchCancelWMTLandingGetC360Accts,
  CANCELWMTLANDINGGETC360ACCTS
);
registerNSSaga(
  WMTRESCHEDULEMODULE,
  watchWMTRescheduleUpdateRXTicket,
  WMTRESCHEDULEUPDATERXTICKET
);
registerNSSaga(
  WMTCANCELMODULE,
  watchWMTCancelUpdateRXTicket,
  WMTCANCELUPDATERXTICKET
);
registerNSSaga(
  GETPERFORMMOBILESSOMODULE,
  watchPerformMobileSSO,
  PERFORMMOBILESSO
);
registerNSSaga(SENDUILOGMODULE, watchSendUILogs, SENDUILOG);

class VuexSagaSetup {
  constructor() {
    if (process.env.NODE_ENV === 'development') {
      const nses = Object.keys(nsSagasMap);
      for (const ns of nses) {
        const patternSagaRecords = nsSagasMap[ns]?.patternSagaRecords;
        if (patternSagaRecords) {
          this.setupDevEnvWatcherSaga(ns, patternSagaRecords);
        }
      }
    } else {
      this.setupDefaultWatcherSaga();
    }
  }

  setupDevEnvWatcherSaga = (
    ns: string,
    patternSagaRecords: NSPatternSagaRecord[]
  ) => {
    function* nsProtectedWatcherSaga() {
      for (const patternSagaRecord of patternSagaRecords) {
        yield fork(patternSagaRecord.saga, patternSagaRecord.pattern);
      }
    }
    const nsStore = s0.useNSProtectedStore(ns);
    nsSagasMap[ns].nsStore = nsStore;
    VuexSaga({
      sagas: [nsProtectedWatcherSaga],
    })(nsStore);
  };

  setupDefaultWatcherSaga = () => {
    function* watcherSaga() {
      const nses = Object.keys(nsSagasMap);
      for (const ns of nses) {
        const patternSagaRecords = nsSagasMap[ns]?.patternSagaRecords;
        if (patternSagaRecords) {
          for (const patternSagaRecord of patternSagaRecords) {
            yield fork(patternSagaRecord.saga, patternSagaRecord.pattern);
          }
        }
      }
    }
    VuexSaga({
      sagas: [watcherSaga],
    })(s0.default);
  };
}

let vuexSagaSetup: VuexSagaSetup;
const useVuexSagaSetup = () => {
  if (!vuexSagaSetup) {
    vuexSagaSetup = new VuexSagaSetup();
  }
  return vuexSagaSetup;
};

export const useNSProtectedSagaDispatch =
  (ns: string) =>
  (s: string, ...a: any[]) => {
    useVuexSagaSetup();
    if (process.env.NODE_ENV === 'development') {
      const sFAM: string | undefined = FULL_ACCESS_MODULES.find((fam) =>
        nsSagasMap[fam]?.patternSagaRecords?.find(
          (patternSagaRecord) => patternSagaRecord?.pattern === s
        )
      );
      if (
        !nsSagasMap[ns]?.patternSagaRecords?.find(
          (patternSagaRecord) => patternSagaRecord?.pattern === s
        ) &&
        !sFAM
      ) {
        throw new Error(
          `${s} saga accessed outside assigned namespace ${ns}, if ${s} should be accessible anywhere then add its module to FULL_ACCESS_MODULES array in src/store/modules/index.ts, ensure ${s} is registered correctly in src/store/protectedVuexSaga.ts`
        );
      }
      let storeAny: any = nsSagasMap[ns]?.nsStore;
      if (!storeAny && sFAM) {
        storeAny = nsSagasMap[sFAM]?.nsStore;
      }
      const { sagaDispatch } = storeAny;
      sagaDispatch?.apply(storeAny, [s, ...a]);
    } else {
      const storeAny: any = s0.default;
      const { sagaDispatch } = storeAny;
      sagaDispatch?.apply(storeAny, [s, ...a]);
    }
  };
