export const GET_GET34SECOMUSERPROFILEMODULE_PENDING =
  'GET_GET34SECOMUSERPROFILEMODULE_PENDING';
export const GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE =
  'GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE';
export const GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR =
  'GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR';
export const MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING =
  'MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING';
export const MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE =
  'MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE';
export const MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR =
  'MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR';
export const ACTION_GET34SECOMUSERPROFILEMODULE_PENDING =
  'ACTION_GET34SECOMUSERPROFILEMODULE_PENDING';
export const ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE =
  'ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE';
export const ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR =
  'ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR';
export const GET34SECOMUSERPROFILEMODULE = 'get34SEcomUserProfileModule';
export const NS_GET_GET34SECOMUSERPROFILEMODULE_PENDING = `${GET34SECOMUSERPROFILEMODULE}/${GET_GET34SECOMUSERPROFILEMODULE_PENDING}`;
export const NS_GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE = `${GET34SECOMUSERPROFILEMODULE}/${GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE}`;
export const NS_GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR = `${GET34SECOMUSERPROFILEMODULE}/${GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR}`;
export const NS_MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING = `${GET34SECOMUSERPROFILEMODULE}/${MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING}`;
export const NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE = `${GET34SECOMUSERPROFILEMODULE}/${MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE}`;
export const NS_MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR = `${GET34SECOMUSERPROFILEMODULE}/${MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR}`;
export const NS_ACTION_GET34SECOMUSERPROFILEMODULE_PENDING = `${GET34SECOMUSERPROFILEMODULE}/${ACTION_GET34SECOMUSERPROFILEMODULE_PENDING}`;
export const NS_ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE = `${GET34SECOMUSERPROFILEMODULE}/${ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE}`;
export const NS_ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR = `${GET34SECOMUSERPROFILEMODULE}/${ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR}`;
