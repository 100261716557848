import { call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import * as apiEndpts from '../../common/apiEndpts';
import * as envVars from '../../common/envVars';
import * as tk from '../modules/azureattrs/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import { useApiKeyUtilsSingleton } from '../../common/utils/apiKeyUtilsSingleton';

export function* getAzureAttrs(): any {
  yield put({ type: tk.NS_MUTATE_AZUREATTRS_PENDING });
  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
  try {
    const apiKeyUtils = useApiKeyUtilsSingleton();
    const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

    const resp = yield call(
      axios.get,
      `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.AUTH_AZURE_ATTRS}`,
      {
        withCredentials: true,
        headers: {
          ApiKey: encryptedApiKey,
        },
        /* timeout: 60000, */
      }
    );
    if (resp?.data) {
      yield put({
        type: tk.NS_MUTATE_AZUREATTRS_FULFILLED,
        azureAttrs: resp.data,
      });
    } else {
      yield put({
        type: tk.NS_MUTATE_AZUREATTRS_REJECTED,
        azureAttrsError: resp,
      });
    }
  } catch (err) {
    yield put({
      type: tk.NS_MUTATE_AZUREATTRS_REJECTED,
      azureAttrsError: err,
    });
  } finally {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: false,
    });
  }
}

export function* watchAzureAttrs(pattern: string): Generator<any, any, any> {
  yield takeLatest(pattern, getAzureAttrs);
}

export default watchAzureAttrs;
