import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { WMTPostLoginIterState, WMTPostLoginState } from './types';
import * as treekeys from './treekeys';

const initState: WMTPostLoginIterState = {
  selectedC360AccountNumber: '',
};

export const data: WMTPostLoginState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<WMTPostLoginState, any> = {
  [treekeys.GET_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER]: (state) =>
    state.currState.selectedC360AccountNumber,
  [treekeys.GET_WMTPOSTLOGINMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_WMTPOSTLOGINMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<WMTPostLoginState> = {
  [treekeys.MUTATE_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    state,
    { selectedC360AccountNumber }
  ) => {
    state.currState.selectedC360AccountNumber = selectedC360AccountNumber;
  },
  [treekeys.MUTATE_WMTPOSTLOGINMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_WMTPOSTLOGINMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<WMTPostLoginState, any> = {
  [treekeys.ACTION_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    { commit },
    { selectedC360AccountNumber }
  ) => {
    commit(treekeys.MUTATE_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER, {
      selectedC360AccountNumber,
    });
  },
};

const wmtPostLoginModule: Module<WMTPostLoginState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default wmtPostLoginModule;
