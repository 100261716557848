export const GET_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP =
  'GET_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP';
export const GET_UPDATERXTICKETPARALLELMODULE_SHOULDTHROTTLE =
  'GET_UPDATERXTICKETPARALLELMODULE_SHOULDTHROTTLE';
export const GET_UPDATERXTICKETPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_UPDATERXTICKETPARALLELMODULE_SHOULDSHIFTLRU';
export const MUTATE_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP =
  'MUTATE_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP';
export const MUTATE_UPDATERXTICKETPARALLELMODULE_SHIFTLRU =
  'MUTATE_UPDATERXTICKETPARALLELMODULE_SHIFTLRU';
export const MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING =
  'MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING';
export const MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE =
  'MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE';
export const MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR =
  'MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR';
export const ACTION_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP =
  'ACTION_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP';
export const ACTION_UPDATERXTICKETPARALLELMODULE_PENDING =
  'ACTION_UPDATERXTICKETPARALLELMODULE_PENDING';
export const ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSE =
  'ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSE';
export const ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR =
  'ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR';
export const UPDATERXTICKETPARALLELMODULE = 'updateRXTicketParallelModule';
export const UPDATERXTICKETPARALLELMODULE_MAXPARALLEL = 5;
export const UPDATERXTICKETPARALLELMODULE_MAXENTRIES = 15;
export const NS_GET_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP = `${UPDATERXTICKETPARALLELMODULE}/${GET_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP}`;
export const NS_GET_UPDATERXTICKETPARALLELMODULE_SHOULDTHROTTLE = `${UPDATERXTICKETPARALLELMODULE}/${GET_UPDATERXTICKETPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_UPDATERXTICKETPARALLELMODULE_SHOULDSHIFTLRU = `${UPDATERXTICKETPARALLELMODULE}/${GET_UPDATERXTICKETPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_MUTATE_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP = `${UPDATERXTICKETPARALLELMODULE}/${MUTATE_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP}`;
export const NS_MUTATE_UPDATERXTICKETPARALLELMODULE_SHIFTLRU = `${UPDATERXTICKETPARALLELMODULE}/${MUTATE_UPDATERXTICKETPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING = `${UPDATERXTICKETPARALLELMODULE}/${MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING}`;
export const NS_MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE = `${UPDATERXTICKETPARALLELMODULE}/${MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE}`;
export const NS_MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR = `${UPDATERXTICKETPARALLELMODULE}/${MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR}`;
export const NS_ACTION_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP = `${UPDATERXTICKETPARALLELMODULE}/${ACTION_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP}`;
export const NS_ACTION_UPDATERXTICKETPARALLELMODULE_PENDING = `${UPDATERXTICKETPARALLELMODULE}/${ACTION_UPDATERXTICKETPARALLELMODULE_PENDING}`;
export const NS_ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSE = `${UPDATERXTICKETPARALLELMODULE}/${ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSE}`;
export const NS_ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR = `${UPDATERXTICKETPARALLELMODULE}/${ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR}`;
