export const GET_AUTH_PENDING = 'GET_AUTH_PENDING';
export const GET_AUTH_AUTHENTICATED = 'GET_AUTH_AUTHENTICATED';
export const GET_AUTH_AUTHENTICATION_ERROR = 'GET_AUTH_AUTHENTICATION_ERROR';
export const GET_AUTH_DH_SSO_AUTHENTICATED = 'GET_AUTH_DH_SSO_AUTHENTICATED';
export const GET_AUTH_DH_SSO_AUTHENTICATION_ERROR =
  'GET_AUTH_DH_SSO_AUTHENTICATION_ERROR';
export const MUTATE_AUTH_STATE = 'MUTATE_AUTH_STATE';
export const MUTATE_AUTH_PENDING = 'MUTATE_AUTH_PENDING';
export const MUTATE_AUTH_FULFILLED = 'MUTATE_AUTH_FULFILLED';
export const MUTATE_AUTH_REJECTED = 'MUTATE_AUTH_REJECTED';
export const MUTATE_AUTH_DH_SSO_FULFILLED = 'MUTATE_AUTH_DH_SSO_FULFILLED';
export const MUTATE_AUTH_DH_SSO_REJECTED = 'MUTATE_AUTH_DH_SSO_REJECTED';
export const AUTH_PENDING = 'AUTH_PENDING';
export const AUTH_FULFILLED = 'AUTH_FULFILLED';
export const AUTH_REJECTED = 'AUTH_REJECTED';

export const AUTHMODULE = 'authModule';
export const NS_GET_AUTH_PENDING = `${AUTHMODULE}/${GET_AUTH_PENDING}`;
export const NS_GET_AUTH_AUTHENTICATED = `${AUTHMODULE}/${GET_AUTH_AUTHENTICATED}`;
export const NS_GET_AUTH_AUTHENTICATION_ERROR = `${AUTHMODULE}/${GET_AUTH_AUTHENTICATION_ERROR}`;
export const NS_GET_AUTH_DH_SSO_AUTHENTICATED = `${AUTHMODULE}/${GET_AUTH_DH_SSO_AUTHENTICATED}`;
export const NS_GET_AUTH_DH_SSO_AUTHENTICATION_ERROR = `${AUTHMODULE}/${GET_AUTH_DH_SSO_AUTHENTICATION_ERROR}`;
export const NS_MUTATE_AUTH_STATE = `${AUTHMODULE}/${MUTATE_AUTH_STATE}`;
export const NS_MUTATE_AUTH_PENDING = `${AUTHMODULE}/${MUTATE_AUTH_PENDING}`;
export const NS_MUTATE_AUTH_FULFILLED = `${AUTHMODULE}/${MUTATE_AUTH_FULFILLED}`;
export const NS_MUTATE_AUTH_REJECTED = `${AUTHMODULE}/${MUTATE_AUTH_REJECTED}`;
export const NS_MUTATE_AUTH_DH_SSO_FULFILLED = `${AUTHMODULE}/${MUTATE_AUTH_DH_SSO_FULFILLED}`;
export const NS_MUTATE_AUTH_DH_SSO_REJECTED = `${AUTHMODULE}/${MUTATE_AUTH_DH_SSO_REJECTED}`;
export const NS_AUTH_PENDING = `${AUTHMODULE}/${AUTH_PENDING}`;
export const NS_AUTH_FULFILLED = `${AUTHMODULE}/${AUTH_FULFILLED}`;
export const NS_AUTH_REJECTED = `${AUTHMODULE}/${AUTH_REJECTED}`;
