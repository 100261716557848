export const GET_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION =
  'GET_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION';
export const MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION =
  'MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION';
export const ACTION_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION =
  'ACTION_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION';

export const HANDLEQUERYPARAMSMODULE = 'handleQueryParamsModule';
export const NS_GET_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION = `${HANDLEQUERYPARAMSMODULE}/${GET_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION}`;
export const NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION = `${HANDLEQUERYPARAMSMODULE}/${MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION}`;
export const NS_ACTION_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION = `${HANDLEQUERYPARAMSMODULE}/${ACTION_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION}`;
