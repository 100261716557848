/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  actionChannel,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from '@redux-saga/core/effects';
import { SAGATASKSMODULE } from '../../modules';
import { MUTATE_SAGATASKS_BLOCKINGVARS } from '../../modules/sagaTasks/treekeys';

const SAGA_MUTATE_SAGATASKS_BLOCKINGVARS = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_BLOCKINGVARS}`;

const UNBLOCK_TAKE_LATEST_CANCEL_REVERT_ACTION =
  'UNBLOCK_TAKE_LATEST_CANCEL_REVERT_ACTION';
const BLOCKING_VAR = 'BLOCKING_VAR';

/**
 * stash - stashes state before running task
 * pop - pops prev state if task cancelled
 * task - task to run
 * taskPattern - pattern to take on
 * taskName - unique task name registered in the sagaTaskModule, uniqueness guarantees no conflict with other tasks
 * trackTaskAction - mutation for putting task into sagaTasksModule
 */
export interface CancelRevertConfig {
  stash: () => any;
  pop: () => any;
  task: (...args: any) => any;
  taskPattern: string;
  taskName: string;
  trackTaskAction: string;
}

const isValid = (cancelRevertConfig: CancelRevertConfig) =>
  cancelRevertConfig?.taskPattern &&
  cancelRevertConfig?.taskName &&
  cancelRevertConfig?.trackTaskAction;

export function* putBlock(
  cancelRevertConfig: CancelRevertConfig,
  block: boolean
): any {
  const BLOCKING_VAR_TASK_NAME = `${BLOCKING_VAR}_${cancelRevertConfig.taskName}`;
  const blockingVars: any = {};
  blockingVars[BLOCKING_VAR_TASK_NAME] = block;
  yield put({
    type: SAGA_MUTATE_SAGATASKS_BLOCKINGVARS,
    blockingVars,
  });
}

export function* cancelHandlerTask(
  cancelRevertConfig: CancelRevertConfig,
  payload: any,
  unblockChannel: any
): any {
  try {
    if (payload) {
      yield call(cancelRevertConfig.task, payload);
    } else {
      yield call(cancelRevertConfig.task);
    }
  } finally {
    if (yield cancelled()) {
      // pops prev state
      yield* cancelRevertConfig.pop();
      yield* putBlock(cancelRevertConfig, false);
      // unblocks after popping
      yield put(unblockChannel, 'unblock');
    } else {
      yield* putBlock(cancelRevertConfig, false);
    }
  }
}

export function* createTask(
  cancelRevertConfig: CancelRevertConfig,
  payload: any,
  unblockChannel: any
): any {
  // stashes prev state
  yield call(cancelRevertConfig.stash);
  const task = yield fork(
    cancelHandlerTask,
    cancelRevertConfig,
    payload,
    unblockChannel
  );
  // puts task into sagaTasksModule
  const putTaskAction: any = {
    type: cancelRevertConfig.trackTaskAction,
  };
  putTaskAction[cancelRevertConfig.taskName] = task;
  yield put(putTaskAction);
}

export function* cancelPrevTask(cancelRevertPayload: CancelRevertConfig): any {
  const task = yield select(
    (s) => s?.sagaTasksModule[cancelRevertPayload.taskName]
  );
  if (task && task.isRunning()) {
    yield cancel(task);
  }
}

/**
 * Wraps a task allowing for its state to revert back to the previous state if cancelled due to either cancel request or
 * another task requested. This should only be called with a task that runs off a takeLatest pattern.
 *
 * Blocking logic: There was an issue where a task runs before its previous one finishes then the previous task may not
 * cancel before the current instance starts. Sometimes, takeLatestCancelRevert executes before the finally block of
 * cancelHandlerTask. To workaround this, a blocking var and blocking channel is introduced.
 *
 * Scenario where task one is cancelled before task two starts:
 *  1. set a blocking var 2. continue running task instance one 3. task instance two is requested before one finishes 4.
 *  instance one is cancelled -> instance one reverts state -> puts blocking var as false 5. instance two runs
 *
 * Scenario where task one is NOT cancelled before task two starts WITH workaround:
 *  1. set a blocking var 2. continue running task instance one 3. task instance two is requested before one finishes ->
 *  task instance two is stopped at the blocking channel 4. instance one is cancelled -> instance one reverts state ->
 *  puts into blocking channel 5. instance two continues
 *
 * @param cancelRevertConfig
 */
export function* takeLatestCancelRevert(
  cancelRevertConfig: CancelRevertConfig
): any {
  if (isValid(cancelRevertConfig)) {
    const unblockChannel = yield actionChannel(
      `${UNBLOCK_TAKE_LATEST_CANCEL_REVERT_ACTION}_${cancelRevertConfig.taskName}`
    );
    while (true) {
      const payload = yield take(cancelRevertConfig.taskPattern);
      yield call(cancelPrevTask, cancelRevertConfig);
      const BLOCKING_VAR_TASK_NAME = `${BLOCKING_VAR}_${cancelRevertConfig.taskName}`;
      // waits for prev task to cancel if blocked
      const blocked = yield select(
        (s) => s?.sagaTasksModule?.blockingVars[BLOCKING_VAR_TASK_NAME]
      );
      if (blocked) {
        yield take(unblockChannel);
      }
      // puts a blocking var to ensure blocked for new tasks until prev task cancels
      yield call(putBlock, cancelRevertConfig, true);
      yield* createTask(cancelRevertConfig, payload, unblockChannel);
    }
  }
}
