/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, select, takeLatest } from '@redux-saga/core/effects';
import * as tkURTP from '@/store/modules/updateRXTicketParallelModule/treekeys';
import {
  RXTicketInfo,
  TroubleReport,
} from '../modules/getC360PendingOrdersAndRXTicketInfosParallelModule/types';
import { updateRXTicketParallel } from './watchUpdateRXTicketParallel';
import { UpdateRXTicketParallelMap } from '../modules/updateRXTicketParallelModule/types';

const updateTRWithCancelInfo = (troubleReport: TroubleReport | undefined) => {
  if (troubleReport) {
    troubleReport.troubleReportStatus = 'CAN';
    troubleReport.canCancel = false;
    troubleReport.canReschedule = false;
    troubleReport.isPast = true;
    troubleReport.isUpcoming = false;
  }
};

export function* updateRXTicketSubtask(updateRXTicketPayload: any): any {
  yield call(updateRXTicketParallel, updateRXTicketPayload);
  const nativeTicketNum = updateRXTicketPayload?.payload?.nativeTicketNum;
  const urtpMap: UpdateRXTicketParallelMap = yield select(
    (s) => s?.[tkURTP.UPDATERXTICKETPARALLELMODULE]?.updateRXTicketParallelMap
  );
  const respType: string | undefined =
    urtpMap[nativeTicketNum]?.response?.updateTicketResponse?.apiResponse?.type;
  if (respType) {
    const tiWithNativeTicketNum =
      updateRXTicketPayload?.payload?.ticketInfos?.find(
        (ticketInfo: RXTicketInfo) =>
          ticketInfo?.troubleReports?.find(
            (troubleReport: TroubleReport) =>
              troubleReport?.nativeTroubleReportIdentifier === nativeTicketNum
          )
      );
    const trWithNativeTicketNum: TroubleReport | undefined =
      tiWithNativeTicketNum?.troubleReports.find(
        (troubleReport: TroubleReport) =>
          troubleReport.nativeTroubleReportIdentifier === nativeTicketNum
      );
    updateTRWithCancelInfo(trWithNativeTicketNum);
  }
}

export function* wmtCancelUpdateRXTicket(updateTicketSagaPayload: any): any {
  if (updateTicketSagaPayload?.payload?.nativeTicketNum) {
    yield call(updateRXTicketSubtask, updateTicketSagaPayload);
  }
}

export function* watchWMTCancelUpdateRXTicket(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, wmtCancelUpdateRXTicket);
}

export default watchWMTCancelUpdateRXTicket;
