/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { apply, call, put, takeLatest } from '@redux-saga/core/effects';
import * as tk from '../modules/handleQueryParamsModule/treekeys';
import { useQueryParamsSTSSWBusinessChain } from './businessChain/queryParams/queryParamsSTSSWBusinessChain';
import {
  QueryParamsRequest,
  QueryParamsResponse,
} from './businessChain/queryParams/queryParamsTypes';
import { HandleQueryParamsAction } from '../modules/handleQueryParamsModule/types';

export function* handleQueryParamsSTSSW(queryParamsPayload: any): any {
  const handleQueryParamsAction: HandleQueryParamsAction =
    HandleQueryParamsAction.PENDING;
  yield put({
    type: tk.NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION,
    handleQueryParamsAction,
  });
  yield call(() => Promise.resolve());
  const queryParamsRequest: QueryParamsRequest = {
    queryParams: {
      ...queryParamsPayload?.payload,
    },
  };
  const queryParamsResponse: QueryParamsResponse = {
    handleQueryParamsAction,
  };
  const queryParamsSTSSWBC = useQueryParamsSTSSWBusinessChain();
  yield apply(queryParamsSTSSWBC, queryParamsSTSSWBC.chainBusinessLogic, [
    queryParamsRequest,
    queryParamsResponse,
  ]);
  yield put({
    type: tk.NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION,
    handleQueryParamsAction: queryParamsResponse.handleQueryParamsAction,
  });
  if (
    queryParamsResponse.handleQueryParamsAction ===
    HandleQueryParamsAction.PENDING
  ) {
    yield put({
      type: tk.NS_MUTATE_HANDLEQUERYPARAMSMODULE_HANDLEQUERYPARAMSACTION,
      handleQueryParamsAction: HandleQueryParamsAction.GOTO_STSSELECTWTN,
    });
  }
}

export function* watchHandleQueryParamsSTSSW(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, handleQueryParamsSTSSW);
}

export default watchHandleQueryParamsSTSSW;
