/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGCARTP from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule/treekeys';
import {
  GetC360PendingOrdersAndRXTicketInfosParallelMap,
  TroubleReport,
  RXTicketInfo,
} from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule/types';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';

class QueryParamsWMTUTHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getTRInMap = (
    getC360PendingOrdersAndRXTicketInfosParallelMap: GetC360PendingOrdersAndRXTicketInfosParallelMap,
    numKey: string,
    nativeTicketNum: string
  ): TroubleReport | undefined =>
    getC360PendingOrdersAndRXTicketInfosParallelMap?.[
      numKey
    ]?.c360PendingOrdersAndRXTicketInfos?.combinedC360AndRXResponse?.ticketInfos
      ?.find((ticketInfo: RXTicketInfo) =>
        ticketInfo?.troubleReports?.find(
          (troubleReport: TroubleReport) =>
            troubleReport?.nativeTroubleReportIdentifier === nativeTicketNum
        )
      )
      ?.troubleReports?.find(
        (troubleReport: TroubleReport) =>
          troubleReport?.nativeTroubleReportIdentifier === nativeTicketNum
      );

  getActionBasedOnRules = (
    getC360PendingOrdersAndRXTicketInfosParallelMap: GetC360PendingOrdersAndRXTicketInfosParallelMap,
    num: string,
    nativeTicketNum: string
  ): HandleQueryParamsAction => {
    const trInMap = this.getTRInMap(
      getC360PendingOrdersAndRXTicketInfosParallelMap,
      num,
      nativeTicketNum
    );
    if (!trInMap?.canReschedule && !trInMap?.canCancel) {
      return HandleQueryParamsAction.GOTO_WMTUPDATETICKETNOTCANCELNOTRESCHEDULE;
    }
    if (!trInMap?.canReschedule) {
      return HandleQueryParamsAction.GOTO_WMTUPDATETICKETNOTRESCHEDULE;
    }
    if (!trInMap?.canCancel) {
      return HandleQueryParamsAction.GOTO_WMTUPDATETICKETNOTCANCEL;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const num: string =
      queryParams?.accountNumber ||
      queryParams?.orderNumberOrTicketNumber ||
      '';
    const nativeTicketNum: string = queryParams?.ticketNumber || '';
    const getC360PendingOrdersAndRXTicketInfosParallelMap: GetC360PendingOrdersAndRXTicketInfosParallelMap =
      yield select(
        (s) =>
          s?.[tkGCARTP.GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE]
            ?.getC360PendingOrdersAndRXTicketInfosParallelMap
      );
    response.handleQueryParamsAction = this.getActionBasedOnRules(
      getC360PendingOrdersAndRXTicketInfosParallelMap,
      num,
      nativeTicketNum
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsWMTUTHandler;

export const useQueryParamsWMTUTHandler = (): QueryParamsWMTUTHandler => {
  if (!qp) {
    qp = new QueryParamsWMTUTHandler();
  }
  return qp;
};
