/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  GetRXAppointmentInfosParallelState,
  GetRXAppointmentInfosState,
} from './types';
import * as treekeys from './treekeys';

export const data: GetRXAppointmentInfosParallelState = {
  getRXAppointmentInfosParallelMap: {},
  shouldThrottle: false,
  lruKeyArr: [],
  shouldShiftLRU: false,
};
export const getters: GetterTree<GetRXAppointmentInfosParallelState, any> = {
  [treekeys.GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP]:
    (state) => state.getRXAppointmentInfosParallelMap,
  [treekeys.GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDTHROTTLE]: (state) =>
    state.shouldThrottle,
  [treekeys.GET_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHOULDSHIFTLRU]: (state) =>
    state.shouldShiftLRU,
};
export const mutations: MutationTree<GetRXAppointmentInfosParallelState> = {
  [treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP]:
    (state, { getRXAppointmentInfosParallelMap }) => {
      state.getRXAppointmentInfosParallelMap = getRXAppointmentInfosParallelMap;
    },
  [treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHIFTLRU]: (state) => {
    delete state.getRXAppointmentInfosParallelMap[
      state.lruKeyArr.shift() || ''
    ];
    state.shouldThrottle =
      Object.values(state.getRXAppointmentInfosParallelMap).filter(
        (getRXAppointmentInfosState: GetRXAppointmentInfosState) =>
          getRXAppointmentInfosState.pending
      ).length >= treekeys.GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING]: (
    state,
    { key }
  ) => {
    state.getRXAppointmentInfosParallelMap[key] ? 0 : state.lruKeyArr.push(key);
    state.shouldShiftLRU =
      state.lruKeyArr.length >
      treekeys.GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXENTRIES;
    state.getRXAppointmentInfosParallelMap[key] =
      state.getRXAppointmentInfosParallelMap[key] || {};
    state.getRXAppointmentInfosParallelMap[key].pending = true;
    state.shouldThrottle =
      Object.values(state.getRXAppointmentInfosParallelMap).filter(
        (getRXAppointmentInfosState: GetRXAppointmentInfosState) =>
          getRXAppointmentInfosState.pending
      ).length >= treekeys.GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS]: (
    state,
    { key, rxAppointmentInfos }
  ) => {
    state.getRXAppointmentInfosParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getRXAppointmentInfosParallelMap).filter(
        (getRXAppointmentInfosState: GetRXAppointmentInfosState) =>
          getRXAppointmentInfosState.pending
      ).length >= treekeys.GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXPARALLEL;
    state.getRXAppointmentInfosParallelMap[key].rxAppointmentInfos =
      rxAppointmentInfos;
    state.getRXAppointmentInfosParallelMap[key].rxAppointmentInfosError =
      undefined;
  },
  [treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR]:
    (state, { key, rxAppointmentInfosError }) => {
      state.getRXAppointmentInfosParallelMap[key].pending = false;
      state.shouldThrottle =
        Object.values(state.getRXAppointmentInfosParallelMap).filter(
          (getRXAppointmentInfosState: GetRXAppointmentInfosState) =>
            getRXAppointmentInfosState.pending
        ).length >= treekeys.GETRXAPPOINTMENTINFOSPARALLELMODULE_MAXPARALLEL;
      state.getRXAppointmentInfosParallelMap[key].rxAppointmentInfos = {};
      state.getRXAppointmentInfosParallelMap[key].rxAppointmentInfosError =
        rxAppointmentInfosError;
    },
};
export const actions: ActionTree<GetRXAppointmentInfosParallelState, any> = {
  [treekeys.ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP]:
    ({ commit }, { getRXAppointmentInfosParallelMap }) => {
      commit(
        treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_GETRXAPPOINTMENTINFOSPARALLELMAP,
        {
          getRXAppointmentInfosParallelMap,
        }
      );
    },
  [treekeys.ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING]: (
    { commit },
    { key }
  ) => {
    commit(treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING, {
      key,
    });
  },
  [treekeys.ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS]: (
    { commit },
    { key, rxAppointmentInfos }
  ) => {
    commit(
      treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS,
      {
        key,
        rxAppointmentInfos,
      }
    );
  },
  [treekeys.ACTION_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR]:
    ({ commit }, { key, rxAppointmentInfosError }) => {
      commit(
        treekeys.MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR,
        {
          key,
          rxAppointmentInfosError,
        }
      );
    },
};

const getRXAppointmentInfosParallelModule: Module<
  GetRXAppointmentInfosParallelState,
  any
> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default getRXAppointmentInfosParallelModule;
