/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGCPOP from '@/store/modules/getC360PendingOrdersParallelModule/treekeys';
import { GetC360PendingOrdersParallelMap } from '@/store/modules/getC360PendingOrdersParallelModule/types';
import { Orders } from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule/types';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { getC360PendingOrdersParallel } from '../../watchGetC360PendingOrdersParallel';

class QueryParamsSTSPOHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  checkIfValidPendingOrder = (
    getC360PendingOrdersParallelMap: GetC360PendingOrdersParallelMap,
    acctNumOrEmail: string
  ): Orders | undefined =>
    getC360PendingOrdersParallelMap?.[
      acctNumOrEmail
    ]?.c360PendingOrders?.inflightOrders?.find(
      (value) =>
        value.orderType?.toLocaleLowerCase() === 'n' ||
        value.orderType?.toLocaleLowerCase() === 'ni' ||
        value.orderType?.toLocaleLowerCase() === 'new install' ||
        value.orderType?.toLocaleLowerCase() === 'newinstall' ||
        value.orderCommittedDueDate?.split('T')[0] ===
          new Date().toISOString().split('T')[0] ||
        value.orderDueDate?.split('T')[0] ===
          new Date().toISOString().split('T')[0]
    );

  getActionBasedOnRulesC360PO = (
    getC360PendingOrdersParallelMap: GetC360PendingOrdersParallelMap,
    acctNumOrEmail: string
  ): HandleQueryParamsAction => {
    const isValidPendingOrder = this.checkIfValidPendingOrder(
      getC360PendingOrdersParallelMap,
      acctNumOrEmail
    );
    if (
      (getC360PendingOrdersParallelMap?.[acctNumOrEmail]?.c360PendingOrders
        ?.inflightOrders?.length || 0) >= 1 &&
      isValidPendingOrder
    ) {
      return HandleQueryParamsAction.GOTO_STSPENDINGORDER;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const getC360PendingOrdersParallelMap: GetC360PendingOrdersParallelMap =
      yield select(
        (s) =>
          s?.[tkGCPOP.GETC360PENDINGORDERSPARALLELMODULE]
            ?.getC360PendingOrdersParallelMap
      );
    if (
      !getC360PendingOrdersParallelMap?.[acctNumOrEmail]?.c360PendingOrders ||
      !Object.keys(
        getC360PendingOrdersParallelMap?.[acctNumOrEmail]?.c360PendingOrders
      ).length
    ) {
      yield call(getC360PendingOrdersParallel, { payload: acctNumOrEmail });
    }

    response.handleQueryParamsAction = this.getActionBasedOnRulesC360PO(
      getC360PendingOrdersParallelMap,
      acctNumOrEmail.replaceAll('*', '')
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSPOHandler;

export const useQueryParamsSTSPOHandler = (): QueryParamsSTSPOHandler => {
  if (!qp) {
    qp = new QueryParamsSTSPOHandler();
  }
  return qp;
};
