import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import getC360AcctsMockResp from '../../../assets/json/apiMockResp/getC360Accts.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 500;
const MOCK_TIME_BOUND_MAX = 2000;

export function* getC360Accts(
  accountNumber: string,
  email: string,
  zipCode: string,
  isAuth: boolean
): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: getC360AcctsMockResp };
  }
  const pathName = window.location.pathname;

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  let API_ENDPOINT = isAuth
    ? apiEndpts.AUTH_WMT_GET_ACCOUNTS
    : apiEndpts.WMT_GET_C360_ACCTS;
  if (pathName && pathName.indexOf('sts') >= 0) {
    API_ENDPOINT = isAuth
      ? apiEndpts.AUTH_STS_GET_ACCOUNTS
      : apiEndpts.STS_GET_C360_ACCTS;
  }
  if (accountNumber) {
    const accNumber = accountNumber.toUpperCase();
    if (zipCode) {
      return yield call(
        axios.get,
        `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?accountNumber=${accNumber}&zipCode=${zipCode}`,
        {
          withCredentials: true,
          headers: {
            ApiKey: encryptedApiKey,
          },
          /* timeout: 60000, */
        }
      );
    }
    return yield call(
      axios.get,
      `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?accountNumber=${accNumber}`,
      {
        withCredentials: true,
        headers: {
          ApiKey: encryptedApiKey,
        },
        /* timeout: 60000, */
      }
    );
  }
  if (zipCode) {
    return yield call(
      axios.get,
      `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?email=${email}&zipCode=${zipCode}`,
      {
        withCredentials: true,
        headers: {
          ApiKey: encryptedApiKey,
        },
        /* timeout: 60000, */
      }
    );
  }
  return yield call(
    axios.get,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}?email=${email}`,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
