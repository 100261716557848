export const GET_SAGATASKS_GETAZUREATTRS_TASK =
  'GET_SAGATASKS_GETAZUREATTRS_TASK';
export const GET_SAGATASKS_BLOCKINGVARS = 'GET_SAGATASKS_BLOCKINGVARS';
export const GET_SAGATASKS_GETC360ACCTS_TASK =
  'GET_SAGATASKS_GETC360ACCTS_TASK';
export const GET_SAGATASKS_GETC360PENDINGORDERS_TASK =
  'GET_SAGATASKS_GETC360PENDINGORDERS_TASK';
export const GET_SAGATASKS_GETRXAPPTS_TASK = 'GET_SAGATASKS_GETRXAPPTS_TASK';
export const GET_SAGATASKS_HANDLEAUTH_TASK = 'GET_SAGATASKS_HANDLEAUTH_TASK';
export const GET_SAGATASKS_HANDLEPROGRESBAR_TASK =
  'GET_SAGATASKS_HANDLEPROGRESSBAR_TASK';
export const GET_SAGATASKS_HANDLEPROGRESSSPINNER_TASK =
  'GET_SAGATASKS_HANDLEPROGRESSSPINNER_TASK';

export const GET_SAGATASKSMODULE_GETC360ACCTSTASK =
  'GET_SAGATASKSMODULE_GETC360ACCTSTASK';
export const GET_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK =
  'GET_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK';
export const GET_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK =
  'GET_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK';
export const GET_SAGATASKSMODULE_UPDATERXTICKETTASK =
  'GET_SAGATASKSMODULE_UPDATERXTICKETTASK';
export const GET_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK =
  'GET_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK';
export const GET_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK =
  'GET_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK';
export const GET_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK =
  'GET_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK';
export const GET_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK =
  'GET_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK';
export const GET_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK =
  'GET_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK';
export const GET_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK =
  'GET_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK';
export const MUTATE_SAGATASKSMODULE_GETC360ACCTSTASK =
  'MUTATE_SAGATASKSMODULE_GETC360ACCTSTASK';
export const MUTATE_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK =
  'MUTATE_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK';
export const MUTATE_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK =
  'MUTATE_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK';
export const MUTATE_SAGATASKSMODULE_UPDATERXTICKETTASK =
  'MUTATE_SAGATASKSMODULE_UPDATERXTICKETTASK';
export const MUTATE_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK =
  'MUTATE_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK';
export const MUTATE_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK =
  'MUTATE_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK';
export const MUTATE_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK =
  'MUTATE_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK';
export const MUTATE_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK =
  'MUTATE_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK';
export const MUTATE_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK =
  'MUTATE_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK';
export const MUTATE_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK =
  'MUTATE_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK';
export const MUTATE_SAGATASKS_BLOCKINGVARS = 'MUTATE_SAGATASKS_BLOCKINGVARS';
export const MUTATE_SAGATASKS_GETAZUREATTRS_TASK =
  'MUTATE_SAGATASKS_GETAZUREATTRS_TASK';
export const MUTATE_SAGATASKS_GETC360ACCTS_TASK =
  'MUTATE_SAGATASKS_GETC360ACCTS_TASK';
export const MUTATE_SAGATASKS_GETC360PENDINGORDERS_TASK =
  'MUTATE_SAGATASKS_GETC360PENDINGORDERS_TASK';
export const MUTATE_SAGATASKS_GETRXAPPTS_TASK =
  'MUTATE_SAGATASKS_GETRXAPPTS_TASK';
export const MUTATE_SAGATASKS_HANDLEAUTH_TASK =
  'MUTATE_SAGATASKS_HANDLEAUTH_TASK';
export const MUTATE_SAGATASKS_HANDLEPROGRESSBAR_TASK =
  'MUTATE_SAGATASKS_HANDLEPROGRESSBAR_TASK';
export const MUTATE_SAGATASKS_HANDLEPROGRESSSPINNER_TASK =
  'MUTATE_SAGATASKS_HANDLEPROGRESSSPINNER_TASK';
export const SAGATASKS_GETAZUREATTRS_TASK = 'SAGATASKS_GETAZUREATTRS_TASK';
export const SAGATASKS_GETC360ACCTS_TASK = 'SAGATASKS_GETC360ACCTS_TASK';
export const SAGATASKS_GETC360PENDINGORDERS_TASK =
  'SAGATASKS_GETC360PENDINGORDERS_TASK';
export const SAGATASKS_GETRXAPPTS_TASK = 'SAGATASKS_GETRXAPPTS_TASK';
export const SAGATASKS_HANDLEAUTH_TASK = 'SAGATASKS_HANDLEAUTH_TASK';
export const ACTION_SAGATASKSMODULE_GETC360ACCTSTASK =
  'ACTION_SAGATASKSMODULE_GETC360ACCTSTASK';
export const ACTION_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK =
  'ACTION_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK';
export const ACTION_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK =
  'ACTION_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK';
export const ACTION_SAGATASKSMODULE_UPDATERXTICKETTASK =
  'ACTION_SAGATASKSMODULE_UPDATERXTICKETTASK';
export const ACTION_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK =
  'ACTION_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK';
export const ACTION_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK =
  'ACTION_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK';
export const ACTION_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK =
  'ACTION_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK';
export const ACTION_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK =
  'ACTION_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK';
export const ACTION_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK =
  'ACTION_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK';

export const SAGATASKSMODULE = 'sagaTasksModule';
export const NS_GET_SAGATASKS_GETAZUREATTRS_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_GETAZUREATTRS_TASK}`;
export const NS_GET_SAGATASKS_BLOCKINGVARS = `${SAGATASKSMODULE}/${GET_SAGATASKS_BLOCKINGVARS}`;
export const NS_GET_SAGATASKS_GETC360ACCTS_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_GETC360ACCTS_TASK}`;
export const NS_GET_SAGATASKS_GETC360PENDINGORDERS_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_GETC360PENDINGORDERS_TASK}`;
export const NS_GET_SAGATASKS_GETRXAPPTS_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_GETRXAPPTS_TASK}`;
export const NS_GET_SAGATASKS_HANDLEAUTH_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_HANDLEAUTH_TASK}`;
export const NS_GET_SAGATASKS_HANDLEPROGRESBAR_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_HANDLEPROGRESBAR_TASK}`;
export const NS_GET_SAGATASKS_HANDLEPROGRESSPINNER_TASK = `${SAGATASKSMODULE}/${GET_SAGATASKS_HANDLEPROGRESSSPINNER_TASK}`;

export const NS_GET_SAGATASKSMODULE_GETC360ACCTSTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_GETC360ACCTSTASK}`;
export const NS_GET_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK}`;
export const NS_GET_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK}`;
export const NS_GET_SAGATASKSMODULE_UPDATERXTICKETTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_UPDATERXTICKETTASK}`;
export const NS_GET_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK}`;
export const NS_GET_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK}`;
export const NS_GET_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK}`;
export const NS_GET_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK}`;
export const NS_GET_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK = `${SAGATASKSMODULE}/${GET_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK}`;
export const NS_MUTATE_SAGATASKSMODULE_GETC360ACCTSTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_GETC360ACCTSTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_UPDATERXTICKETTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_UPDATERXTICKETTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_STSLANDINGGETC360ACCTSTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK}`;
export const NS_MUTATE_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK}`;

export const NS_MUTATE_SAGATASKS_BLOCKINGVARS = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_BLOCKINGVARS}`;
export const NS_MUTATE_SAGATASKS_GETAZUREATTRS_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_GETAZUREATTRS_TASK}`;
export const NS_MUTATE_SAGATASKS_GETC360ACCTS_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_GETC360ACCTS_TASK}`;
export const NS_MUTATE_SAGATASKS_GETC360PENDINGORDERS_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_GETC360PENDINGORDERS_TASK}`;
export const NS_MUTATE_SAGATASKS_GETRXAPPTS_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_GETRXAPPTS_TASK}`;
export const NS_MUTATE_SAGATASKS_HANDLEAUTH_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_HANDLEAUTH_TASK}`;
export const NS_MUTATE_SAGATASKS_HANDLEPROGRESSBAR_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_HANDLEPROGRESSBAR_TASK}`;
export const NS_MUTATE_SAGATASKS_HANDLEPROGRESSSPINNER_TASK = `${SAGATASKSMODULE}/${MUTATE_SAGATASKS_HANDLEPROGRESSSPINNER_TASK}`;
export const NS_SAGATASKS_GETAZUREATTRS_TASK = `${SAGATASKSMODULE}/${SAGATASKS_GETAZUREATTRS_TASK}`;
export const NS_SAGATASKS_GETC360ACCTS_TASK = `${SAGATASKSMODULE}/${SAGATASKS_GETC360ACCTS_TASK}`;
export const NS_SAGATASKS_GETC360PENDINGORDERS_TASK = `${SAGATASKSMODULE}/${SAGATASKS_GETC360PENDINGORDERS_TASK}`;
export const NS_SAGATASKS_GETRXAPPTS_TASK = `${SAGATASKSMODULE}/${SAGATASKS_GETRXAPPTS_TASK}`;
export const NS_SAGATASKS_HANDLEAUTH_TASK = `${SAGATASKSMODULE}/${SAGATASKS_HANDLEAUTH_TASK}`;

export const NS_ACTION_SAGATASKSMODULE_GETC360ACCTSTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_GETC360ACCTSTASK}`;
export const NS_ACTION_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSTASK}`;
export const NS_ACTION_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_GETRXAPPOINTMENTINFOSTASK}`;
export const NS_ACTION_SAGATASKSMODULE_UPDATERXTICKETTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_UPDATERXTICKETTASK}`;
export const NS_ACTION_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_WMTCANCELUPDATERXTICKETTASK}`;
export const NS_ACTION_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_WMTLANDINGGETC360ACCTSTASK}`;
export const NS_ACTION_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK}`;
export const NS_ACTION_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_WMTRESCHEDULEUPDATERXTICKETTASK}`;
export const NS_ACTION_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK = `${SAGATASKSMODULE}/${ACTION_SAGATASKSMODULE_WMTRESULTSGETC360POANDRXTITASK}`;

export const K_GETAZUREATTRSTASK = 'getAzureAttrsTask';
export const K_HANDLEAUTHTASK = 'handleAuthTask';
export const K_HANDLEPROGRESSBARTASK = 'handleProgressBarTask';
export const K_HANDLEPROGRESSSPINNERTASK = 'handleProgressSpinnerTask';
export const K_GETC360ACCTSTASK = 'getC360AcctsTask';
export const K_GETC360PENDINGORDERSANDRXTICKETINFOSTASK =
  'getC360PendingOrdersAndRXTicketInfosTask';
export const K_GETRXAPPOINTMENTINFOSTASK = 'getRXAppointmentInfosTask';
export const K_UPDATERXTICKETTASK = 'updateRXTicketTask';
export const K_STSLANDINGGETC360ACCTSTASK = 'stsLandingGetC360AcctsTask';
export const K_WMTCANCELUPDATERXTICKETTASK = 'wmtCancelUpdateRXTicketTask';
export const K_WMTLANDINGGETC360ACCTSTASK = 'wmtLandingGetC360AcctsTask';
export const K_WMTRESCHEDULEGETRXAPPOINTMENTINFOSTASK =
  'wmtRescheduleGetRXAppointmentInfosTask';
export const K_WMTRESCHEDULEUPDATERXTICKETTASK =
  'wmtRescheduleUpdateRXTicketTask';
export const K_WMTRESULTSGETC360POANDRXTITASK =
  'wmtResultsGetC360POAndRXTITask';
