/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { useDataUtilsSingleton } from '@/common/utils/dataUtilsSingleton';
import { INACTIVE_STATUSES } from '@/common/strings';
import {
  GetC360AcctsParallelState,
  GetC360AcctsState,
  C360Account,
} from './types';
import * as treekeys from './treekeys';

export const data: GetC360AcctsParallelState = {
  getC360AcctsParallelMap: {},
  shouldThrottle: false,
  lruKeyArr: [],
  shouldShiftLRU: false,
  companyOwnerId: '1',
};
export const getters: GetterTree<GetC360AcctsParallelState, any> = {
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP]: (state) =>
    state.getC360AcctsParallelMap,
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_SHOULDTHROTTLE]: (state) =>
    state.shouldThrottle,
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_SHOULDSHIFTLRU]: (state) =>
    state.shouldShiftLRU,
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_ACCTNUMPOSTALCODEMAP]: (state) => {
    const resp: { [index: string]: string | undefined } = {};
    Object.values(state.getC360AcctsParallelMap).forEach(
      (getC360AcctsState: GetC360AcctsState) =>
        getC360AcctsState?.c360AccountList?.c360AccountList?.accounts?.forEach(
          (c360Account: C360Account) =>
            (resp[c360Account?.accountNumber || 'UNKNOWN'] =
              c360Account?.billingAddress?.postalCode?.slice(0, 5))
        )
    );
    return resp;
  },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_ACCTNUMACCTSTATUSMAP]: (state) => {
    const resp: { [index: string]: string | undefined } = {};
    Object.values(state.getC360AcctsParallelMap).forEach(
      (getC360AcctsState: GetC360AcctsState) =>
        getC360AcctsState?.c360AccountList?.c360AccountList?.accounts?.forEach(
          (c360Account: C360Account) =>
            (resp[c360Account?.accountNumber || 'UNKNOWN'] =
              c360Account?.accountStatus)
        )
    );
    return resp;
  },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_COMPANYOWNERID]: (state) =>
    state.companyOwnerId,
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_ACCTNUMASSOCC360ACCOUNTSMAP]: (
    state
  ) => {
    const resp: { [index: string]: C360Account[] } = {};
    Object.values(state.getC360AcctsParallelMap).forEach(
      (getC360AcctsState: GetC360AcctsState) =>
        getC360AcctsState?.c360AccountList?.c360AccountList?.accounts?.forEach(
          (c360Account: C360Account) =>
            (resp[c360Account?.accountNumber?.slice(-4) || 'UNKNOWN'] =
              getC360AcctsState?.c360AccountList?.c360AccountList?.accounts ||
              [])
        )
    );
    return resp;
  },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_POSTALCODEACCTNUMSMAP]: (state) => {
    const resp: { [index: string]: string[] } = {};
    Object.values(state.getC360AcctsParallelMap).forEach(
      (getC360AcctsState: GetC360AcctsState) =>
        getC360AcctsState?.c360AccountList?.c360AccountList?.accounts?.forEach(
          (c360Account: C360Account) => {
            resp[
              c360Account?.billingAddress?.postalCode?.slice(0, 5) || 'UNKNOWN'
            ] =
              resp[
                c360Account?.billingAddress?.postalCode?.slice(0, 5) ||
                  'UNKNOWN'
              ] || [];
            c360Account?.accountNumber
              ? resp[
                  c360Account?.billingAddress?.postalCode?.slice(0, 5) ||
                    'UNKNOWN'
                ].push(c360Account?.accountNumber)
              : 0;
          }
        )
    );
    return resp;
  },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_NUMKEYNONINACTIVEANDNONSUSPACCTSMAP]:
    (state) => {
      const resp: { [index: string]: C360Account[] } = {};
      const dataUtilsSingleton = useDataUtilsSingleton();
      Object.entries(state.getC360AcctsParallelMap).forEach((entry) => {
        resp[entry[0] || 'UNKNOWN'] =
          dataUtilsSingleton.getAcctsWithoutStatuses(
            entry[1]?.c360AccountList?.c360AccountList?.accounts,
            [...INACTIVE_STATUSES, '', undefined]
          );
      });
      return resp;
    },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_NUMKEYPOSTALCODENONINACTIVEANDNONSUSPACCTSMAPMAP]:
    (state) => {
      const resp: { [index: string]: { [index: string]: C360Account[] } } = {};
      const dataUtilsSingleton = useDataUtilsSingleton();
      Object.entries(state.getC360AcctsParallelMap).forEach((entry) => {
        resp[entry[0] || 'UNKNOWN'] = resp[entry[0] || 'UNKNOWN'] || {};
        dataUtilsSingleton
          .getAcctsWithoutStatuses(
            entry[1]?.c360AccountList?.c360AccountList?.accounts,
            [...INACTIVE_STATUSES, '', undefined]
          )
          ?.forEach((c360Account: C360Account) => {
            const a = resp[entry[0] || 'UNKNOWN'];
            const b =
              c360Account?.billingAddress?.postalCode?.slice(0, 5) || 'UNKNOWN';
            a[b] = a[b] || [];
            a[b].push(c360Account);
          });
      });
      return resp;
    },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_ACCTNUMNUMKEYMAP]: (state) => {
    const resp: { [index: string]: string } = {};
    Object.entries(state.getC360AcctsParallelMap).forEach((entry) =>
      entry[1]?.c360AccountList?.c360AccountList?.accounts?.forEach(
        (c360Account: C360Account) => {
          [resp[c360Account?.accountNumber || 'UNKNOWN']] = entry;
        }
      )
    );
    return resp;
  },
  [treekeys.GET_GETC360ACCTSPARALLELMODULE_COMPANY_ID_BY_C360ACCOUNT]:
    (state) => (accountNumber: string) => {
      let resp = '';
      Object.values(state.getC360AcctsParallelMap).forEach(
        (getC360AcctsState: GetC360AcctsState) => {
          getC360AcctsState?.c360AccountList?.c360AccountList?.accounts?.forEach(
            (c360Account: C360Account) => {
              if (
                c360Account.accountNumber?.replaceAll('*', '') === accountNumber
              ) {
                resp =
                  c360Account?.companyOwnerId === 'null' ||
                  c360Account?.companyOwnerId === null ||
                  c360Account?.companyOwnerId === undefined
                    ? '1'
                    : c360Account?.companyOwnerId;
              }
            }
          );
        }
      );
      return resp;
    },
};
export const mutations: MutationTree<GetC360AcctsParallelState> = {
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP]: (
    state,
    { getC360AcctsParallelMap }
  ) => {
    state.getC360AcctsParallelMap = getC360AcctsParallelMap;
  },
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_SHIFTLRU]: (state) => {
    delete state.getC360AcctsParallelMap[state.lruKeyArr.shift() || ''];
    state.shouldThrottle =
      Object.values(state.getC360AcctsParallelMap).filter(
        (getC360AcctsState: GetC360AcctsState) => getC360AcctsState.pending
      ).length >= treekeys.GETC360ACCTSPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_PENDING]: (state, { key }) => {
    state.getC360AcctsParallelMap[key] ? 0 : state.lruKeyArr.push(key);
    state.shouldShiftLRU =
      state.lruKeyArr.length > treekeys.GETC360ACCTSPARALLELMODULE_MAXENTRIES;
    state.getC360AcctsParallelMap[key] =
      state.getC360AcctsParallelMap[key] || {};
    state.getC360AcctsParallelMap[key].pending = true;
    state.shouldThrottle =
      Object.values(state.getC360AcctsParallelMap).filter(
        (getC360AcctsState: GetC360AcctsState) => getC360AcctsState.pending
      ).length >= treekeys.GETC360ACCTSPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST]: (
    state,
    { key, c360AccountList }
  ) => {
    state.getC360AcctsParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getC360AcctsParallelMap).filter(
        (getC360AcctsState: GetC360AcctsState) => getC360AcctsState.pending
      ).length >= treekeys.GETC360ACCTSPARALLELMODULE_MAXPARALLEL;
    state.getC360AcctsParallelMap[key].c360AccountList = c360AccountList;
    state.getC360AcctsParallelMap[key].c360AccountListError = undefined;
  },
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR]: (
    state,
    { key, c360AccountListError }
  ) => {
    state.getC360AcctsParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getC360AcctsParallelMap).filter(
        (getC360AcctsState: GetC360AcctsState) => getC360AcctsState.pending
      ).length >= treekeys.GETC360ACCTSPARALLELMODULE_MAXPARALLEL;
    state.getC360AcctsParallelMap[key].c360AccountList = {};
    state.getC360AcctsParallelMap[key].c360AccountListError =
      c360AccountListError;
  },
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID]: (
    state,
    { accountNumber }
  ) => {
    Object.values(state.getC360AcctsParallelMap).forEach(
      (getC360AcctsState: GetC360AcctsState) => {
        getC360AcctsState?.c360AccountList?.c360AccountList?.accounts?.forEach(
          (c360Account: C360Account) => {
            if (c360Account.accountNumber === accountNumber) {
              state.companyOwnerId =
                c360Account?.companyOwnerId === 'null' ||
                c360Account?.companyOwnerId === null ||
                c360Account?.companyOwnerId === undefined
                  ? '1'
                  : c360Account?.companyOwnerId;
            }
          }
        );
      }
    );
  },
  [treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID]: (
    state,
    { companyOwnerId }
  ) => {
    state.companyOwnerId = companyOwnerId;
  },
};
export const actions: ActionTree<GetC360AcctsParallelState, any> = {
  [treekeys.ACTION_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP]: (
    { commit },
    { getC360AcctsParallelMap }
  ) => {
    commit(treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_GETC360ACCTSPARALLELMAP, {
      getC360AcctsParallelMap,
    });
  },
  [treekeys.ACTION_GETC360ACCTSPARALLELMODULE_PENDING]: (
    { commit },
    { key }
  ) => {
    commit(treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_PENDING, { key });
  },
  [treekeys.ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST]: (
    { commit },
    { key, c360AccountList }
  ) => {
    commit(treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST, {
      key,
      c360AccountList,
    });
  },
  [treekeys.ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR]: (
    { commit },
    { key, c360AccountListError }
  ) => {
    commit(treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR, {
      key,
      c360AccountListError,
    });
  },
  [treekeys.ACTION_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID]: (
    { commit },
    { accountNumber }
  ) => {
    commit(treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNT_COMPANY_ID, {
      accountNumber,
    });
  },
  [treekeys.ACTION_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID]: (
    { commit },
    { companyOwnerId }
  ) => {
    commit(treekeys.MUTATE_GETC360ACCTSPARALLELMODULE_SET_COMPANY_ID, {
      companyOwnerId,
    });
  },
};

const getC360AcctsParallelModule: Module<GetC360AcctsParallelState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default getC360AcctsParallelModule;
