import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { HANDLE_AUTH } from '@/store/sagas/patterns';
import * as strings from '@/common/strings';
import { useNSProtectedSagaDispatch } from '@/store/protectedVuexSaga';
import { AUTHMODULE } from '@/store/modules/auth/treekeys';

const doBeforeEach = (to: any) => {
  if (typeof document !== 'undefined') {
    if (to?.name?.startsWith('WMT') || to?.name?.startsWith('SAM')) {
      document.title = 'My Appointments';
    } else if (to?.name?.startsWith('STS')) {
      document.title = 'CenturyLink Repair';
    } else if (to?.name?.startsWith('Tech')) {
      document.title = "Technician's Location";
    } else if (to?.name === strings.ROUTERNAME_PAGENOTFOUND) {
      document.title = 'Sorry! Page Not Found';
    }
  }
};

const base = '/digicustcare/';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/sam',
  },
  {
    path: '/home',
    name: strings.ROUTERNAME_HOME,
    component: () => import('@/views/AuthWrapper.vue'),
  },
  {
    path: '/logout',
    name: strings.ROUTERNAME_LOGOUT,
    component: () => import('@/views/Logout.vue'),
  },
  {
    path: '/stsPostLogin',
    name: strings.ROUTERNAME_STSPOSTLOGIN,
    component: () => import('@/views/STSAuthWrapper.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/wmtPostLogin',
    name: strings.ROUTERNAME_WMTPOSTLOGIN,
    component: () => import('@/views/WMTAuthWrapper.vue'),
    meta: { page: { pageType: 'sam' } },
  },
  {
    path: '/sts',
    name: strings.ROUTERNAME_STSLANDING,
    component: () => import('@/views/STSLanding.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsNoProducts',
    name: strings.ROUTERNAME_STSNOPRODUCTS,
    component: () => import('@/views/STSNoProducts.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsIFrame',
    name: strings.ROUTERNAME_STSIFRAME,
    component: () => import('@/views/STSIFrame.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsPendingOrder',
    name: strings.ROUTERNAME_STSPENDINGORDER,
    component: () => import('@/views/STSPendingOrder.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsSelectAcct',
    name: strings.ROUTERNAME_STSSELECTACCT,
    component: () => import('@/views/STSSelectAcct.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsSelectWTN',
    name: strings.ROUTERNAME_STSSELECTWTN,
    component: () => import('@/views/STSSelectWTN.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsSuspNonPay',
    name: strings.ROUTERNAME_STSSUSPNONPAY,
    component: () => import('@/views/STSSuspNonPay.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/stsSuspVac',
    name: strings.ROUTERNAME_STSSUSPVAC,
    component: () => import('@/views/STSSuspVac.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/sam',
    name: strings.ROUTERNAME_WMTLANDING,
    component: () => import('@/views/WMTLanding.vue'),
    meta: { page: { pageType: 'sam' } },
  },
  {
    path: '/wmtResults',
    name: strings.ROUTERNAME_WMTRESULTS,
    component: () => import('@/views/WMTResults.vue'),
    meta: { page: { pageType: 'sam' } },
  },
  {
    path: '/wmtSelectAcct',
    name: strings.ROUTERNAME_WMTSELECTACCT,
    component: () => import('@/views/WMTSelectAcct.vue'),
    meta: { page: { pageType: 'sam' } },
  },
  {
    path: '/wmtFindMyTech',
    name: strings.ROUTERNAME_WMTFINDMYTECH,
    component: () => import('@/views/WMTFindMyTech.vue'),
    meta: { page: { pageType: 'sam' } },
  },
  {
    path: '/stsMobileLogin',
    name: strings.ROUTERNAME_STS_MOBILE_LOGIN,
    component: () => import('@/views/STSMobileLogIn.vue'),
    meta: { page: { pageType: 'sts' } },
  },
  {
    path: '/techLocation',
    name: strings.ROUTERNAME_TECHLOCATION,
    component: () => import('@/views/AuthTechLocation.vue'),
    meta: { page: { pageType: 'sam' } },
  },
  {
    path: '/wmtLanding',
    redirect: '/sam',
  },
  {
    path: '/stsLanding',
    redirect: '/sts',
  },
  /* {
    path: '/:pathMatch(.*)*',
    name: strings.ROUTERNAME_PAGENOTFOUND,
    component: () => import('@/views/PageNotFound.vue'),
  }, */
];

const router = createRouter({
  history: createWebHistory(base),
  routes,
});

router.beforeEach(async (to: any, from: any, next: any) => {
  document.getElementById('app')?.scrollIntoView();
  doBeforeEach(to);
  const sagaDispatch = useNSProtectedSagaDispatch(AUTHMODULE);
  sagaDispatch(HANDLE_AUTH, {
    to,
    from,
  });
  next();
});

router.beforeResolve(async (to: any, from: any, next: any) => {
  next();
});

export default router;
