import { call, cancelled, put, takeLatest } from '@redux-saga/core/effects';
import * as tk from '../modules/performMobileSSOModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import { performMobileSSO as performMobileSSOApi } from './api/performMobileSSO';

export function* performMobileSSO(mobileSSOPayload: any): any {
  yield put({ type: tk.NS_MUTATE_PERFORMMOBILESSOMODULE_PENDING });
  yield put({
    type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
    progressSpinnerAppear: true,
  });
  try {
    const resp = yield call(performMobileSSOApi, {
      ...mobileSSOPayload?.payload,
    });
    if (resp?.data) {
      yield put({
        type: tk.NS_MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE,
        mobileSSOResponse: resp.data,
      });
    } else {
      yield put({
        type: tk.NS_MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR,
        mobileSSOError: resp,
      });
    }
  } catch (err) {
    yield put({
      type: tk.NS_MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR,
      mobileSSOError: err,
    });
  } finally {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: false,
    });
    if (yield cancelled()) {
      yield put({
        type: tk.NS_MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR,
        _34SEcomUserProfileError: 'SAGA_CANCELLED',
      });
    }
  }
}

export function* watchPerformMobileSSO(
  pattern: string
): Generator<any, any, any> {
  yield takeLatest(pattern, performMobileSSO);
}

export default watchPerformMobileSSO;
