export const GET_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER =
  'GET_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER';
export const GET_STSLANDINGMODULE_SELECTEDZIPCODE =
  'GET_STSLANDINGMODULE_SELECTEDZIPCODE';
export const GET_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER =
  'GET_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER';
export const GET_STSLANDINGMODULE_THROTTLED = 'GET_STSLANDINGMODULE_THROTTLED';
export const GET_STSLANDINGMODULE_SUBMITPENDING =
  'GET_STSLANDINGMODULE_SUBMITPENDING';
export const GET_STSLANDINGMODULE_CURRSTATE = 'GET_STSLANDINGMODULE_CURRSTATE';
export const GET_STSLANDINGMODULE_PREVSTATE = 'GET_STSLANDINGMODULE_PREVSTATE';
export const GET_STSLANDINGMODULE_QUERYPARAMSUSED =
  'GET_STSLANDINGMODULE_QUERYPARAMSUSED';
export const GET_STSLANDINGMODULE_C360ACCTLISTERRKEY =
  'GET_STSLANDINGMODULE_C360ACCTLISTERRKEY';
export const GET_STSLANDINGMODULE_RXPRODUCTINFOERRKEY =
  'GET_STSLANDINGMODULE_RXPRODUCTINFOERRKEY';
export const MUTATE_STSLANDINGMODULE_QUERYPARAMSUSED =
  'MUTATE_STSLANDINGMODULE_QUERYPARAMSUSED';
export const MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER =
  'MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER';
export const MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE =
  'MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE';
export const MUTATE_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER =
  'MUTATE_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER';
export const MUTATE_STSLANDINGMODULE_THROTTLED =
  'MUTATE_STSLANDINGMODULE_THROTTLED';
export const MUTATE_STSLANDINGMODULE_SUBMITPENDING =
  'MUTATE_STSLANDINGMODULE_SUBMITPENDING';
export const MUTATE_STSLANDINGMODULE_CURRSTATE =
  'MUTATE_STSLANDINGMODULE_CURRSTATE';
export const MUTATE_STSLANDINGMODULE_PREVSTATE =
  'MUTATE_STSLANDINGMODULE_PREVSTATE';
export const MUTATE_STSLANDINGMODULE_C360ACCTLISTERRKEY =
  'MUTATE_STSLANDINGMODULE_C360ACCTLISTERRKEY';
export const MUTATE_STSLANDINGMODULE_RXPRODUCTINFOERRKEY =
  'MUTATE_STSLANDINGMODULE_RXPRODUCTINFOERRKEY';
export const ACTION_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER =
  'ACTION_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER';
export const ACTION_STSLANDINGMODULE_SELECTEDZIPCODE =
  'ACTION_STSLANDINGMODULE_SELECTEDZIPCODE';
export const ACTION_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER =
  'ACTION_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER';
export const ACTION_STSLANDINGMODULE_THROTTLED =
  'ACTION_STSLANDINGMODULE_THROTTLED';
export const ACTION_STSLANDINGMODULE_SUBMITPENDING =
  'ACTION_STSLANDINGMODULE_SUBMITPENDING';
export const ACTION_STSLANDINGMODULE_QUERYPARAMSUSED =
  'ACTION_STSLANDINGMODULE_QUERYPARAMSUSED';
export const ACTION_STSLANDINGMODULE_C360ACCTLISTERRKEY =
  'ACTION_STSLANDINGMODULE_C360ACCTLISTERRKEY';
export const ACTION_STSLANDINGMODULE_RXPRODUCTINFOERRKEY =
  'ACTION_STSLANDINGMODULE_RXPRODUCTINFOERRKEY';
export const STSLANDINGMODULE = 'stsLandingModule';
export const NS_GET_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER}`;
export const NS_GET_STSLANDINGMODULE_SELECTEDZIPCODE = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_SELECTEDZIPCODE}`;
export const NS_GET_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER}`;
export const NS_GET_STSLANDINGMODULE_THROTTLED = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_THROTTLED}`;
export const NS_GET_STSLANDINGMODULE_SUBMITPENDING = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_SUBMITPENDING}`;
export const NS_GET_STSLANDINGMODULE_CURRSTATE = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_CURRSTATE}`;
export const NS_GET_STSLANDINGMODULE_PREVSTATE = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_PREVSTATE}`;
export const NS_GET_STSLANDINGMODULE_QUERYPARAMSUSED = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_QUERYPARAMSUSED}`;
export const NS_GET_STSLANDINGMODULE_C360ACCTLISTERRKEY = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_C360ACCTLISTERRKEY}`;
export const NS_GET_STSLANDINGMODULE_RXPRODUCTINFOERRKEY = `${STSLANDINGMODULE}/${GET_STSLANDINGMODULE_RXPRODUCTINFOERRKEY}`;
export const NS_MUTATE_STSLANDINGMODULE_C360ACCTLISTERRKEY = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_C360ACCTLISTERRKEY}`;
export const NS_MUTATE_STSLANDINGMODULE_RXPRODUCTINFOERRKEY = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_RXPRODUCTINFOERRKEY}`;
export const NS_MUTATE_STSLANDINGMODULE_QUERYPARAMSUSED = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_QUERYPARAMSUSED}`;
export const NS_MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER}`;
export const NS_MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_SELECTEDZIPCODE}`;
export const NS_MUTATE_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER}`;
export const NS_MUTATE_STSLANDINGMODULE_THROTTLED = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_THROTTLED}`;
export const NS_MUTATE_STSLANDINGMODULE_SUBMITPENDING = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_SUBMITPENDING}`;
export const NS_MUTATE_STSLANDINGMODULE_CURRSTATE = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_CURRSTATE}`;
export const NS_MUTATE_STSLANDINGMODULE_PREVSTATE = `${STSLANDINGMODULE}/${MUTATE_STSLANDINGMODULE_PREVSTATE}`;
export const NS_ACTION_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_SELECTEDACCOUNTNUMBER}`;
export const NS_ACTION_STSLANDINGMODULE_SELECTEDZIPCODE = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_SELECTEDZIPCODE}`;
export const NS_ACTION_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_SHOWVALIDATEERRORSACCOUNTNUMBER}`;
export const NS_ACTION_STSLANDINGMODULE_THROTTLED = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_THROTTLED}`;
export const NS_ACTION_STSLANDINGMODULE_SUBMITPENDING = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_SUBMITPENDING}`;
export const NS_ACTION_STSLANDINGMODULE_QUERYPARAMSUSED = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_QUERYPARAMSUSED}`;
export const NS_ACTION_STSLANDINGMODULE_C360ACCTLISTERRKEY = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_C360ACCTLISTERRKEY}`;
export const NS_ACTION_STSLANDINGMODULE_RXPRODUCTINFOERRKEY = `${STSLANDINGMODULE}/${ACTION_STSLANDINGMODULE_RXPRODUCTINFOERRKEY}`;
