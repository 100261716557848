/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/getC360PendingOrdersParallelModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import { getC360PendingOrders as getC360PendingOrdersApi } from './api/getC360PendingOrders';
import { GetC360PendingOrdersParallelMap } from '../modules/getC360PendingOrdersParallelModule/types';

const shouldCall = (gcpopMap: GetC360PendingOrdersParallelMap, num: string) =>
  num &&
  gcpopMap &&
  !gcpopMap[num]?.pending &&
  (!gcpopMap[num]?.c360PendingOrders ||
    !Object.keys(gcpopMap[num]?.c360PendingOrders).length);

export function* getC360PendingOrdersParallel(
  getC360PendingOrdersPayload: any
): any {
  const acctNum: string = getC360PendingOrdersPayload?.payload?.replaceAll(
    '*',
    ''
  );
  const gcpopMap: GetC360PendingOrdersParallelMap = yield select(
    (s) =>
      s?.[tk.GETC360PENDINGORDERSPARALLELMODULE]
        ?.getC360PendingOrdersParallelMap
  );
  const shouldThrottle: boolean = yield select(
    (s) => s?.[tk.GETC360PENDINGORDERSPARALLELMODULE]?.shouldThrottle
  );
  if (shouldCall(gcpopMap, acctNum) && !shouldThrottle) {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: true,
    });
    yield put({
      type: tk.NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_PENDING,
      key: acctNum,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) => s?.[tk.GETC360PENDINGORDERSPARALLELMODULE]?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }

      const resp = yield call(getC360PendingOrdersApi, acctNum, isAuth);
      if (resp?.data) {
        yield put({
          type: tk.NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERS,
          c360PendingOrders: resp.data,
          key: acctNum,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR,
          c360PendingOrdersError: resp,
          key: acctNum,
        });
      }
    } catch (err) {
      if (gcpopMap[acctNum]) {
        yield put({
          type: tk.NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR,
          c360PendingOrdersError: err,
          key: acctNum,
        });
      }
    } finally {
      yield put({
        type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
        progressSpinnerAppear: false,
      });
      if (yield cancelled()) {
        if (gcpopMap[acctNum]) {
          yield put({
            type: tk.NS_MUTATE_GETC360PENDINGORDERSPARALLELMODULE_C360PENDINGORDERSERROR,
            c360PendingOrdersError: 'SAGA_CANCELLED',
            key: acctNum,
          });
        }
      }
    }
  }
}

export function* watchGetC360PendingOrdersParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getC360PendingOrdersParallel);
}

export default watchGetC360PendingOrdersParallel;
