/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/getRXAppointmentInfosParallelModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import { getRXAppointmentInfos as getRXAppointmentInfosApi } from './api/getRXAppointmentInfos';
import { GetRXAppointmentInfosParallelMap } from '../modules/getRXAppointmentInfosParallelModule/types';

const shouldCall = (
  grapMap: GetRXAppointmentInfosParallelMap,
  nativeTicketNum: string
) =>
  nativeTicketNum &&
  grapMap &&
  !grapMap[nativeTicketNum]?.pending &&
  (!grapMap[nativeTicketNum]?.rxAppointmentInfos ||
    !Object.keys(grapMap[nativeTicketNum]?.rxAppointmentInfos).length);

export function* getRXAppointmentInfosParallel(
  getRXAppointmentInfosPayload: any
): any {
  const nativeTicketNum: string =
    getRXAppointmentInfosPayload?.payload?.nativeTicketNum;
  const grapMap: GetRXAppointmentInfosParallelMap = yield select(
    (s) =>
      s?.[tk.GETRXAPPOINTMENTINFOSPARALLELMODULE]
        ?.getRXAppointmentInfosParallelMap
  );
  const shouldThrottle: boolean = yield select(
    (s) => s?.[tk.GETRXAPPOINTMENTINFOSPARALLELMODULE]?.shouldThrottle
  );
  if (shouldCall(grapMap, nativeTicketNum) && !shouldThrottle) {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: true,
    });
    yield put({
      type: tk.NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_PENDING,
      key: nativeTicketNum,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) => s?.[tk.GETRXAPPOINTMENTINFOSPARALLELMODULE]?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }

      const resp = yield call(
        getRXAppointmentInfosApi,
        {
          ...getRXAppointmentInfosPayload?.payload,
          ticketNum: getRXAppointmentInfosPayload?.payload?.nativeTicketNum,
          wtn: getRXAppointmentInfosPayload?.payload?.wtn?.replaceAll('*', ''),
        },
        isAuth
      );
      if (resp?.data) {
        yield put({
          type: tk.NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOS,
          rxAppointmentInfos: resp.data,
          key: nativeTicketNum,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR,
          rxAppointmentInfosError: resp,
          key: nativeTicketNum,
        });
      }
    } catch (err) {
      if (grapMap[nativeTicketNum]) {
        yield put({
          type: tk.NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR,
          rxAppointmentInfosError: err,
          key: nativeTicketNum,
        });
      }
    } finally {
      yield put({
        type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
        progressSpinnerAppear: false,
      });
      if (yield cancelled()) {
        if (grapMap[nativeTicketNum]) {
          yield put({
            type: tk.NS_MUTATE_GETRXAPPOINTMENTINFOSPARALLELMODULE_RXAPPOINTMENTINFOSERROR,
            rxAppointmentInfosError: 'SAGA_CANCELLED',
            key: nativeTicketNum,
          });
        }
      }
    }
  }
}

export function* watchGetRXAppointmentInfosParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getRXAppointmentInfosParallel);
}

export default watchGetRXAppointmentInfosParallel;
