/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { useCookies } from 'vue3-cookies';
import * as apiEndpts from '../../common/apiEndpts';
import * as envVars from '../../common/envVars';
import {
  ALLOWS_UNAUTH_PATHS,
  DH_SSO_ALLOWABLE_PATHS,
  LOCALSTORAGE_FRONTCHANNELLOGOUT,
  CUSTCARE_ISLOGINFROMDH_COOKIE,
} from '../../common/strings';
import * as tk from '../modules/auth/treekeys';
import * as tkG3EUP from '../modules/get34SEcomUserProfileModule/treekeys';
import { getIsAuth } from './api/getIsAuth';
import { getIsDhSsoAuth } from './api/getIsDhSsoAuth';
import { useDataUtilsSingleton } from '../../common/utils/dataUtilsSingleton';

const allowsUnauth = (page: string | null | undefined) =>
  ALLOWS_UNAUTH_PATHS.map((v) => v.toLowerCase()).includes(
    page ? page.toLowerCase() : ''
  );

const allowsDhSsoAuth = (page: string | null | undefined) =>
  DH_SSO_ALLOWABLE_PATHS.map((v) => v.toLowerCase()).includes(
    page ? page.toLowerCase() : ''
  );

const throttleRefresh = () => false;

const base = '/digicustcare/';

const redirectToOAuth2SignIn = (
  postLoginRedirectURL: string | undefined | null
) => {
  if (throttleRefresh()) {
    return;
  }
  const slashStrippedURL =
    postLoginRedirectURL?.split('/').filter(Boolean).join('/') || '';
  const lowerCasePath = slashStrippedURL.split(/[^A-Za-z]/)[0].toLowerCase();
  const lowerCaseFullPath =
    lowerCasePath + slashStrippedURL.substr(lowerCasePath.length);
  const postLoginUrl = encodeURIComponent(
    `${envVars.VUE_APP_CUSTCARE_UI_HOST}${base}${lowerCaseFullPath || 'home'}`
  );
  window.location.href = `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${apiEndpts.AUTH_SIGNIN}?postLoginRedirectUri=${postLoginUrl}`;
};

const handleSigninRedirect = (to: any) => {
  if (!allowsUnauth(to?.name)) {
    redirectToOAuth2SignIn(to?.fullPath);
  }
};

const handleLocalStorage = (to: any) => {
  if (
    !allowsUnauth(to?.name) &&
    localStorage.getItem(LOCALSTORAGE_FRONTCHANNELLOGOUT) === 'true'
  ) {
    localStorage.removeItem(LOCALSTORAGE_FRONTCHANNELLOGOUT);
    redirectToOAuth2SignIn(to?.fullPath);
  }
};

function* handleDhSsoAuth(to: any): any {
  if (allowsDhSsoAuth(to?.name)) {
    try {
      const resp = yield call(getIsDhSsoAuth);
      if (resp?.data) {
        yield put({
          type: tk.NS_MUTATE_AUTH_DH_SSO_FULFILLED,
          dhSsoAuthenticated: true,
        });
        const { cookies } = useCookies();
        const expiryTime = useDataUtilsSingleton().getExpiryTime();
        cookies.set(CUSTCARE_ISLOGINFROMDH_COOKIE, 'true', `${expiryTime}s`);
      } else {
        yield put({
          type: tk.NS_MUTATE_AUTH_DH_SSO_REJECTED,
          dhSsoAuthenticationError: 'Error calling checkHeaderForDssr.',
        });
      }
    } catch (err) {
      yield put({
        type: tk.NS_MUTATE_AUTH_DH_SSO_REJECTED,
        dhSsoAuthenticationError: err,
      });
    }
  }
}

export function* handleAuth(newItem: any): any {
  const isAuth = yield select((s) => s?.[tk.AUTHMODULE]?.authenticated);
  handleLocalStorage(newItem?.payload?.to);
  if (isAuth === false) {
    yield put({ type: tk.NS_MUTATE_AUTH_PENDING });
    try {
      const resp = yield call(getIsAuth);
      if (resp?.data) {
        yield put({
          type: tk.NS_MUTATE_AUTH_FULFILLED,
          authenticated: resp.data,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_AUTH_REJECTED,
          authenticationError: resp.data,
        });
        handleSigninRedirect(newItem?.payload?.to);
      }
    } catch (err) {
      yield put({
        type: tk.NS_MUTATE_AUTH_REJECTED,
        authenticationError: err,
      });
      handleSigninRedirect(newItem?.payload?.to);
    }
    const isAuthRecheck = yield select(
      (s) => s?.[tk.AUTHMODULE]?.authenticated
    );
    if (!isAuthRecheck) {
      yield handleDhSsoAuth(newItem?.payload?.to);
      const isDhSsoAuth = yield select(
        (s) => s?.[tk.AUTHMODULE]?.dhSsoAuthenticated
      );
      if (isDhSsoAuth) {
        const pageType = newItem?.payload?.to?.meta?.page?.pageType;
        if (pageType && pageType.length > 0) {
          redirectToOAuth2SignIn(
            pageType.includes('sts') ? '/stsPostLogin' : '/wmtPostLogin'
          );
        }
      }
    }
  }
}

export function* watchHandleAuth(pattern: string): Generator<any, any, any> {
  yield takeLatest(pattern, handleAuth);
}

export default watchHandleAuth;
