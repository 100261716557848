import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import { ref } from 'vue';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import { useDataUtilsSingleton } from '../../../common/utils/dataUtilsSingleton';
import get34SEcomUserProfileMockResp from '../../../assets/json/apiMockResp/get34SEcomUserProfile.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();
const dataUtils = useDataUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 500;
const MOCK_TIME_BOUND_MAX = 2000;

export function* get34SEcomUserProfile(flowName: string): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: get34SEcomUserProfileMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  if (flowName === 'sam' || flowName === 'wmt') {
    return yield call(
      axios.get,
      `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${
        apiEndpts.AUTH_WMT_GET_USERPROFILE
      }?deviceType=${dataUtils.detectDeviceType()}&browserType=${dataUtils.detectBrowser()}&expiryTime=${dataUtils.getExpiryTime()}`,
      {
        withCredentials: true,
        headers: {
          ApiKey: encryptedApiKey,
        },
        /* timeout: 60000, */
      }
    );
  }
  return yield call(
    axios.get,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${
      apiEndpts.AUTH_STS_GET_USERPROFILE
    }?deviceType=${dataUtils.detectDeviceType()}&browserType=${dataUtils.detectBrowser()}&expiryTime=${dataUtils.getExpiryTime()}`,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
