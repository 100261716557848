import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { AuthState } from './types';
import * as treekeys from './treekeys';

export const data: AuthState = {
  pending: false,
  authenticated: false,
  authenticationError: undefined,
  dhSsoAuthenticated: false,
  dhSsoAuthenticationError: undefined,
};

export const getters: GetterTree<AuthState, any> = {
  [treekeys.GET_AUTH_PENDING]: (state) => state.pending,
  [treekeys.GET_AUTH_AUTHENTICATED]: (state) => state.authenticated,
  [treekeys.GET_AUTH_AUTHENTICATION_ERROR]: (state) =>
    state.authenticationError,
  [treekeys.GET_AUTH_DH_SSO_AUTHENTICATED]: (state) => state.dhSsoAuthenticated,
  [treekeys.GET_AUTH_DH_SSO_AUTHENTICATION_ERROR]: (state) =>
    state.dhSsoAuthenticationError,
};

export const mutations: MutationTree<AuthState> = {
  [treekeys.MUTATE_AUTH_STATE]: (state, newState: AuthState) => {
    state.pending = newState.pending;
    state.authenticated = newState.authenticated;
    state.authenticationError = newState.authenticationError;
  },
  [treekeys.MUTATE_AUTH_PENDING]: (state) => {
    state.pending = true;
  },
  [treekeys.MUTATE_AUTH_FULFILLED]: (state, { authenticated }) => {
    state.pending = false;
    state.authenticated = authenticated;
    state.authenticationError = undefined;
  },
  [treekeys.MUTATE_AUTH_REJECTED]: (state, { authenticationError }) => {
    state.pending = false;
    state.authenticated = false;
    state.authenticationError = authenticationError;
  },
  [treekeys.MUTATE_AUTH_DH_SSO_FULFILLED]: (state, { dhSsoAuthenticated }) => {
    state.dhSsoAuthenticated = dhSsoAuthenticated;
    state.dhSsoAuthenticationError = undefined;
  },
  [treekeys.MUTATE_AUTH_DH_SSO_REJECTED]: (
    state,
    { dhSsoAuthenticationError }
  ) => {
    state.dhSsoAuthenticated = false;
    state.dhSsoAuthenticationError = dhSsoAuthenticationError;
  },
};

export const actions: ActionTree<AuthState, any> = {
  [treekeys.AUTH_PENDING]: ({ commit }) => {
    commit(treekeys.MUTATE_AUTH_STATE, {
      ...data,
      pending: true,
    });
  },
  [treekeys.AUTH_FULFILLED]: ({ commit }, authenticated: boolean) => {
    commit(treekeys.MUTATE_AUTH_STATE, {
      ...data,
      pending: false,
      authenticated,
      authenticationError: undefined,
    });
  },
  [treekeys.AUTH_REJECTED]: (
    { commit },
    authenticationError: Record<string, unknown>
  ) => {
    commit(treekeys.MUTATE_AUTH_STATE, {
      ...data,
      pending: false,
      authenticated: false,
      authenticationError,
    });
  },
};

const authModule: Module<AuthState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default authModule;
