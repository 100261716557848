import { C360Account } from '@/store/modules/getC360AcctsParallelModule/types';
import { ACTIVE_STATUSES } from '../strings';
import { CustomerTypeEnum } from '../enum/CustomerTypeEnum';

class DataUtilsSingleton {
  browserType;

  osType;

  deviceType;

  constructor() {
    this.browserType = '';
    this.osType = '';
    this.deviceType = '';
  }

  getActiveAccts = (c360Accounts: C360Account[] | undefined) =>
    c360Accounts?.filter((c360Account: C360Account) =>
      ACTIVE_STATUSES.map((x) => x.toLowerCase()).includes(
        c360Account?.accountStatus?.toLowerCase() || 'assumingInactive'
      )
    ) || [];

  getAcctsWithoutStatuses = (
    c360Accounts: C360Account[] | undefined,
    statuses: (string | undefined)[]
  ) =>
    c360Accounts?.filter(
      (c360Account: C360Account) =>
        !statuses
          .map((x) => x?.toLowerCase())
          .includes(c360Account?.accountStatus?.toLowerCase())
    ) || [];

  getAcctsWithoutStatusesWithZip = (
    c360Accounts: C360Account[] | undefined,
    statuses: (string | undefined)[],
    zipCode: string
  ) => this.getAcctsWithoutStatuses(c360Accounts, statuses);

  getCustomerType = (type: any) =>
    type != null
      ? Object.entries(CustomerTypeEnum).find(
          ([key]) => key.split('_')[1] === type
        )?.[1]
      : '';

  isStringNonNull = (str: string | undefined) =>
    str && str !== '' && str !== '' && str !== null && str !== 'null';

  detectBrowser = () => {
    if (this.browserType?.length > 0) {
      return this.browserType;
    }
    const { userAgent } = navigator;
    if (userAgent.indexOf('Firefox') > -1 || userAgent.indexOf('FxiOS') > -1) {
      this.browserType = 'Mozilla Firefox';
    } else if (userAgent.indexOf('SamsungBrowser') > -1) {
      this.browserType = 'Samsung Internet';
    } else if (
      userAgent.indexOf('Opera') > -1 ||
      userAgent.indexOf('OPR') > -1
    ) {
      this.browserType = 'Opera';
    } else if (userAgent.indexOf('Trident') > -1) {
      this.browserType = 'Microsoft Internet Explorer';
    } else if (userAgent.indexOf('Edg') > -1) {
      this.browserType = 'Microsoft Edge';
    } else if (
      userAgent.indexOf('Chrome') > -1 ||
      userAgent.indexOf('CriOS') > -1
    ) {
      this.browserType = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      this.browserType = 'Apple Safari';
    } else {
      this.browserType = 'UNKNOWN';
    }
    return this.browserType;
  };

  detectDeviceType = () => {
    if (this.deviceType?.length > 0) {
      return this.deviceType;
    }
    const { userAgent } = navigator;
    if (userAgent.indexOf('Android') > -1 && userAgent.indexOf('Mobile') > -1) {
      this.deviceType = 'mobile-ANDROID';
    } else if (
      userAgent.indexOf('Android') > -1 &&
      userAgent.indexOf('Mobile') === -1
    ) {
      this.deviceType = 'tablet-ANDROID';
    } else if (userAgent.indexOf('Win') > -1) {
      this.deviceType = 'desktop-WINDOWS';
    } else if (userAgent.indexOf('iPhone') > -1) {
      this.deviceType = 'mobile-IOS';
    } else if (userAgent.indexOf('iPad') > -1) {
      this.deviceType = 'tablet-IOS';
    } else if (userAgent.indexOf('Mac') > -1) {
      this.deviceType = 'desktop-MACOS';
    } else if (userAgent.indexOf('X11') > -1) {
      this.deviceType = 'desktop-UNIX';
    } else if (userAgent.indexOf('Linux') > -1) {
      this.deviceType = 'desktop-LINUX';
    } else {
      this.deviceType = getDeviceByResolution();
    }
    return this.deviceType;
  };

  detectOS = () => {
    if (this.osType?.length > 0) {
      return this.osType;
    }
    const { userAgent } = navigator;
    if (userAgent.indexOf('Android') > -1) {
      this.osType = 'Android';
    } else if (userAgent.indexOf('Win') > -1) {
      this.osType = 'Windows';
    } else if (userAgent.indexOf('like Mac') > -1) {
      this.osType = 'iOS';
    } else if (userAgent.indexOf('Mac') > -1) {
      this.osType = 'MacOS';
    } else if (userAgent.indexOf('X11') > -1) {
      this.osType = 'UNIX';
    } else if (userAgent.indexOf('Linux') > -1) {
      this.osType = 'Linux';
    } else {
      this.osType = 'UNKNOWN';
    }
    return this.osType;
  };

  getExpiryTime = () => {
    const eod: Date = new Date();
    eod.setHours(23, 59, 59, 999);
    const expiry = Math.floor((eod.getTime() - new Date().getTime()) / 1000);
    return expiry;
  };
}

let dataUtilsSingleton: DataUtilsSingleton;

export const useDataUtilsSingleton = (): DataUtilsSingleton => {
  if (!dataUtilsSingleton) {
    dataUtilsSingleton = new DataUtilsSingleton();
  }
  return dataUtilsSingleton;
};
function getDeviceByResolution() {
  const width = window.innerWidth;
  if (width <= 767) return 'mobile';
  if (width >= 767 && width <= 1024) return 'tablet';
  return 'desktop';
}
