/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, takeEvery } from '@redux-saga/core/effects';
import { sendUILog as sendUILogApi } from './api/sendUILogs';

export function* sendUILogs(uiLogRequest: any): any {
  try {
    yield call(sendUILogApi, uiLogRequest.payload);
  } catch (err) {
    console.log(err);
  }
}

export function* watchSendUILogs(pattern: string): Generator<any, any, any> {
  yield takeEvery(pattern, sendUILogs);
}

export default watchSendUILogs;
