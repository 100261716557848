import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { ProgressSpinnerState } from './types';
import * as treekeys from './treekeys';

export const data: ProgressSpinnerState = {
  progressSpinnerAppear: false,
};

export const getters: GetterTree<ProgressSpinnerState, any> = {
  [treekeys.GET_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR]: (state) =>
    state.progressSpinnerAppear,
};

export const mutations: MutationTree<ProgressSpinnerState> = {
  [treekeys.MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR]: (
    state,
    { progressSpinnerAppear }
  ) => {
    state.progressSpinnerAppear = progressSpinnerAppear;
  },
};

export const actions: ActionTree<ProgressSpinnerState, any> = {
  [treekeys.ACTION_PROGRESSSPINNERMODULEMODULE_PROGRESSSPINNERAPPEAR]: (
    { commit },
    { progressSpinnerAppear }
  ) => {
    commit(treekeys.MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR, {
      progressSpinnerAppear,
    });
  },
};

const progressSpinnerModule: Module<ProgressSpinnerState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default progressSpinnerModule;
