/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { useVuelidateRulesSingleton } from '@/common/utils/vuelidateRulesSingleton';
import {
  C360Account,
  GetC360AcctsParallelMap,
  GetC360AcctsState,
} from '@/store/modules/getC360AcctsParallelModule/types';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkG3EUP from '@/store/modules/get34SEcomUserProfileModule/treekeys';
import * as tkPMSM from '@/store/modules/performMobileSSOModule/treekeys';
import * as tkGCAP from '@/store/modules/getC360AcctsParallelModule/treekeys';
import {
  AccountNumberInfo,
  _34SEcomUserProfileResponse,
} from '@/store/modules/get34SEcomUserProfileModule/types';
import {
  AccountNumberInfo as AccountNumberInfoPMSM,
  MobileSSOResponse,
} from '@/store/modules/performMobileSSOModule/types';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { getC360AcctsParallel } from '../../watchGetC360AcctsParallel';

class QueryParamsSTSLHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getAcctInMap = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNum: string
  ) =>
    Object.values(getC360AcctsParallelMap)
      ?.flatMap(
        (getC360AcctsState: GetC360AcctsState) =>
          getC360AcctsState?.c360AccountList?.c360AccountList?.accounts
      )
      ?.find(
        (c360Account: C360Account | undefined) =>
          c360Account?.accountNumber === acctNum
      );

  getActionBasedOnRules = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNumOrEmail: string,
    zipCode: string
  ): HandleQueryParamsAction => {
    const vuelidateRules = useVuelidateRulesSingleton();
    if (!acctNumOrEmail) {
      return HandleQueryParamsAction.GOTO_STSLANDING;
    }
    // const acctInMap = this.getAcctInMap(
    //   getC360AcctsParallelMap,
    //   acctNumOrEmail
    // );
    if (
      getC360AcctsParallelMap?.[acctNumOrEmail] &&
      Object.keys(getC360AcctsParallelMap?.[acctNumOrEmail]).length &&
      (!getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ?.c360AccountList?.accounts?.length ||
        vuelidateRules.isNoAcctsFoundError(
          getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ) ||
        vuelidateRules.isAcctDeactivatedError(
          getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ) ||
        !vuelidateRules.hasAcctsWithoutBadStatuses(
          getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
        ))
    ) {
      return HandleQueryParamsAction.GOTO_STSLANDING;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const zipCode: string = queryParams?.zipCode || '';
    const getC360AcctsParallelMap: GetC360AcctsParallelMap = yield select(
      (s) => s?.[tkGCAP.GETC360ACCTSPARALLELMODULE]?.getC360AcctsParallelMap
    );
    if (
      acctNumOrEmail &&
      !this.getAcctInMap(getC360AcctsParallelMap, acctNumOrEmail)
    ) {
      yield call(getC360AcctsParallel, { payload: acctNumOrEmail });
    }
    response.handleQueryParamsAction = this.getActionBasedOnRules(
      getC360AcctsParallelMap,
      acctNumOrEmail,
      zipCode
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNum: string = queryParams?.accountNumber || '';
    const ecomUserProfResp: _34SEcomUserProfileResponse = yield select(
      (s) => s?.[tkG3EUP.GET34SECOMUSERPROFILEMODULE]?._34SEcomUserProfile
    );
    let shouldNotHandleResponse;
    if (ecomUserProfResp?.accountNumbers !== undefined) {
      shouldNotHandleResponse =
        ecomUserProfResp?.accountNumbers?.find(
          (accountNumberInfo: AccountNumberInfo) =>
            accountNumberInfo?.accountNumber === acctNum
        ) ||
        response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
    } else {
      const mobileSSOResponse: MobileSSOResponse = yield select(
        (s) => s?.[tkPMSM.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
      );
      shouldNotHandleResponse =
        mobileSSOResponse?.accountNumbers?.find(
          (accountNumberInfo: AccountNumberInfoPMSM) =>
            accountNumberInfo?.accountNumber === acctNum
        ) ||
        response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
    }
    return shouldNotHandleResponse;
  }
}

let qp: QueryParamsSTSLHandler;

export const useQueryParamsSTSLHandler = (): QueryParamsSTSLHandler => {
  if (!qp) {
    qp = new QueryParamsSTSLHandler();
  }
  return qp;
};
