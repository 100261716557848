/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import {
  C360Account,
  GetC360AcctsParallelMap,
  GetC360AcctsState,
} from '@/store/modules/getC360AcctsParallelModule/types';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGCAP from '@/store/modules/getC360AcctsParallelModule/treekeys';
import { INACTIVE_STATUSES, SUSPENDED_STATUSES } from '@/common/strings';
import { useDataUtilsSingleton } from '@/common/utils/dataUtilsSingleton';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';

class QueryParamsSTSSAHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getAcctInMap = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNum: string
  ) =>
    Object.values(getC360AcctsParallelMap)
      ?.flatMap(
        (getC360AcctsState: GetC360AcctsState) =>
          getC360AcctsState?.c360AccountList?.c360AccountList?.accounts
      )
      ?.find(
        (c360Account: C360Account | undefined) =>
          c360Account?.accountNumber === acctNum
      );

  getActionBasedOnRules = (
    getC360AcctsParallelMap: GetC360AcctsParallelMap,
    acctNumOrEmail: string,
    zipCode: string
  ): HandleQueryParamsAction => {
    const dataUtilsSingleton = useDataUtilsSingleton();
    const numAccts: number | undefined =
      dataUtilsSingleton.getAcctsWithoutStatusesWithZip(
        getC360AcctsParallelMap?.[acctNumOrEmail]?.c360AccountList
          ?.c360AccountList?.accounts,
        [...INACTIVE_STATUSES, ...SUSPENDED_STATUSES, '', undefined],
        zipCode
      )?.length;
    const acctInMap = this.getAcctInMap(
      getC360AcctsParallelMap,
      acctNumOrEmail
    );
    if (numAccts && numAccts > 1) {
      if (
        !acctInMap ||
        INACTIVE_STATUSES.map((x) => x.toLowerCase()).includes(
          acctInMap?.accountStatus?.toLowerCase() || 'assumingActive'
        )
      ) {
        return HandleQueryParamsAction.GOTO_STSSELECTACCT;
      }
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const zipCode: string = queryParams?.zipCode || '';
    const getC360AcctsParallelMap: GetC360AcctsParallelMap = yield select(
      (s) => s?.[tkGCAP.GETC360ACCTSPARALLELMODULE]?.getC360AcctsParallelMap
    );
    response.handleQueryParamsAction = this.getActionBasedOnRules(
      getC360AcctsParallelMap,
      acctNumOrEmail.replaceAll('*', ''),
      zipCode
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSSAHandler;

export const useQueryParamsSTSSAHandler = (): QueryParamsSTSSAHandler => {
  if (!qp) {
    qp = new QueryParamsSTSSAHandler();
  }
  return qp;
};
