/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  TrackerTechState,
  TrackerTechDetails,
  ServiceAddressDetails,
} from './types';
import * as treekeys from './treekeys';

const initServiceAddressState: ServiceAddressDetails = {
  pending: false,
  locationError: '',
};
const initTrackerTechState: TrackerTechDetails = {
  serviceAddressDetails: { ...initServiceAddressState },
};
export const data: TrackerTechState = {
  trackerTechDetails: { ...initTrackerTechState },
  errorMessage: '',
  pending: false,
};
export const getters: GetterTree<TrackerTechState, any> = {
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECH]: (state) =>
    state.trackerTechDetails,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECHERROR]: (state) =>
    state.errorMessage,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECHPENDING]: (state) => state.pending,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESS]: (state) =>
    state.trackerTechDetails?.serviceAddressDetails,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING]: (state) =>
    state.trackerTechDetails?.serviceAddressDetails?.pending,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION]: (
    state
  ) => state.trackerTechDetails?.serviceAddressDetails?.location,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR]: (
    state
  ) => state.trackerTechDetails?.serviceAddressDetails?.locationError,
  [treekeys.GET_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION]: (state) =>
    state.trackerTechDetails?.technicianLocation,
};
export const mutations: MutationTree<TrackerTechState> = {
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH]: (
    state,
    { trackerTechDetails }
  ) => {
    state.trackerTechDetails = trackerTechDetails;
    state.errorMessage = '';
    state.pending = false;
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECHCLEARDETAILS]: (state) => {
    state.trackerTechDetails = { ...initTrackerTechState };
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR]: (
    state,
    errorMessage
  ) => {
    state.errorMessage = errorMessage?.errorMessage;
    state.pending = false;
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECHPENDING]: (state) => {
    state.pending = true;
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATION]: (
    state,
    { location }
  ) => {
    state.trackerTechDetails.serviceAddressDetails.location = location;
    state.trackerTechDetails.serviceAddressDetails.locationError = '';
    state.trackerTechDetails.serviceAddressDetails.pending = false;
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSCLEARDETAILS]: (
    state
  ) => {
    const currentAddress =
      state.trackerTechDetails.serviceAddressDetails.address;
    state.trackerTechDetails.serviceAddressDetails = {
      address: currentAddress,
      ...initServiceAddressState,
    };
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSLOCATIONERROR]: (
    state,
    errorMessage
  ) => {
    state.trackerTechDetails.serviceAddressDetails.locationError =
      errorMessage?.errorMessage;
    state.trackerTechDetails.serviceAddressDetails.pending = false;
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH_SERVICEADDRESSPENDING]: (
    state
  ) => {
    state.trackerTechDetails.serviceAddressDetails.pending = true;
  },
  [treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH_TECHLOCATION]: (
    state,
    { technicianLocation }
  ) => {
    state.trackerTechDetails.technicianLocation = technicianLocation;
    state.errorMessage = '';
    state.pending = false;
  },
};
export const actions: ActionTree<TrackerTechState, any> = {
  [treekeys.ACTION_TRACKERTECHMODULE_TRACKERTECH]: (
    { commit },
    { trackerTechDetails }
  ) => {
    commit(treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECH, {
      trackerTechDetails,
    });
  },
  [treekeys.ACTION_TRACKERTECHMODULE_TRACKERTECHERROR]: (
    { commit },
    errorMessage
  ) => {
    commit(treekeys.MUTATE_TRACKERTECHMODULE_TRACKERTECHERROR, {
      errorMessage,
    });
  },
};

const trackerTechModule: Module<TrackerTechState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default trackerTechModule;
