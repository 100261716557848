import { call, delay } from '@redux-saga/core/effects';
import axios from 'axios';
import * as envVars from '../../../common/envVars';
import * as apiEndpts from '../../../common/apiEndpts';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import getRXAppointmentInfosMockResp from '../../../assets/json/apiMockResp/getRXAppointmentInfos.json';
import { useApiKeyUtilsSingleton } from '../../../common/utils/apiKeyUtilsSingleton';

const sagaUtils = useSagaUtilsSingleton();

const MOCK_TIME_BOUND_MIN = 1000;
const MOCK_TIME_BOUND_MAX = 2000;

interface GetRXAppointmentInfosPayload {
  rxSessionIdentifier: string;
  ticketNum: string;
  customerProductId: string;
  wtn: string;
  useRXWS: boolean;
  productName: string;
}

export function* getRXAppointmentInfos(
  getRXApptInfosPayload: GetRXAppointmentInfosPayload,
  isAuth: boolean
): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return { data: getRXAppointmentInfosMockResp };
  }

  const apiKeyUtils = useApiKeyUtilsSingleton();
  const encryptedApiKey = yield apiKeyUtils.getEncryptedApiKey();

  const API_ENDPOINT = isAuth
    ? apiEndpts.AUTH_WMT_GET_APPOINTMENTINFOS
    : apiEndpts.WMT_GET_RX_APPOINTMENTINFOS;
  return yield call(
    axios.post,
    `${envVars.VUE_APP_CUSTCARE_SERVICE_HOST}${API_ENDPOINT}`,
    getRXApptInfosPayload,
    {
      withCredentials: true,
      headers: {
        ApiKey: encryptedApiKey,
      },
      /* timeout: 60000, */
    }
  );
}
