import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { AzureAttrsState } from './types';
import * as treekeys from './treekeys';

export const data: AzureAttrsState = {
  pending: false,
  azureAttrs: {},
  azureAttrsError: undefined,
};

export const getters: GetterTree<AzureAttrsState, any> = {
  [treekeys.GET_AZUREATTRS_PENDING]: (state) => state.pending,
  [treekeys.GET_AZUREATTRS_AZURE_ATTRS]: (state) => state.azureAttrs,
  [treekeys.GET_AZUREATTRS_AZURE_ATTRS_ERROR]: (state) => state.azureAttrsError,
};

export const mutations: MutationTree<AzureAttrsState> = {
  [treekeys.MUTATE_AZUREATTRS_STATE]: (state, { newState }) => {
    state.pending = newState.pending;
    state.azureAttrs = newState.azureAttrs;
    state.azureAttrsError = newState.azureAttrsError;
  },
  [treekeys.MUTATE_AZUREATTRS_PENDING]: (state) => {
    state.pending = true;
  },
  [treekeys.MUTATE_AZUREATTRS_FULFILLED]: (state, { azureAttrs }) => {
    state.pending = false;
    state.azureAttrs = azureAttrs;
    state.azureAttrsError = undefined;
  },
  [treekeys.MUTATE_AZUREATTRS_REJECTED]: (state, { azureAttrsError }) => {
    state.pending = false;
    state.azureAttrs = {};
    state.azureAttrsError = azureAttrsError;
  },
};

export const actions: ActionTree<AzureAttrsState, any> = {
  [treekeys.AZUREATTRS_PENDING]: ({ commit }) => {
    commit(treekeys.MUTATE_AZUREATTRS_STATE, {
      newState: {
        ...data,
        pending: true,
      },
    });
  },
  [treekeys.AZUREATTRS_FULFILLED]: ({ commit }, { azureAttrs }) => {
    commit(treekeys.MUTATE_AZUREATTRS_STATE, {
      newState: {
        ...data,
        pending: false,
        azureAttrs,
        azureAttrsError: undefined,
      },
    });
  },
  [treekeys.AZUREATTRS_REJECTED]: ({ commit }, { azureAttrsError }) => {
    commit(treekeys.MUTATE_AZUREATTRS_STATE, {
      newState: {
        ...data,
        pending: false,
        azureAttrs: false,
        azureAttrsError,
      },
    });
  },
};

const azureAttrsModule: Module<AzureAttrsState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default azureAttrsModule;
