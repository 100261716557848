export const GET_PERFORMMOBILESSOMODULE_PENDING =
  'GET_PERFORMMOBILESSOMODULE_PENDING';
export const GET_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE =
  'GET_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE';
export const GET_PERFORMMOBILESSOMODULE_MOBILESSOERROR =
  'GET_PERFORMMOBILESSOMODULE_MOBILESSOERROR';
export const GET_PERFORMMOBILESSOMODULE_AUTHENTICATED =
  'GET_PERFORMMOBILESSOMODULE_AUTHENTICATED';
export const GET_PERFORMMOBILESSOMODULE_ERRORMESSAGE =
  'GET_PERFORMMOBILESSOMODULE_ERRORMESSAGE';
export const GET_PERFORMMOBILESSOMODULE_CURRSTATE =
  'GET_PERFORMMOBILESSOMODULE_CURRSTATE';
export const GET_PERFORMMOBILESSOMODULE_PREVSTATE =
  'GET_PERFORMMOBILESSOMODULE_PREVSTATE';
export const GET_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER =
  'GET_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER';
export const MUTATE_PERFORMMOBILESSOMODULE_PENDING =
  'MUTATE_PERFORMMOBILESSOMODULE_PENDING';
export const MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE =
  'MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE';
export const MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR =
  'MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR';
export const MUTATE_PERFORMMOBILESSOMODULE_AUTHENTICATED =
  'MUTATE_PERFORMMOBILESSOMODULE_AUTHENTICATED';
export const MUTATE_PERFORMMOBILESSOMODULE_ERRORMESSAGE =
  'MUTATE_PERFORMMOBILESSOMODULE_ERRORMESSAGE';
export const MUTATE_PERFORMMOBILESSOMODULE_CURRSTATE =
  'MUTATE_PERFORMMOBILESSOMODULE_CURRSTATE';
export const MUTATE_PERFORMMOBILESSOMODULE_PREVSTATE =
  'MUTATE_PERFORMMOBILESSOMODULE_PREVSTATE';
export const MUTATE_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER =
  'MUTATE_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER';
export const ACTION_PERFORMMOBILESSOMODULE_PENDING =
  'ACTION_PERFORMMOBILESSOMODULE_PENDING';
export const ACTION_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE =
  'ACTION_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE';
export const ACTION_PERFORMMOBILESSOMODULE_MOBILESSOERROR =
  'ACTION_PERFORMMOBILESSOMODULE_MOBILESSOERROR';
export const ACTION_PERFORMMOBILESSOMODULE_AUTHENTICATED =
  'ACTION_PERFORMMOBILESSOMODULE_AUTHENTICATED';
export const ACTION_PERFORMMOBILESSOMODULE_ERRORMESSAGE =
  'ACTION_PERFORMMOBILESSOMODULE_ERRORMESSAGE';
export const ACTION_PERFORMMOBILESSOMODULE_CURRSTATE =
  'ACTION_PERFORMMOBILESSOMODULE_CURRSTATE';
export const ACTION_PERFORMMOBILESSOMODULE_PREVSTATE =
  'ACTION_PERFORMMOBILESSOMODULE_PREVSTATE';
export const ACTION_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER =
  'ACTION_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER';
export const GETPERFORMMOBILESSOMODULE = 'performMobileSSOModule';
export const NS_GET_PERFORMMOBILESSOMODULE_PENDING = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_PENDING}`;
export const NS_GET_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE}`;
export const NS_GET_PERFORMMOBILESSOMODULE_MOBILESSOERROR = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_MOBILESSOERROR}`;
export const NS_GET_PERFORMMOBILESSOMODULE_AUTHENTICATED = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_AUTHENTICATED}`;
export const NS_GET_PERFORMMOBILESSOMODULE_ERRORMESSAGE = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_ERRORMESSAGE}`;
export const NS_GET_PERFORMMOBILESSOMODULE_CURRSTATE = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_CURRSTATE}`;
export const NS_GET_PERFORMMOBILESSOMODULE_PREVSTATE = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_PREVSTATE}`;
export const NS_GET_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER = `${GETPERFORMMOBILESSOMODULE}/${GET_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_PENDING = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_PENDING}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_MOBILESSOERROR}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_AUTHENTICATED = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_AUTHENTICATED}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_ERRORMESSAGE = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_ERRORMESSAGE}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_CURRSTATE = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_CURRSTATE}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_PREVSTATE = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_PREVSTATE}`;
export const NS_MUTATE_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER = `${GETPERFORMMOBILESSOMODULE}/${MUTATE_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_PENDING = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_PENDING}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_MOBILESSORESPONSE}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_MOBILESSOERROR = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_MOBILESSOERROR}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_AUTHENTICATED = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_AUTHENTICATED}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_ERRORMESSAGE = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_ERRORMESSAGE}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_CURRSTATE = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_CURRSTATE}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_PREVSTATE = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_PREVSTATE}`;
export const NS_ACTION_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER = `${GETPERFORMMOBILESSOMODULE}/${ACTION_PERFORMMOBILESSOMODULE_SELECTEDC360ACCOUNTNUMBER}`;
