/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  GetRXProductInfoParallelState,
  GetRXProductInfoState,
  ServiceLine,
} from './types';
import * as treekeys from './treekeys';

export const data: GetRXProductInfoParallelState = {
  getRXProductInfoParallelMap: {},
  shouldThrottle: false,
  lruKeyArr: [],
  shouldShiftLRU: false,
};
export const getters: GetterTree<GetRXProductInfoParallelState, any> = {
  [treekeys.GET_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP]: (
    state
  ) => state.getRXProductInfoParallelMap,
  [treekeys.GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDTHROTTLE]: (state) =>
    state.shouldThrottle,
  [treekeys.GET_GETRXPRODUCTINFOPARALLELMODULE_SHOULDSHIFTLRU]: (state) =>
    state.shouldShiftLRU,
  [treekeys.GET_GETRXPRODUCTINFOPARALLELMODULE_GETACCTNUMWTNSMAP]: (state) => {
    const resp: { [index: string]: string[] } = {};
    Object.entries(state.getRXProductInfoParallelMap).forEach((entry) => {
      resp[entry[0] || 'UNKNOWN'] = resp[entry[0] || 'UNKNOWN'] || [];
      const pushEntries = entry[1]?.rxProductInfo?.serviceLines
        ?.flatMap((serviceLine: ServiceLine) => serviceLine?.serviceTnOrCktId)
        .filter((x) => x) as string[];
      pushEntries ? resp[entry[0] || 'UNKNOWN'].push(...pushEntries) : 0;
    });
    return resp;
  },
};
export const mutations: MutationTree<GetRXProductInfoParallelState> = {
  [treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP]:
    (state, { getRXProductInfoParallelMap }) => {
      state.getRXProductInfoParallelMap = getRXProductInfoParallelMap;
    },
  [treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_SHIFTLRU]: (state) => {
    delete state.getRXProductInfoParallelMap[state.lruKeyArr.shift() || ''];
    state.shouldThrottle =
      Object.values(state.getRXProductInfoParallelMap).filter(
        (getRXProductInfoState: GetRXProductInfoState) =>
          getRXProductInfoState.pending
      ).length >= treekeys.GETRXPRODUCTINFOPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING]: (
    state,
    { key }
  ) => {
    state.getRXProductInfoParallelMap[key] ? 0 : state.lruKeyArr.push(key);
    state.shouldShiftLRU =
      state.lruKeyArr.length >
      treekeys.GETRXPRODUCTINFOPARALLELMODULE_MAXENTRIES;
    state.getRXProductInfoParallelMap[key] =
      state.getRXProductInfoParallelMap[key] || {};
    state.getRXProductInfoParallelMap[key].pending = true;
    state.shouldThrottle =
      Object.values(state.getRXProductInfoParallelMap).filter(
        (getRXProductInfoState: GetRXProductInfoState) =>
          getRXProductInfoState.pending
      ).length >= treekeys.GETRXPRODUCTINFOPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO]: (
    state,
    { key, rxProductInfo }
  ) => {
    state.getRXProductInfoParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getRXProductInfoParallelMap).filter(
        (getRXProductInfoState: GetRXProductInfoState) =>
          getRXProductInfoState.pending
      ).length >= treekeys.GETRXPRODUCTINFOPARALLELMODULE_MAXPARALLEL;
    state.getRXProductInfoParallelMap[key].rxProductInfo = rxProductInfo;
    state.getRXProductInfoParallelMap[key].rxProductInfoError = undefined;
  },
  [treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR]: (
    state,
    { key, rxProductInfoError }
  ) => {
    state.getRXProductInfoParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.getRXProductInfoParallelMap).filter(
        (getRXProductInfoState: GetRXProductInfoState) =>
          getRXProductInfoState.pending
      ).length >= treekeys.GETRXPRODUCTINFOPARALLELMODULE_MAXPARALLEL;
    state.getRXProductInfoParallelMap[key].rxProductInfo = {};
    state.getRXProductInfoParallelMap[key].rxProductInfoError =
      rxProductInfoError;
  },
  // [treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO]: (
  //   state
  // ) => {
  //   state.getRXProductInfoParallelMap = {};
  // },
};
export const actions: ActionTree<GetRXProductInfoParallelState, any> = {
  [treekeys.ACTION_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP]:
    ({ commit }, { getRXProductInfoParallelMap }) => {
      commit(
        treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_GETRXPRODUCTINFOPARALLELMAP,
        {
          getRXProductInfoParallelMap,
        }
      );
    },
  [treekeys.ACTION_GETRXPRODUCTINFOPARALLELMODULE_PENDING]: (
    { commit },
    { key }
  ) => {
    commit(treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_PENDING, {
      key,
    });
  },
  [treekeys.ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO]: (
    { commit },
    { key, rxProductInfo }
  ) => {
    commit(treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFO, {
      key,
      rxProductInfo,
    });
  },
  [treekeys.ACTION_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR]: (
    { commit },
    { key, rxProductInfoError }
  ) => {
    commit(treekeys.MUTATE_GETRXPRODUCTINFOPARALLELMODULE_RXPRODUCTINFOERROR, {
      key,
      rxProductInfoError,
    });
  },
};

const getRXProductInfoParallelModule: Module<
  GetRXProductInfoParallelState,
  any
> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default getRXProductInfoParallelModule;
