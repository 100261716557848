import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { Get34SEcomUserProfileState } from './types';
import * as treekeys from './treekeys';

export const data: Get34SEcomUserProfileState = {
  pending: false,
  _34SEcomUserProfile: {},
  _34SEcomUserProfileError: undefined,
};
export const getters: GetterTree<Get34SEcomUserProfileState, any> = {
  [treekeys.GET_GET34SECOMUSERPROFILEMODULE_PENDING]: (state) => state.pending,
  [treekeys.GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE]: (state) =>
    state._34SEcomUserProfile,
  [treekeys.GET_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR]: (state) =>
    state._34SEcomUserProfileError,
};
export const mutations: MutationTree<Get34SEcomUserProfileState> = {
  [treekeys.MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING]: (state) => {
    state.pending = true;
  },
  [treekeys.MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE]: (
    state,
    { _34SEcomUserProfile }
  ) => {
    state.pending = false;
    state._34SEcomUserProfile = _34SEcomUserProfile;
    state._34SEcomUserProfileError = undefined;
  },
  [treekeys.MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR]: (
    state,
    { _34SEcomUserProfileError }
  ) => {
    state.pending = false;
    state._34SEcomUserProfile = {};
    state._34SEcomUserProfileError = _34SEcomUserProfileError;
  },
};
export const actions: ActionTree<Get34SEcomUserProfileState, any> = {
  [treekeys.ACTION_GET34SECOMUSERPROFILEMODULE_PENDING]: ({ commit }) => {
    commit(treekeys.MUTATE_GET34SECOMUSERPROFILEMODULE_PENDING);
  },
  [treekeys.ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE]: (
    { commit },
    { _34SEcomUserProfile }
  ) => {
    commit(treekeys.MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILE, {
      _34SEcomUserProfile,
    });
  },
  [treekeys.ACTION_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR]: (
    { commit },
    { _34SEcomUserProfileError }
  ) => {
    commit(
      treekeys.MUTATE_GET34SECOMUSERPROFILEMODULE_34SECOMUSERPROFILEERROR,
      { _34SEcomUserProfileError }
    );
  },
};

const get34SEcomUserProfileModule: Module<Get34SEcomUserProfileState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default get34SEcomUserProfileModule;
