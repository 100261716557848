/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
} from '@redux-saga/core/effects';
import { REGEXP_EMAIL } from '@/common/strings';
import * as tkAuth from '../modules/auth/treekeys';
import * as tk from '../modules/getC360AcctsParallelModule/treekeys';
import * as tkPS from '../modules/progressspinner/treekeys';
import * as tkSSO from '../modules/performMobileSSOModule/treekeys';
import * as tkRXP from '../modules/getRXProductInfoParallelModule/treekeys';
import { getC360Accts as getC360AcctsApi } from './api/getC360Accts';
import { GetC360AcctsParallelMap } from '../modules/getC360AcctsParallelModule/types';

const shouldCall = (gcapMap: GetC360AcctsParallelMap, acctNum: string) =>
  acctNum &&
  gcapMap &&
  !gcapMap[acctNum]?.pending &&
  (!gcapMap[acctNum]?.c360AccountList ||
    !Object.keys(gcapMap[acctNum]?.c360AccountList).length);

export function* getC360AcctsParallel(accountNumber: any): any {
  const acctNum: string = accountNumber?.payload;
  const zipCode: string = accountNumber?.zipCode;
  const gcapMap: GetC360AcctsParallelMap = yield select(
    (s) => s?.[tk.GETC360ACCTSPARALLELMODULE]?.getC360AcctsParallelMap
  );
  const shouldThrottle: boolean = yield select(
    (s) => s?.[tk.GETC360ACCTSPARALLELMODULE]?.shouldThrottle
  );
  if ((shouldCall(gcapMap, acctNum) && !shouldThrottle) || zipCode) {
    yield put({
      type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
      progressSpinnerAppear: true,
    });
    yield put({
      type: tk.NS_MUTATE_GETC360ACCTSPARALLELMODULE_PENDING,
      key: acctNum,
    });
    const shouldShiftLRU: boolean = yield select(
      (s) => s?.[tk.GETC360ACCTSPARALLELMODULE]?.shouldShiftLRU
    );
    if (shouldShiftLRU) {
      yield put({
        type: tk.NS_MUTATE_GETC360ACCTSPARALLELMODULE_SHIFTLRU,
      });
    }
    try {
      let isAuth: boolean = yield select(
        (s) => s?.[tkAuth.AUTHMODULE]?.authenticated
      );
      if (!isAuth) {
        isAuth = yield select(
          (s) =>
            s?.[tkSSO.GETPERFORMMOBILESSOMODULE]?.mobileSSOResponse
              ?.authenticated
        );
      }

      let resp: any;
      if (REGEXP_EMAIL.test(acctNum)) {
        resp = yield call(getC360AcctsApi, '', acctNum, zipCode, isAuth);
      } else {
        resp = yield call(getC360AcctsApi, acctNum, '', zipCode, isAuth);
      }
      if (resp?.data) {
        // TODO: check isGoodResp (accounts.filter(active) >=1)
        // yield put({
        //   type: tkRXP.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO,
        // });
        yield put({
          type: tkRXP.NS_MUTATE_GETRXPRODUCTINFOPARALLELMODULE_CLEAR_RXPRODUCTINFO,
        });
        yield put({
          type: tk.NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLIST,
          c360AccountList: resp.data,
          key: acctNum,
        });
      } else {
        yield put({
          type: tk.NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR,
          c360AccountListError: resp,
          key: acctNum,
        });
      }
    } catch (err) {
      if (gcapMap[acctNum]) {
        yield put({
          type: tk.NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR,
          c360AccountListError: err,
          key: acctNum,
        });
      }
    } finally {
      yield put({
        type: tkPS.NS_MUTATE_PROGRESSSPINNER_PROGRESSSPINNERAPPEAR,
        progressSpinnerAppear: false,
      });
      if (yield cancelled()) {
        if (gcapMap[acctNum]) {
          yield put({
            type: tk.NS_MUTATE_GETC360ACCTSPARALLELMODULE_C360ACCOUNTLISTERROR,
            c360AccountListError: 'SAGA_CANCELLED',
            key: acctNum,
          });
        }
      }
    }
  }
}

export function* watchGetC360AcctsParallel(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, getC360AcctsParallel);
}

export default watchGetC360AcctsParallel;
