export const GET_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER =
  'GET_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER';
export const GET_WMTPOSTLOGINMODULE_CURRSTATE =
  'GET_WMTPOSTLOGINMODULE_CURRSTATE';
export const GET_WMTPOSTLOGINMODULE_PREVSTATE =
  'GET_WMTPOSTLOGINMODULE_PREVSTATE';
export const MUTATE_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER =
  'MUTATE_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER';
export const MUTATE_WMTPOSTLOGINMODULE_CURRSTATE =
  'MUTATE_WMTPOSTLOGINMODULE_CURRSTATE';
export const MUTATE_WMTPOSTLOGINMODULE_PREVSTATE =
  'MUTATE_WMTPOSTLOGINMODULE_PREVSTATE';
export const ACTION_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER =
  'ACTION_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER';
export const WMTPOSTLOGINMODULE = 'wmtPostLoginModule';
export const NS_GET_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER = `${WMTPOSTLOGINMODULE}/${GET_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_GET_WMTPOSTLOGINMODULE_CURRSTATE = `${WMTPOSTLOGINMODULE}/${GET_WMTPOSTLOGINMODULE_CURRSTATE}`;
export const NS_GET_WMTPOSTLOGINMODULE_PREVSTATE = `${WMTPOSTLOGINMODULE}/${GET_WMTPOSTLOGINMODULE_PREVSTATE}`;
export const NS_MUTATE_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER = `${WMTPOSTLOGINMODULE}/${MUTATE_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_MUTATE_WMTPOSTLOGINMODULE_CURRSTATE = `${WMTPOSTLOGINMODULE}/${MUTATE_WMTPOSTLOGINMODULE_CURRSTATE}`;
export const NS_MUTATE_WMTPOSTLOGINMODULE_PREVSTATE = `${WMTPOSTLOGINMODULE}/${MUTATE_WMTPOSTLOGINMODULE_PREVSTATE}`;
export const NS_ACTION_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER = `${WMTPOSTLOGINMODULE}/${ACTION_WMTPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER}`;
