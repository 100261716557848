import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { WMTSelectAcctIterState, WMTSelectAcctState } from './types';
import * as treekeys from './treekeys';

const initState: WMTSelectAcctIterState = {
  selectedC360AccountNumber: '',
  maskedSelectedC360AccountNumber: '',
};

export const data: WMTSelectAcctState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<WMTSelectAcctState, any> = {
  [treekeys.GET_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER]: (state) =>
    state.currState.selectedC360AccountNumber,
  [treekeys.GET_WMTSELECTACCTMODULE_MASKED_SELECTEDC360ACCOUNTNUMBER]: (
    state
  ) => state.currState.maskedSelectedC360AccountNumber,
  [treekeys.GET_WMTSELECTACCTMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_WMTSELECTACCTMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<WMTSelectAcctState> = {
  [treekeys.MUTATE_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    state,
    { selectedC360AccountNumber, maskedSelectedC360AccountNumber }
  ) => {
    state.currState.selectedC360AccountNumber = selectedC360AccountNumber;
    state.currState.maskedSelectedC360AccountNumber =
      maskedSelectedC360AccountNumber;
  },
  [treekeys.MUTATE_WMTSELECTACCTMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_WMTSELECTACCTMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<WMTSelectAcctState, any> = {
  [treekeys.ACTION_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    { commit },
    { selectedC360AccountNumber, maskedSelectedC360AccountNumber }
  ) => {
    commit(treekeys.MUTATE_WMTSELECTACCTMODULE_SELECTEDC360ACCOUNTNUMBER, {
      selectedC360AccountNumber,
      maskedSelectedC360AccountNumber,
    });
  },
};

const wmtSelectAcctModule: Module<WMTSelectAcctState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default wmtSelectAcctModule;
