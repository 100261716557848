export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDTHROTTLE =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDTHROTTLE';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDSHIFTLRU =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDSHIFTLRU';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMTICKETINFOMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMTICKETINFOMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMACCTNUMMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMACCTNUMMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMPOSTALCODEMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMPOSTALCODEMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMDATEASSUMPTIONMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMDATEASSUMPTIONMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMDATEASSUMPTIONMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMDATEASSUMPTIONMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMSTATUSMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMSTATUSMAP';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMSTATUSMAP =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMSTATUSMAP';
export const MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP =
  'MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP';
export const MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHIFTLRU =
  'MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHIFTLRU';
export const MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING =
  'MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING';
export const MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS =
  'MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS';
export const MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR =
  'MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR';
export const MUTATE_GETBRIGHTSPEED_NOTIFICATION =
  'MUTATE_GETBRIGHTSPEED_NOTIFICATION';
export const ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP =
  'ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP';
export const ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING =
  'ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING';
export const ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS =
  'ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS';
export const ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR =
  'ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR';
export const ACTION_GETBRIGHTSPEED_NOTIFICATION =
  'ACTION_GETBRIGHTSPEED_NOTIFICATION';
export const GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE =
  'getC360PendingOrdersAndRXTicketInfosParallelModule';
export const GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETCOMPANYOWNERID =
  'GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETCOMPANYOWNERID';
export const GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXPARALLEL = 2;
export const GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_MAXENTRIES = 5;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETCOMPANYOWNERID = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETCOMPANYOWNERID}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDTHROTTLE = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDTHROTTLE}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDSHIFTLRU = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHOULDSHIFTLRU}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMTICKETINFOMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMTICKETINFOMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMACCTNUMMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMACCTNUMMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMPOSTALCODEMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMPOSTALCODEMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMDATEASSUMPTIONMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMDATEASSUMPTIONMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMDATEASSUMPTIONMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMDATEASSUMPTIONMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMSTATUSMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_NATIVETICKETNUMSTATUSMAP}`;
export const NS_GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMSTATUSMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${GET_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_ORDERNUMSTATUSMAP}`;
export const NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP}`;
export const NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHIFTLRU = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_SHIFTLRU}`;
export const NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING}`;
export const NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS}`;
export const NS_MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${MUTATE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR}`;
export const NS_MUTATE_GETBRIGHTSPEED_NOTIFICATION = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${MUTATE_GETBRIGHTSPEED_NOTIFICATION}`;
export const NS_ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMAP}`;
export const NS_ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_PENDING}`;
export const NS_ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOS}`;
export const NS_ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${ACTION_GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE_C360PENDINGORDERSANDRXTICKETINFOSERROR}`;
export const NS_ACTION_GETBRIGHTSPEED_NOTIFICATION = `${GETC360PENDINGORDERSANDRXTICKETINFOSPARALLELMODULE}/${ACTION_GETBRIGHTSPEED_NOTIFICATION}`;
