import AbstractBusinessChain from '../abstractBusinessChain';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { useQueryParamsSTSSAHandler } from './queryParamsSTSSAHandler';
import { useQueryParamsSTSLHandler } from './queryParamsSTSLHandler';
import { useQueryParamsSTSNPHandler } from './queryParamsSTSNPHandler';
import { useQueryParamsSTSPOHandler } from './queryParamsSTSPOHandler';
import { useQueryParamsSTSSNPHandler } from './queryParamsSTSSNPHandler';
import { useQueryParamsSTSSVHandler } from './queryParamsSTSSVHandler';
import { useQueryParamsSTSSWHandler } from './queryParamsSTSSWHandler';
import { useQueryParamsSTSLRXProductInfoHandler } from './queryParamsSTSLRXProductInfoHandler';

class QueryParamsSTSSWBusinessChain extends AbstractBusinessChain<
  QueryParamsRequest,
  QueryParamsResponse
> {
  setupChain() {
    this.chainHandlers.push(useQueryParamsSTSLHandler());
    this.chainHandlers.push(useQueryParamsSTSSAHandler());
    this.chainHandlers.push(useQueryParamsSTSSNPHandler());
    this.chainHandlers.push(useQueryParamsSTSPOHandler());
    this.chainHandlers.push(useQueryParamsSTSLRXProductInfoHandler());
    this.chainHandlers.push(useQueryParamsSTSNPHandler());
    this.chainHandlers.push(useQueryParamsSTSSVHandler());
    this.chainHandlers.push(useQueryParamsSTSSWHandler());
  }
}

let qp: QueryParamsSTSSWBusinessChain;

export const useQueryParamsSTSSWBusinessChain =
  (): QueryParamsSTSSWBusinessChain => {
    if (!qp) {
      qp = new QueryParamsSTSSWBusinessChain();
    }
    return qp;
  };
