export const GET_WMTRESCHEDULEMODULE_CURRSTATE =
  'GET_WMTRESCHEDULEMODULE_CURRSTATE';
export const GET_WMTRESCHEDULEMODULE_PREVSTATE =
  'GET_WMTRESCHEDULEMODULE_PREVSTATE';
export const GET_WMTRESCHEDULEMODULE_KEYRELOAD =
  'GET_WMTRESCHEDULEMODULE_KEYRELOAD';

export const MUTATE_WMTRESCHEDULEMODULE_KEYRELOAD =
  'MUTATE_WMTRESCHEDULEMODULE_KEYRELOAD';
export const MUTATE_WMTRESCHEDULEMODULE_CURRSTATE =
  'MUTATE_WMTRESCHEDULEMODULE_CURRSTATE';
export const MUTATE_WMTRESCHEDULEMODULE_PREVSTATE =
  'MUTATE_WMTRESCHEDULEMODULE_PREVSTATE';

export const ACTION_WMTRESCHEDULEMODULE_KEYRELOAD =
  'ACTION_WMTRESCHEDULEMODULE_KEYRELOAD';
export const WMTRESCHEDULEMODULE = 'wmtRescheduleModule';
export const NS_GET_WMTRESCHEDULEMODULE_CURRSTATE = `${WMTRESCHEDULEMODULE}/${GET_WMTRESCHEDULEMODULE_CURRSTATE}`;
export const NS_GET_WMTRESCHEDULEMODULE_PREVSTATE = `${WMTRESCHEDULEMODULE}/${GET_WMTRESCHEDULEMODULE_PREVSTATE}`;
export const NS_GET_WMTRESCHEDULEMODULE_KEYRELOAD = `${WMTRESCHEDULEMODULE}/${GET_WMTRESCHEDULEMODULE_KEYRELOAD}`;
export const NS_MUTATE_WMTRESCHEDULEMODULE_KEYRELOAD = `${WMTRESCHEDULEMODULE}/${MUTATE_WMTRESCHEDULEMODULE_KEYRELOAD}`;
export const NS_MUTATE_WMTRESCHEDULEMODULE_CURRSTATE = `${WMTRESCHEDULEMODULE}/${MUTATE_WMTRESCHEDULEMODULE_CURRSTATE}`;
export const NS_MUTATE_WMTRESCHEDULEMODULE_PREVSTATE = `${WMTRESCHEDULEMODULE}/${MUTATE_WMTRESCHEDULEMODULE_PREVSTATE}`;
export const NS_ACTION_WMTRESCHEDULEMODULE_KEYRELOAD = `${WMTRESCHEDULEMODULE}/${ACTION_WMTRESCHEDULEMODULE_KEYRELOAD}`;
