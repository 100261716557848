import AbstractBusinessChain from '../abstractBusinessChain';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { useQueryParamsSTSSAHandler } from './queryParamsSTSSAHandler';
import { useQueryParamsSTSLHandler } from './queryParamsSTSLHandler';
import { useQueryParamsSTSSNPHandler } from './queryParamsSTSSNPHandler';
import { useQueryParamsSTSIFHandler } from './queryParamsSTSIFHandler';
import { useQueryParamsSTSNPHandler } from './queryParamsSTSNPHandler';
import { useQueryParamsSTSPOHandler } from './queryParamsSTSPOHandler';
import { useQueryParamsSTSSVHandler } from './queryParamsSTSSVHandler';
import { useQueryParamsSTSSWHandler } from './queryParamsSTSSWHandler';
import { useQueryParamsSTSLRXProductInfoHandler } from './queryParamsSTSLRXProductInfoHandler';

class QueryParamsSTSIFBusinessChain extends AbstractBusinessChain<
  QueryParamsRequest,
  QueryParamsResponse
> {
  setupChain() {
    this.chainHandlers.push(useQueryParamsSTSLHandler());
    this.chainHandlers.push(useQueryParamsSTSSAHandler());
    this.chainHandlers.push(useQueryParamsSTSSNPHandler());
    this.chainHandlers.push(useQueryParamsSTSPOHandler());
    this.chainHandlers.push(useQueryParamsSTSLRXProductInfoHandler());
    this.chainHandlers.push(useQueryParamsSTSNPHandler());
    this.chainHandlers.push(useQueryParamsSTSSVHandler());
    this.chainHandlers.push(useQueryParamsSTSSWHandler());
    this.chainHandlers.push(useQueryParamsSTSIFHandler());
  }
}

let qp: QueryParamsSTSIFBusinessChain;

export const useQueryParamsSTSIFBusinessChain =
  (): QueryParamsSTSIFBusinessChain => {
    if (!qp) {
      qp = new QueryParamsSTSIFBusinessChain();
    }
    return qp;
  };
