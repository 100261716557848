import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { STSPostLoginIterState, STSPostLoginState } from './types';
import * as treekeys from './treekeys';

const initState: STSPostLoginIterState = {
  selectedC360AccountNumber: '',
};

export const data: STSPostLoginState = {
  currState: { ...initState },
  prevState: { ...initState },
};
export const getters: GetterTree<STSPostLoginState, any> = {
  [treekeys.GET_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER]: (state) =>
    state.currState.selectedC360AccountNumber,
  [treekeys.GET_STSPOSTLOGINMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_STSPOSTLOGINMODULE_PREVSTATE]: (state) => state.prevState,
};
export const mutations: MutationTree<STSPostLoginState> = {
  [treekeys.MUTATE_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    state,
    { selectedC360AccountNumber }
  ) => {
    state.currState.selectedC360AccountNumber = selectedC360AccountNumber;
  },
  [treekeys.MUTATE_STSPOSTLOGINMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_STSPOSTLOGINMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
};
export const actions: ActionTree<STSPostLoginState, any> = {
  [treekeys.ACTION_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER]: (
    { commit },
    { selectedC360AccountNumber }
  ) => {
    commit(treekeys.MUTATE_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER, {
      selectedC360AccountNumber,
    });
  },
};

const stsPostLoginModule: Module<STSPostLoginState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default stsPostLoginModule;
