import { call, delay } from '@redux-saga/core/effects';
import * as envVars from '../../../common/envVars';
import { useSagaUtilsSingleton } from '../../../common/utils/sagaUtilsSingleton';
import { useGmapLoaderSingleton } from '../../../common/utils/gmapLoaderSingleton';

const sagaUtils = useSagaUtilsSingleton();
const MOCK_TIME_BOUND_MIN = 1000;
const MOCK_TIME_BOUND_MAX = 10000;

export function* getAddressGeocodeInfo(c360Address: string): any {
  if (envVars.VUE_APP_MOCK_API === 'true') {
    const rand = sagaUtils.getRandom(MOCK_TIME_BOUND_MIN, MOCK_TIME_BOUND_MAX);
    yield delay(rand);
    return {
      results: [
        {
          formatted_address: '14911 Izard St, Omaha, NE 68154, USA',
          geometry: {
            location: {
              lat: () => 41.26887929999999,
              lng: () => -96.14681,
            },
          },
        },
      ],
    };
  }
  if (!window.google || !window?.google.maps) {
    yield useGmapLoaderSingleton().getLoader().importLibrary('maps');
  }
  const geocoder = new window.google.maps.Geocoder();
  return yield call(geocoder.geocode, {
    address: c360Address,
  });
}
