/* eslint-disable no-unused-expressions */
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { UpdateRXTicketParallelState, UpdateRXTicketState } from './types';
import * as treekeys from './treekeys';

export const data: UpdateRXTicketParallelState = {
  updateRXTicketParallelMap: {},
  shouldThrottle: false,
  lruKeyArr: [],
  shouldShiftLRU: false,
};
export const getters: GetterTree<UpdateRXTicketParallelState, any> = {
  [treekeys.GET_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP]: (
    state
  ) => state.updateRXTicketParallelMap,
  [treekeys.GET_UPDATERXTICKETPARALLELMODULE_SHOULDTHROTTLE]: (state) =>
    state.shouldThrottle,
  [treekeys.GET_UPDATERXTICKETPARALLELMODULE_SHOULDSHIFTLRU]: (state) =>
    state.shouldShiftLRU,
};
export const mutations: MutationTree<UpdateRXTicketParallelState> = {
  [treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP]: (
    state,
    { updateRXTicketParallelMap }
  ) => {
    state.updateRXTicketParallelMap = updateRXTicketParallelMap;
  },
  [treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_SHIFTLRU]: (state) => {
    delete state.updateRXTicketParallelMap[state.lruKeyArr.shift() || ''];
    state.shouldThrottle =
      Object.values(state.updateRXTicketParallelMap).filter(
        (updateRXTicketState: UpdateRXTicketState) =>
          updateRXTicketState.pending
      ).length >= treekeys.UPDATERXTICKETPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING]: (state, { key }) => {
    state.updateRXTicketParallelMap[key] ? 0 : state.lruKeyArr.push(key);
    state.shouldShiftLRU =
      state.lruKeyArr.length > treekeys.UPDATERXTICKETPARALLELMODULE_MAXENTRIES;
    state.updateRXTicketParallelMap[key] =
      state.updateRXTicketParallelMap[key] || {};
    state.updateRXTicketParallelMap[key].pending = true;
    state.shouldThrottle =
      Object.values(state.updateRXTicketParallelMap).filter(
        (updateRXTicketState: UpdateRXTicketState) =>
          updateRXTicketState.pending
      ).length >= treekeys.UPDATERXTICKETPARALLELMODULE_MAXPARALLEL;
  },
  [treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE]: (
    state,
    { key, response }
  ) => {
    state.updateRXTicketParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.updateRXTicketParallelMap).filter(
        (updateRXTicketState: UpdateRXTicketState) =>
          updateRXTicketState.pending
      ).length >= treekeys.UPDATERXTICKETPARALLELMODULE_MAXPARALLEL;
    state.updateRXTicketParallelMap[key].response = response;
    state.updateRXTicketParallelMap[key].responseError = undefined;
  },
  [treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR]: (
    state,
    { key, responseError }
  ) => {
    state.updateRXTicketParallelMap[key].pending = false;
    state.shouldThrottle =
      Object.values(state.updateRXTicketParallelMap).filter(
        (updateRXTicketState: UpdateRXTicketState) =>
          updateRXTicketState.pending
      ).length >= treekeys.UPDATERXTICKETPARALLELMODULE_MAXPARALLEL;
    state.updateRXTicketParallelMap[key].response = {};
    state.updateRXTicketParallelMap[key].responseError =
      responseError?.data?.updateTicketResponse?.apiResponse?.message;
  },
};
export const actions: ActionTree<UpdateRXTicketParallelState, any> = {
  [treekeys.ACTION_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP]: (
    { commit },
    { updateRXTicketParallelMap }
  ) => {
    commit(
      treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_UPDATERXTICKETPARALLELMAP,
      {
        updateRXTicketParallelMap,
      }
    );
  },
  [treekeys.ACTION_UPDATERXTICKETPARALLELMODULE_PENDING]: (
    { commit },
    { key }
  ) => {
    commit(treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_PENDING, { key });
  },
  [treekeys.ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSE]: (
    { commit },
    { key, response }
  ) => {
    commit(treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSE, {
      key,
      response,
    });
  },
  [treekeys.ACTION_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR]: (
    { commit },
    { key, responseError }
  ) => {
    commit(treekeys.MUTATE_UPDATERXTICKETPARALLELMODULE_RESPONSEERROR, {
      key,
      responseError,
    });
  },
};

const updateRXTicketParallelModule: Module<UpdateRXTicketParallelState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default updateRXTicketParallelModule;
