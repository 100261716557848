export const API_BASE = '/custcare/api';

export const AUTH_LOGOUT = '/logout';
export const AUTH_IS_AUTH = `${API_BASE}/isAuth`;
export const AUTH_AZURE_ATTRS = `${API_BASE}/attrs`;
export const AUTH_SIGNIN = `${API_BASE}/signin`;
export const AUTH_CLEAR_COOKIES = `${API_BASE}/clearCookies`;

export const AUTH_WMT_GET_USERPROFILE = `${API_BASE}/auth/wmt/getUserProfile`;
export const AUTH_WMT_GET_ACCOUNTS = `${API_BASE}/auth/wmt/getAccounts`;
export const AUTH_WMT_GET_TICKETINFOS = `${API_BASE}/auth/wmt/getTicketInfos`;
export const AUTH_WMT_GET_APPOINTMENTINFOS = `${API_BASE}/auth/wmt/getAppointmentInfos`;
export const AUTH_WMT_UPDATE_TICKET = `${API_BASE}/auth/wmt/updateTicket`;

export const AUTH_STS_GET_USERPROFILE = `${API_BASE}/auth/sts/getUserProfile`;
export const AUTH_STS_GET_ACCOUNTS = `${API_BASE}/auth/sts/getAccounts`;
export const AUTH_STS_GET_PENDINGORDERS = `${API_BASE}/auth/sts/getPendingOrders`;
export const AUTH_STS_GET_PRODUCTINFO = `${API_BASE}/auth/sts/getProductInfo`;
export const AUTH_STS_GET_SSCTOKEN = `${API_BASE}/auth/sts/getSSCToken`;

export const MOBILE_SSO = `${API_BASE}/mobile/performSSO`;

export const STS_GET_C360_ACCTS = `${API_BASE}/sts/getAccounts`;
export const STS_GET_C360_PENDINGORDERS = `${API_BASE}/sts/getPendingOrders`;
export const STS_GET_RX_PRODUCTINFO = `${API_BASE}/sts/getProductInfo`;
export const STS_GET_NOKIASSC_TOKEN = `${API_BASE}/sts/getSSCToken`;

export const WMT_GET_C360_ACCTS = `${API_BASE}/wmt/getAccounts`;
export const WMT_GET_WMT_APPOINTMENTS = `${API_BASE}/wmt/getAppointments`;
export const WMT_GET_C360_PENDINGORDERS_AND_RX_TICKETINFOS = `${API_BASE}/wmt/getTicketInfos`;
export const WMT_GET_RX_APPOINTMENTINFOS = `${API_BASE}/wmt/getAppointmentInfos`;
export const WMT_UPDATE_RX_TICKET = `${API_BASE}/wmt/updateTicket`;

export const WMT_GET_SERVICE_ADDRESS_TECHNICIAN_INFO = `${API_BASE}/auth/wmt/getLatLng`;

export const AUTH_IS_DH_SSO_AUTH = `/Orch/checkHeaderForDssr`;

export const SEND_UI_LOG = `${API_BASE}/logInfo`;
