export const GET_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER =
  'GET_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER';
export const GET_STSPOSTLOGINMODULE_CURRSTATE =
  'GET_STSPOSTLOGINMODULE_CURRSTATE';
export const GET_STSPOSTLOGINMODULE_PREVSTATE =
  'GET_STSPOSTLOGINMODULE_PREVSTATE';
export const MUTATE_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER =
  'MUTATE_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER';
export const MUTATE_STSPOSTLOGINMODULE_CURRSTATE =
  'MUTATE_STSPOSTLOGINMODULE_CURRSTATE';
export const MUTATE_STSPOSTLOGINMODULE_PREVSTATE =
  'MUTATE_STSPOSTLOGINMODULE_PREVSTATE';
export const ACTION_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER =
  'ACTION_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER';
export const STSPOSTLOGINMODULE = 'stsPostLoginModule';
export const NS_GET_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER = `${STSPOSTLOGINMODULE}/${GET_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_GET_STSPOSTLOGINMODULE_CURRSTATE = `${STSPOSTLOGINMODULE}/${GET_STSPOSTLOGINMODULE_CURRSTATE}`;
export const NS_GET_STSPOSTLOGINMODULE_PREVSTATE = `${STSPOSTLOGINMODULE}/${GET_STSPOSTLOGINMODULE_PREVSTATE}`;
export const NS_MUTATE_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER = `${STSPOSTLOGINMODULE}/${MUTATE_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER}`;
export const NS_MUTATE_STSPOSTLOGINMODULE_CURRSTATE = `${STSPOSTLOGINMODULE}/${MUTATE_STSPOSTLOGINMODULE_CURRSTATE}`;
export const NS_MUTATE_STSPOSTLOGINMODULE_PREVSTATE = `${STSPOSTLOGINMODULE}/${MUTATE_STSPOSTLOGINMODULE_PREVSTATE}`;
export const NS_ACTION_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER = `${STSPOSTLOGINMODULE}/${ACTION_STSPOSTLOGINMODULE_SELECTEDC360ACCOUNTNUMBER}`;
