import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { WMTResultsIterState, WMTResultsState } from './types';
import * as treekeys from './treekeys';

const initState: WMTResultsIterState = {
  showUpdateTicketMsg: false,
  keyReload: false,
  numKeyCloseApptMissedAlert: '',
};

export const data: WMTResultsState = {
  currState: { ...initState },
  prevState: { ...initState },
  isWMTResultsNavigating: false,
};
export const getters: GetterTree<WMTResultsState, any> = {
  [treekeys.GET_WMTRESULTSMODULE_SHOWUPDATETICKETMSG]: (state) =>
    state.currState.showUpdateTicketMsg,
  [treekeys.GET_WMTRESULTSMODULE_CURRSTATE]: (state) => state.currState,
  [treekeys.GET_WMTRESULTSMODULE_PREVSTATE]: (state) => state.prevState,
  [treekeys.GET_WMTRESULTSMODULE_KEYRELOAD]: (state) =>
    state.currState.keyReload,
  [treekeys.GET_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT]: (state) =>
    state.currState.numKeyCloseApptMissedAlert,
  [treekeys.GET_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING]: (state) =>
    state.isWMTResultsNavigating,
};
export const mutations: MutationTree<WMTResultsState> = {
  [treekeys.MUTATE_WMTRESULTSMODULE_SHOWUPDATETICKETMSG]: (
    state,
    { showUpdateTicketMsg }
  ) => {
    state.currState.showUpdateTicketMsg = showUpdateTicketMsg;
  },
  [treekeys.MUTATE_WMTRESULTSMODULE_CURRSTATE]: (state, { currState }) => {
    state.currState = currState;
  },
  [treekeys.MUTATE_WMTRESULTSMODULE_PREVSTATE]: (state, { prevState }) => {
    state.prevState = prevState;
  },
  [treekeys.MUTATE_WMTRESULTSMODULE_KEYRELOAD]: (state, { keyReload }) => {
    state.currState.keyReload = keyReload;
  },
  [treekeys.MUTATE_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT]: (
    state,
    { numKeyCloseApptMissedAlert }
  ) => {
    state.currState.numKeyCloseApptMissedAlert = numKeyCloseApptMissedAlert;
  },
  [treekeys.MUTATE_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING]: (
    state,
    { isWMTResultsNavigating }
  ) => {
    state.isWMTResultsNavigating = isWMTResultsNavigating;
  },
};
export const actions: ActionTree<WMTResultsState, any> = {
  [treekeys.ACTION_WMTRESULTSMODULE_SHOWUPDATETICKETMSG]: (
    { commit },
    { showUpdateTicketMsg }
  ) => {
    commit(treekeys.MUTATE_WMTRESULTSMODULE_SHOWUPDATETICKETMSG, {
      showUpdateTicketMsg,
    });
  },
  [treekeys.ACTION_WMTRESULTSMODULE_KEYRELOAD]: ({ commit }, { keyReload }) => {
    commit(treekeys.MUTATE_WMTRESULTSMODULE_KEYRELOAD, {
      keyReload,
    });
  },
  [treekeys.ACTION_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT]: (
    { commit },
    { numKeyCloseApptMissedAlert }
  ) => {
    commit(treekeys.MUTATE_WMTRESULTSMODULE_NUMKEYCLOSEAPPTMISSEDALERT, {
      numKeyCloseApptMissedAlert,
    });
  },
  [treekeys.ACTION_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING]: (
    { commit },
    { isWMTResultsNavigating }
  ) => {
    commit(treekeys.MUTATE_WMTRESULTSMODULE_ISWMTRESULTSNAVIGATING, {
      isWMTResultsNavigating,
    });
  },
};

const wmtResultsModule: Module<WMTResultsState, any> = {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};

export default wmtResultsModule;
