import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import today from 'dayjs/plugin/isToday';

class DateUtilsSingleton {
  constructor() {
    dayjs.extend(calendar);
    dayjs.extend(today);
    dayjs.extend(timezone);
    dayjs.extend(utc);
  }

  useDayjs =
    () => (d: string | number | Date | dayjs.Dayjs | undefined | null) =>
      dayjs(d);

  getMinuteStr = (minute: number) => {
    if (minute > 9) {
      return `:${minute}`;
    }
    if (minute >= 0) {
      return `:0${minute}`;
    }
    return '';
  };

  getTimeAMPM = (rTime: string | undefined) =>
    `${
      dayjs(rTime).hour() % 12 === 0 ? 12 : dayjs(rTime).hour() % 12
    }${this.getMinuteStr(dayjs(rTime).minute())} ${
      dayjs(rTime).hour() < 12 ? 'AM' : 'PM'
    }`;

  getTimeAMPMNoZulu = (rTime: string | undefined) => {
    const rTimeNoZ = rTime?.replaceAll('Z', '').replaceAll('+0000', '');
    return `${
      dayjs(rTimeNoZ).hour() % 12 === 0 ? 12 : dayjs(rTimeNoZ).hour() % 12
    }${this.getMinuteStr(dayjs(rTimeNoZ).minute())} ${
      dayjs(rTimeNoZ).hour() < 12 ? 'AM' : 'PM'
    }`;
  };
}

let dateUtilsSingleton: DateUtilsSingleton;

export const useDateUtilsSingleton = (): DateUtilsSingleton => {
  if (!dateUtilsSingleton) {
    dateUtilsSingleton = new DateUtilsSingleton();
  }
  return dateUtilsSingleton;
};
