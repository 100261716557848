export const GET_AZUREATTRS_PENDING = 'GET_AZUREATTRS_PENDING';
export const GET_AZUREATTRS_AZURE_ATTRS = 'GET_AZUREATTRS_AZURE_ATTRS';
export const GET_AZUREATTRS_AZURE_ATTRS_ERROR =
  'GET_AZUREATTRS_AZURE_ATTRS_ERROR';
export const MUTATE_AZUREATTRS_STATE = 'MUTATE_AZUREATTRS_STATE';
export const MUTATE_AZUREATTRS_PENDING = 'MUTATE_AZUREATTRS_PENDING';
export const MUTATE_AZUREATTRS_FULFILLED = 'MUTATE_AZUREATTRS_FULFILLED';
export const MUTATE_AZUREATTRS_REJECTED = 'MUTATE_AZUREATTRS_REJECTED';
export const AZUREATTRS_PENDING = 'AZUREATTRS_PENDING';
export const AZUREATTRS_FULFILLED = 'AZUREATTRS_FULFILLED';
export const AZUREATTRS_REJECTED = 'AZUREATTRS_REJECTED';

export const AZUREATTRSMODULE = 'azureAttrsModule';
export const NS_GET_AZUREATTRS_PENDING = `${AZUREATTRSMODULE}/${GET_AZUREATTRS_PENDING}`;
export const NS_GET_AZUREATTRS_AZURE_ATTRS = `${AZUREATTRSMODULE}/${GET_AZUREATTRS_AZURE_ATTRS}`;
export const NS_GET_AZUREATTRS_AZURE_ATTRS_ERROR = `${AZUREATTRSMODULE}/${GET_AZUREATTRS_AZURE_ATTRS_ERROR}`;
export const NS_MUTATE_AZUREATTRS_STATE = `${AZUREATTRSMODULE}/${MUTATE_AZUREATTRS_STATE}`;
export const NS_MUTATE_AZUREATTRS_PENDING = `${AZUREATTRSMODULE}/${MUTATE_AZUREATTRS_PENDING}`;
export const NS_MUTATE_AZUREATTRS_FULFILLED = `${AZUREATTRSMODULE}/${MUTATE_AZUREATTRS_FULFILLED}`;
export const NS_MUTATE_AZUREATTRS_REJECTED = `${AZUREATTRSMODULE}/${MUTATE_AZUREATTRS_REJECTED}`;
export const NS_AZUREATTRS_PENDING = `${AZUREATTRSMODULE}/${AZUREATTRS_PENDING}`;
export const NS_AZUREATTRS_FULFILLED = `${AZUREATTRSMODULE}/${AZUREATTRS_FULFILLED}`;
export const NS_AZUREATTRS_REJECTED = `${AZUREATTRSMODULE}/${AZUREATTRS_REJECTED}`;
