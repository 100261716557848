/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, select, takeEvery } from '@redux-saga/core/effects';
import { updateRXTicketParallel } from '@/store/sagas/watchUpdateRXTicketParallel';
import {
  RXTicketInfo,
  TroubleReport,
} from '@/store/modules/getC360PendingOrdersAndRXTicketInfosParallelModule/types';
import * as tkGRAP from '@/store/modules/getRXAppointmentInfosParallelModule/treekeys';
import * as tkURTP from '@/store/modules/updateRXTicketParallelModule/treekeys';
import {
  AppointmentResultElement,
  GetRXAppointmentInfosState,
} from '@/store/modules/getRXAppointmentInfosParallelModule/types';
import { UpdateRXTicketParallelMap } from '../modules/updateRXTicketParallelModule/types';

const updateTRWithRescheduleInfo = (
  troubleReport: TroubleReport | undefined,
  newApptInfo: AppointmentResultElement | undefined
) => {
  if (troubleReport) {
    if (!troubleReport?.appointmentInfo) {
      troubleReport.appointmentInfo = {
        startDateTime: '',
        endDateTime: '',
        estimatedCompletionTime: '',
        estimatedStartTime: '',
        techDispatch: false,
        techInstall: false,
        isDispatchInfoEmpty: false,
      };
    }
    troubleReport.appointmentInfo.estimatedStartTime =
      newApptInfo?.appointmentDetail?.startTime || '';
    troubleReport.appointmentInfo.estimatedCompletionTime =
      newApptInfo?.appointmentDetail?.endTime || '';
    troubleReport.troubleReportStatus = 'OPN';
    troubleReport.appointmentInfo.startDateTime =
      newApptInfo?.appointmentDetail?.startTime || '';
    troubleReport.appointmentInfo.endDateTime =
      newApptInfo?.appointmentDetail?.endTime || '';
  }
};

export function* updateRXTicketSubtask(updateRXTicketPayload: any): any {
  yield call(updateRXTicketParallel, updateRXTicketPayload);
  const nativeTicketNum = updateRXTicketPayload?.payload?.nativeTicketNum;
  const urtpMap: UpdateRXTicketParallelMap = yield select(
    (s) => s?.[tkURTP.UPDATERXTICKETPARALLELMODULE]?.updateRXTicketParallelMap
  );
  const respType: string | undefined =
    urtpMap[nativeTicketNum]?.response?.updateTicketResponse?.apiResponse?.type;
  if (respType) {
    const tiWithTicketNum = updateRXTicketPayload?.payload?.ticketInfos?.find(
      (ticketInfo: RXTicketInfo) =>
        ticketInfo?.troubleReports?.find(
          (troubleReport: TroubleReport) =>
            troubleReport?.nativeTroubleReportIdentifier === nativeTicketNum
        )
    );
    const trWithTicketNum: TroubleReport | undefined =
      tiWithTicketNum?.troubleReports.find(
        (troubleReport: TroubleReport) =>
          troubleReport.nativeTroubleReportIdentifier === nativeTicketNum
      );
    const getRXAppointmentInfosState: GetRXAppointmentInfosState = yield select(
      (s) =>
        s?.[tkGRAP.GETRXAPPOINTMENTINFOSPARALLELMODULE]
          ?.getRXAppointmentInfosParallelMap?.[nativeTicketNum]
    );
    const newApptInfo: AppointmentResultElement | undefined =
      getRXAppointmentInfosState?.rxAppointmentInfos?.appointmentResult?.find(
        (are: AppointmentResultElement) =>
          are?.appointmentID ===
          updateRXTicketPayload?.payload?.selectedAppointmentId
      );
    updateTRWithRescheduleInfo(trWithTicketNum, newApptInfo);
  }
}

export function* wmtRescheduleUpdateRXTicket(
  updateTicketSagaPayload: any
): any {
  if (updateTicketSagaPayload?.payload?.nativeTicketNum) {
    yield call(updateRXTicketSubtask, updateTicketSagaPayload);
  }
}

export function* watchWMTRescheduleUpdateRXTicket(
  pattern: string
): Generator<any, any, any> {
  yield takeEvery(pattern, wmtRescheduleUpdateRXTicket);
}

export default watchWMTRescheduleUpdateRXTicket;
