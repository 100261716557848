import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DSSHeaderSignedIn = _resolveComponent("DSSHeaderSignedIn")!
  const _component_DSSHeader = _resolveComponent("DSSHeader")!
  const _component_PVProgressSpinner = _resolveComponent("PVProgressSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DSSFooter = _resolveComponent("DSSFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.authenticated)
      ? (_openBlock(), _createBlock(_component_DSSHeaderSignedIn, {
          key: 0,
          authenticated: _ctx.authenticated,
          loading: _ctx.loading,
          "sign-out-url": _ctx.signOutUrl,
          "company-owner-id": _ctx.companyOwnerId,
          "dot-com-host": _ctx.dotComHost,
          "lumen-host": _ctx.dotComHost,
          "my-ctl-host": _ctx.myCtlHost,
          "brspd-url": _ctx.brspdUrl,
          "get-accounts-resp": _ctx.getAccountsResp,
          "on-change-account": _ctx.onChangeAccount,
          "on-sign-out": _ctx.onSignOut,
          "dh-host": _ctx.dhHost
        }, null, 8, ["authenticated", "loading", "sign-out-url", "company-owner-id", "dot-com-host", "lumen-host", "my-ctl-host", "brspd-url", "get-accounts-resp", "on-change-account", "on-sign-out", "dh-host"]))
      : (_openBlock(), _createBlock(_component_DSSHeader, {
          key: 1,
          loading: _ctx.loading,
          authenticated: _ctx.authenticated,
          "sign-out-url": _ctx.signOutUrl,
          "company-owner-id": _ctx.companyOwnerId,
          "dot-com-host": _ctx.dotComHost,
          "lumen-host": _ctx.dotComHost,
          "my-ctl-host": _ctx.myCtlHost,
          "brspd-url": _ctx.brspdUrl,
          "dssp-host": _ctx.dsspHost
        }, null, 8, ["loading", "authenticated", "sign-out-url", "company-owner-id", "dot-com-host", "lumen-host", "my-ctl-host", "brspd-url", "dssp-host"])),
    _createElementVNode("div", {
      id: "spinner",
      class: _normalizeClass(_ctx.circularSpinner ? 'show' : '')
    }, [
      _createVNode(_component_PVProgressSpinner, { "stroke-width": "3" })
    ], 2),
    (_openBlock(), _createBlock(_component_router_view, { key: _ctx.computedRouteKey })),
    _createVNode(_component_DSSFooter, {
      authenticated: _ctx.authenticated,
      "company-owner-id": _ctx.companyOwnerId
    }, null, 8, ["authenticated", "company-owner-id"])
  ], 64))
}