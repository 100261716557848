/* eslint-disable class-methods-use-this */
import { call, select } from '@redux-saga/core/effects';
import { HandleQueryParamsAction } from '@/store/modules/handleQueryParamsModule/types';
import * as tkGRPP from '@/store/modules/getRXProductInfoParallelModule/treekeys';
import {
  CustomerProduct,
  GetRXProductInfoParallelMap,
  GetRXProductInfoState,
  ServiceLine,
} from '@/store/modules/getRXProductInfoParallelModule/types';
import { getRXProductInfoParallel } from '../../watchGetRXProductInfoParallel';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import AbstractBusinessChainRequestHandler from '../abstractBusinessChainRequestHandler';

class QueryParamsSTSNPHandler extends AbstractBusinessChainRequestHandler<
  QueryParamsRequest,
  QueryParamsResponse
> {
  getActionBasedOnRulesRXProductInfo = (
    getRXProductInfoParallelMap: GetRXProductInfoParallelMap,
    acctNumOrEmail: string
  ): HandleQueryParamsAction => {
    const getRXProductInfoState: GetRXProductInfoState =
      getRXProductInfoParallelMap?.[acctNumOrEmail];
    const custProducts: CustomerProduct[] | undefined =
      getRXProductInfoState?.rxProductInfo?.serviceLines?.flatMap(
        (serviceLine: ServiceLine) => serviceLine?.customerProducts || []
      );
    if (!custProducts?.length) {
      return HandleQueryParamsAction.GOTO_STSNOPRODUCTS;
    }
    return HandleQueryParamsAction.PENDING;
  };

  public *handleRequest(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    const { queryParams } = request;
    const acctNumOrEmail: string =
      queryParams?.accountNumber || queryParams?.email || '';
    const getRXProductInfoParallelMap: GetRXProductInfoParallelMap =
      yield select(
        (s) =>
          s?.[tkGRPP.GETRXPRODUCTINFOPARALLELMODULE]
            ?.getRXProductInfoParallelMap
      );
    if (
      !getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo ||
      !Object.keys(getRXProductInfoParallelMap?.[acctNumOrEmail]?.rxProductInfo)
        .length
    ) {
      yield call(getRXProductInfoParallel, { payload: acctNumOrEmail });
    }

    response.handleQueryParamsAction = this.getActionBasedOnRulesRXProductInfo(
      getRXProductInfoParallelMap,
      acctNumOrEmail.replaceAll('*', '')
    );
  }

  public *shouldNotHandle(
    request: QueryParamsRequest,
    response: QueryParamsResponse
  ): any {
    yield call(() => Promise.resolve());
    return response.handleQueryParamsAction !== HandleQueryParamsAction.PENDING;
  }
}

let qp: QueryParamsSTSNPHandler;

export const useQueryParamsSTSNPHandler = (): QueryParamsSTSNPHandler => {
  if (!qp) {
    qp = new QueryParamsSTSNPHandler();
  }
  return qp;
};
