import AbstractBusinessChain from '../abstractBusinessChain';
import { QueryParamsRequest, QueryParamsResponse } from './queryParamsTypes';
import { useQueryParamsSTSSAHandler } from './queryParamsSTSSAHandler';
import { useQueryParamsSTSLHandler } from './queryParamsSTSLHandler';

class QueryParamsSTSSABusinessChain extends AbstractBusinessChain<
  QueryParamsRequest,
  QueryParamsResponse
> {
  setupChain() {
    this.chainHandlers.push(useQueryParamsSTSLHandler());
    this.chainHandlers.push(useQueryParamsSTSSAHandler());
  }
}

let qp: QueryParamsSTSSABusinessChain;

export const useQueryParamsSTSSABusinessChain =
  (): QueryParamsSTSSABusinessChain => {
    if (!qp) {
      qp = new QueryParamsSTSSABusinessChain();
    }
    return qp;
  };
