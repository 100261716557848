export const GET_SENDUILOGMODULE_LOGINFO = 'GET_SENDUILOGMODULE_LOGINFO';
export const MUTATE_SENDUILOGMODULE_RESPONSEERROR =
  'MUTATE_SENDUILOGMODULE_RESPONSEERROR';
export const ACTION_SENDUILOGMODULE_RESPONSEERROR =
  'ACTION_SENDUILOGMODULE_RESPONSEERROR';
export const SENDUILOGMODULE = 'sendUILogModule';

export const NS_GET_SENDUILOGMODULE_LOGINFO = `${SENDUILOGMODULE}/${GET_SENDUILOGMODULE_LOGINFO}`;
export const NS_MUTATE_SENDUILOGMODULE_RESPONSEERROR = `${SENDUILOGMODULE}/${MUTATE_SENDUILOGMODULE_RESPONSEERROR}`;
export const NS_ACTION_SENDUILOGMODULE_RESPONSEERROR = `${SENDUILOGMODULE}/${ACTION_SENDUILOGMODULE_RESPONSEERROR}`;
